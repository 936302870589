import axios from "axios";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as location from "../../1055-world-locations.json";
import * as success from "../../1127-success.json";
import "./contractDetailsStyle.css";

var retrievedObject = JSON.parse(localStorage.getItem("testObject"));
var simType = JSON.parse(localStorage.getItem("simType"));
var repeatingProductId = JSON.parse(localStorage.getItem("repeatingProductId"));
var description = JSON.parse(localStorage.getItem("description"));
var userInformation = JSON.parse(localStorage.getItem("user"));

const AddMoreData = ({}) => {
  useEffect(() => {
    getProducts();
  }, []);

  const [products, setProducts] = useState([]);
  const [newRepeatingProductId, setNewRepeatingProductId] = useState();
  const [changePage, setChangePage] = useState(false);
  // const [state, setState] = useState(false);
  const [isVisible, setIsvisible] = useState(false);
  const [completed, setcompleted] = useState(false);
  const [loading, setLoading] = useState();

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/tariff/tariffList/simType/${simType}/repeatingProductId/${repeatingProductId}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) => setProducts(response.data));
  };

  const submitAddData = () => {
    const data = {
      contractid: retrievedObject.id,
      repeatingproductid: newRepeatingProductId,
      enddate: null,
    };
    setIsvisible(true);
    setTimeout(() => {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/contract/assignRepeatingProduct/oldRepeatingProductId/${repeatingProductId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => { 
          setLoading(true);
          setTimeout(() => {
            setcompleted(true);
            setChangePage(true);
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          setIsvisible(false);
        });
    }, 2000);
  };

  return (
    <div className="layout">
      {changePage ? (window.location.href = "/ContractDetails") : null}
      <div className="containerM">
        <label className="addTitle">Add More Data</label>
        <div className="row" style={{ paddingTop: "60px" }}>
          <div className="col colDataPlan">
            <label className="addDataTitle">Current Data Plans</label>
            <div className="card cardDataPlan">
              <span className="dataPlnDescription">{description}</span>
              <span className="dataPlnCharge">$/mo</span>

              <br />
              <br />
              <button className="btn currentBtn">Current</button>
            </div>
          </div>
          <div className="col colSelectedPlan">
            <label className="addDataTitle"> Selected Plan</label>
            <div
              className="row"
              style={{
                position: "absolute",
                paddingTop: "6%",
                margin: "5px",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              {products.map((item, i) => (
                <div
                  className="col"
                  style={{
                    margin: "5px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div>
                    <tr key={i}>
                      <div key={item.charge}>
                        <button
                          type="button"
                          className="dataPlnBtn"
                          value={item.repeatingProductId}
                          onClick={(e) => {
                            setNewRepeatingProductId(e.target.value);
                          }}
                        >
                          {item.description}
                          <span
                            style={{
                              fontStyle: "italic",
                              fontWeight: "400",
                              fontSize: "13.223px",
                              lineHeight: "15px",
                              textAlign: "right",
                              paddingTop: "10%",
                            }}
                          >
                            ${item.charge}/mo
                          </span>
                        </button>
                      </div>
                    </tr>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <button
            onClick={() => {
              submitAddData();
            }}
            className="addSaveChanges"
          >
            Save Changes
          </button>
        </div>
        {!isVisible ? (
          <></>
        ) : (
          <>
            {true ? (
              <>
                {!loading ? (
                  <Lottie options={defaultOptions1} height={200} width={200} />
                ) : (
                  <Lottie options={defaultOptions2} height={100} width={100} />
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default AddMoreData;
