import axios from "axios";
import React, { useState } from "react";
import Group326 from "../../images/Group326.png";
import Group309 from "../../images/Group309.png";
import Group312 from "../../images/Group312.png";
import Line from "../../images/Line.png";
import "./ForgetMyPasswordStyle.css";
var userInformation = JSON.parse(localStorage.getItem("user"));

function ForgetMyPassword() {
  const [email, setEmail] = useState("");
  const submitPassword = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/forgotPassword/username/${email}`,  
      )
      .then((res) => { 

        window.location.href = "/";
 
      })
      .catch((err) => {
        console.error(err); 
      });
  };
  return (
    <div>
      <div className="containerPassword">
        <div className="row rowPassword1 justify-content-center">
          <div>
            <img src={Group326} alt="Logo Image" />
          </div>
        </div>
        <div className="row rowPassword2 justify-content-center">
          <span className="textP1">Reset Your Password</span>
          <div className="w-100"></div>
          <span className="textP2">
            Please click the button below to reset your password.
          </span>
        </div>
        <div className="row rowPassword3 justify-content-center">
          <div className="row">
            <img src={Group312} alt="Key Image" />
          </div>
          <br />
          <div className="w-100"></div>
          <br />
          <div className="row">
            <input
              className="passwordInput"
              type="email"
              value={email}
              placeholder="Enter Your Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <br />
          <div className="w-100"></div>
          <br />
          <div className="row">
            <button onClick={submitPassword} className="btn resetPasswordA">
              Reset Password
            </button>
          </div>
          <br />
          <div className="w-100"></div>
          <br />
         
        </div>
        <div className="row rowPassword4 justify-content-center">
          <div className="row" style={{ paddingBottom: "10px" }}>
            <a>
              <span className="a1">Contact </span>
            </a>
            <img src={Line} alt="Logo Image" />
            <a>
              <span className="a1"> Privacy Policy </span>
            </a>
            <img src={Line} alt="Logo Image" />
            <a>
              <span className="a1"> FQA</span>
            </a>
          </div>
          <div className="row">
            <span className="footerPassword">
              You received this email because we received a request for reset
              your password. If you didn't request or you think received this
              email by mistake, you can safely delete this email.
            </span>
          </div>
        </div>
      </div>

      {/* <button onClick={submitPassword}>Submit</button> */}
    </div>
  );
}

export default ForgetMyPassword;
