import React, { Component } from 'react';
import { CustomLayout } from './customimage';
import { ImgUpload } from './imgUpload';
import "./supplyDetailsStyle.css";

class AddImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          images: []
        };
      }
    
      handleUpload = e => {
        const imagePath = URL.createObjectURL(e.target.files[0]);
        const image = (
          <img src={imagePath} alt="dummy" className="img-fluid image" />
        );
    
        this.setState(
          prevState => ({
            images: prevState.images.concat(image)
          }),
          () => {
            console.log(this.state.images);
          }
        );
      };

    render() {
        return (
          <div className="container">
            
            <div hidden ><ImgUpload /></div>
            <CustomLayout />
            <div className="row pb-3">
            </div>
        </div>
        );
      }
}
export default AddImage;