import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import styled, { css } from "styled-components";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import addimage from "../../../images/plusellipse.png";
import { centerCrop } from "react-image-crop";
import axios from "axios";
var userInformation = JSON.parse(localStorage.getItem("user"));
var ticketDetail = JSON.parse(localStorage.getItem("ticketDetail"));


const ConfirmationMessage = styled.div`
  color: green;
  font-style: bold;
  font-family: "Nunito";
  ${({ isVisible }) =>
    isVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const StyledDropzone = styled.div`
  .dzu-dropzone {
    /* convert to horizontal previews */
    align-item: start;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: none !important;
    overflow: hidden;
    border: 2px solid #9D2A00;
    border-radius: 10px;
    width: 170px;
    min-height: 150px;
    margin-left: 0px;
    margin-top: 5px;
  }
  .dzu-previewContainer {
    /* convert preview to square tile */
    padding: 0;
    justify-content: center;
    align-items: start;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 15px;
    height: calc(150px + 20px);
    width: calc(200px + 2px);
    border-bottom: none;
    margin-top: 5px;

    img {
      /* create square bordered image */
      border: 0px;
      max-height: none;
      max-width: none;
      height: 150px;
      width: 200px;
      pointer-events: none;
      user-select: none;
      object-fit: cover;
      border-radius: 0px;
    }

    .dzu-previewStatusContainer {
      position: absolute;
      bottom: 0px;
  }
  
    progress {
      vertical-align: baseline;
      opacity: 0;
  }
  }
  .dzu-submitButtonContainer {
    /* align submit button to bottom */
    position: absolute;
    margin: 0;
    top: 0;
    z-index: 1;
    left: 100px;
    margin: 10px;
  }
  .dzu-inputLabelWithFiles {
    /* align add files button to bottom */
    position: absolute;
    margin: 0;
    top: 0;
    z-index: 1;
    margin: 10px;
  }
  .dzu-inputLabel {
    font-family: 'Nunito';
    font-size: 50px;
    font-weight: 300;
    color: #9D2A00;
}
  .dzu-previewStatusContainer {
    /* place loading bar at bottom */
    position: absolute;
    bottom: 0;
  }

  // doesn't work, svgs being upadated dyanmically
  /* .dzu-previewButton {
    background-image: url("anchor.svg") !important;
  } */

  .dzu-submitButton {
    padding: 0 14px;
    min-height: 32px;
    background-color: #9D2A00;
    border: none;
    border-radius: 10px;
    font-family: 'Nunito'
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}
.dzu-submitButtonContainer {
    position: inherit; 
    margin: 0;
    padding-top:5px;
    padding-left: 22px;
    top: 0;
    z-index: 1;
    left: 100px;
    margin: 10px;
}
`;

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}) => {
  return (
    <>
      <div className="row">
        <div className="col-auto">
          <div className="row">{previews}</div>
        </div>
        <div className="col">
          <div className="row">
            {
              files.length < 5 && (
                <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
              )
            }
          </div>
          {/* {console.log(files.length)} */}
          {/* <div className="row">{files.length > 0 && submitButton}</div> */}
        </div>
      </div>
    </>
  );
};

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const text = "";

  return (
    <label
      style={{
        backgroundColor: "transparent",
        margin: "0px",
        paddingLeft: "35%",
        paddingTop: "30%",
        paddingBottom: "30%",
        cursor: "pointer",
        width: "100%",
      }}
    >
      <img src={addimage} />
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
          });
        }}
      />
    </label>
  );
};

export const CustomLayout = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const getUploadParams = () => ({
    url: `https://test-mvnoportal.iointel.com:8996/api/ticketImages/addTicketImages/${ticketDetail[2]}`,
    headers: {
      Authorization: `Bearer ${userInformation.access_token}`,
      User: `${userInformation.username}`,
    },
  });

  const handleChangeStatus = ({ meta }, status) => {
    // console.log(status, meta);
    setUploadSuccess(false);
  };

  const handleSubmit = (files, allFiles) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInformation.access_token}`,
      },
    };
    let fd = new FormData();

    files.map((file) => {
      fd.append("image", file);
    });

    axios
      .post(
        `https://test-mvnoportal.iointel.com:8996/api/ticketImages/addTicketImages/${ticketDetail[2]}`,
        fd,
        config
      )
      .then((response) => {
        // callback(response);
      })
      .catch((error) => {
        // errorResponse(error);
      });

    console.log(files.map((f) => f.meta));
    setUploadSuccess(true);

    // allFiles.forEach(f => f.remove())
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <>
      <StyledDropzone>
        <ConfirmationMessage isVisible={uploadSuccess}>
          <label style={{ marginLeft: "-10px" }}>Successfully uploaded!</label>
        </ConfirmationMessage>
        <Dropzone
          maxFiles={5}
          //   onDrop={handleDrop}
          getUploadParams={getUploadParams}
          LayoutComponent={Layout}
          onSubmit={handleSubmit}
          // classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
          InputComponent={Input}
          onChangeStatus={handleChangeStatus}
          getFilesFromEvent={getFilesFromEvent}
          accept="image/*"
        />
      </StyledDropzone>
    </>
  );
};
