import React, { Component, useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import "./updateUserStyle.css";
import { connect } from "react-redux";
import axios from "axios"; 
var userInformation = JSON.parse(localStorage.getItem("user"));


const UserUpdateModal = ({ userInfo, closePopup, reFetch }) => {
  const [username, setUsername] = useState(userInfo.username);
  const [mail, setMail] = useState(userInfo.username);
  const [name, setName] = useState(userInfo.name);
  const [department, setDepartment] = useState(userInfo.department);
  const [role, setRole] = useState(userInfo.role);
  const [dealerCode, setDealerCode] = useState(userInfo.dealerCode);
  const [phone, setPhone] = useState(userInfo.phone);
  const [city, setCity] = useState(userInfo.city);
  const [country, setCountry] = useState(userInfo.country);
  const [zipcode, setZipcode] = useState(userInfo.zipcode);
  const [state, setState] = useState(userInfo.state);
  const [addressLine1, setAddressLine1] = useState(userInfo.addressLine1);
  const [addressLine2, setAddressLine2] = useState(userInfo.addressLine2);
  const [isActive, setIsActive] = useState(userInfo.isActive === "1");
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const vemail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };

  const vfirstName = (value) => {
    var illegalChars = /\W/; // allow letters, numbers, and underscores
    if (value.length < 2 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The first name must be between 2 and 20 characters.
        </div>
      );
    } else if (illegalChars.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter a valid first name.
        </div>
      );
    }
  };

  const vmiddleName = (value) => {
    var illegalChars = /\W/; // allow letters, numbers, and underscores

    if (illegalChars.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter a valid middle name.
        </div>
      );
    }
  };

  const vlastName = (value) => {
    // var illegalChars = /\W/; // allow letters, numbers, and underscores
  
    if (value.length < 2 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The last name must be between 2 and 20 characters.
        </div>
      );
    } else if (!/^[a-zA-Z\s]*$/i.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter a valid last name.
        </div>
      );
    }
  };

  const vdepartment = (value) => {
    // var illegalChars = /\W/; allow letters, numbers, and underscores
    if (!/^[a-zA-Z\s]*$/i.test(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter a valid department.
        </div>
      );
    }
  };

  const onSubmit = () => {
    const userInfo = {
      username: username,
      name: name,
      department: department,
      role: role,
      phone: phone,
      enabled: isActive ? true : false,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/updateUser/username/${mail}`,
        userInfo,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then(function (response) { 
        setIsSuccess(true);
        reFetch();
        closePopup();
      })
      .catch(function (error) {
        console.log(error);
        setIsSuccess(false);
        closePopup();
      });
  };

  return (
    <div>
      <div className="backdrop" onClick={closePopup} />
      <div
        className="modal"
        id="updateUser"
        style={{
          display: "flex",
          width: "500px",
          position: "fixed",
          marginTop: "6%",
          left: "calc(50% - 250px)",
        }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                Update User
              </h5>
              <button type="button" className="close" onClick={closePopup}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form>
                <div>
                  <div className="row" id="labelform">
                    <div className="col">
                      <label htmlFor="name">Full Name</label>
                    </div>
                    <div className="col">
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        id="addInput"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        validations={[required, vlastName]}
                      />
                    </div>
                  </div>
                  <div className="row" id="labelform">
                    <div className="col">
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="col">
                      <Input
                        type="text"
                        className="form-control"
                        name="username"
                        id="addInput"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        validations={[required, vemail]}
                      />
                    </div>
                  </div>
                  <div className="row" id="labelform">
                    <div className="col">
                      <label htmlFor="name">Phone</label>
                    </div>
                    <div className="col">
                      <Input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="addInput"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        validations={[required, vlastName]}
                      />
                    </div>
                  </div>
                  <div className="form-check">
                    <div
                      className="row mt-1 mb-1"
                      style={{ marginLeft: "-35px" }}
                    >
                      <div className="col">
                        <label
                          id="active"
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Active
                        </label>
                      </div>
                      <div
                        className="col"
                        style={{ marginLeft: "-50%", paddingTop: "7px" }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          onChange={(e) => setIsActive(e.target.checked)}
                          checked={isActive}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" id="labelform">
                    <div className="col">
                      <label htmlFor="department">Department</label>
                    </div>
                    <div className="col">
                      <Input
                        type="text"
                        className="form-control"
                        name="department"
                        id="addInput"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                        validations={[vdepartment]}
                      />
                    </div>
                  </div>
                  <div className="row" id="labelform">
                    <div className="col">
                      <label>Role</label>
                    </div>
                    <div className="col">
                      <select
                        id="selectrole"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option>ROLE_MOD</option>
                        <option>ROLE_USER</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-and" style={{ marginLeft: "60%" }}>
                      <button
                        id="add"
                        className="btn btn-block"
                        type="button"
                        onClick={onSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        isSuccess ? "alert alert-success" : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserUpdateModal;
