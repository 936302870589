import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
// import { createMuiTheme } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import Chip from "@material-ui/core/Chip";
import "./ticketTable.css";
import axios from "axios";
import Moment from 'moment';
var userInformation = JSON.parse(localStorage.getItem("user"));

const themeStatus = createTheme({
  palette: {
    primary: {
      main: "#11cb5f",
    },
    secondary: {
      main: "#ffea00",
    },
    check: {
      main: "#b2102f",
    },
  },
});

const colums = [
  { name: "title", label: "Ticket Title" },
  {
    name: "ticketStatus",
    label: "Ticket Status",
    options: {
      filter: true,
      customBodyRender: (value) => {
        return (
          <ThemeProvider theme={themeStatus}>
            <Chip
              label={value}
              color={
                value === "OPENED"
                  ? "secondary"
                  : value === "UNREAD"
                    ? "error"
                    : "secondary"
              }
            />
          </ThemeProvider>
        );
      },
    },
  },
  { name: "id", label: "Ticket Number" },
  {
    name: "status",
    label: "Message Status",
    options: {
      filter: true,
      customBodyRender: (value) => {
        return (
          <ThemeProvider theme={themeStatus}>
            <Chip
              label={value}
              color={
                value === "SOLVED"
                  ? "primary"
                  : value === "OPEN"
                    ? "secondary"
                    : "error"
              }
            />
          </ThemeProvider>
        );
      },
    },
  },
  { name: "date", label: "Date" },
];

function TicketTable() {
  const [products, setProducts] = useState();
  const [products1, setProducts1] = useState();
  const [products2, setProducts2] = useState();
  const [products3, setProducts3] = useState();
  const [counterAll, setCounterAll] = useState();
  const [counterSolved, setCounterSolved] = useState();
  const [counterOpend, setCounterOpend] = useState();
  const [open, setOpen] = useState(false);
  const [solved, setSolved] = useState(false);
  // const [idsToDelete, setIdsToDelete] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => { 
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/ticket/getStatusCountByDealerCode/${userInformation.dealerCode + "-" + userInformation.username}`, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setCounterAll(response.data.data);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ticket/getStatusCountsByDealerCode/SOLVED/${userInformation.dealerCode + "-" + userInformation.username}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setCounterSolved(response.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/ticket/getStatusCountsByDealerCode/OPEN/${userInformation.dealerCode + "-" + userInformation.username}`, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setCounterOpend(response.data.data);
      });
    axios
      .get( 
        `${process.env.REACT_APP_API_URL}/api/ticket/getAllTicketsByDealerCode/${userInformation.dealerCode + "-" + userInformation.username}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) =>
      response.data.map((user) => ({ 
        category: `${user.category}`, 
        date: Moment(`${user.date}`).format('MM-DD-YYYY'),
        dealerCode: `${user.dealerCode}`,
        id: `${user.id}`,
        page: `${user.page}`,
        status: `${user.status}`,
        ticketStatus: `${user.ticketStatus}`,
        title: `${user.title}`,
         
      }))
    )
    .then((contacts) => {
      setProducts(contacts);
        setProducts1(contacts);
        setProducts2(contacts);
        setProducts3(contacts);
      
    })
      
      
  };

  let data = products;

  const showTotal = () => {
    setProducts(products3);
  };

  const showOpen = () => {
    setProducts(products1.filter((item) => item.status === "OPEN"));
    setOpen(true);
    setSolved(false);
  };
  const showSolved = () => {
    // console.log(products, data);
    setProducts(products2.filter((item) => item.status === "SOLVED"));
    setOpen(false);
    setSolved(true);
  };

  const handleRowClick = (rowData, rowMeta) => {
    localStorage.setItem("ticketDetail", JSON.stringify(rowData));
    window.location.href = "/submittedTicketDetail";
  };

  const options = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    onRowClick: handleRowClick,
    onRowsDelete: (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex].id); // array of all ids to to be deleted
      // setIdsToDelete(rowsDeleted.data.map(d => data[d.dataIndex].id));
      // console.log(idsToDelete);

      const dataTicket = new FormData();
      dataTicket.append("ids", idsToDelete);
      axios
        .post(
          `https://test-mvnoportal.iointel.com:8996/api/ticket/deleteTickets`, dataTicket,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          window.alert('Deleted successfully!')
        });
    }
  };
  return (
    <>
      <div className="row">
        <div className="col statusTicketCol">
          <div className="container statusTicketRow pl-0 pr-0">
            <div className="row  p-0">
              <div className="col pr-0 mr-0">
                <button className="statusTicketT" onClick={showTotal}>
                  Total Tickets
                </button>
              </div>
              <div className="col pl-0 ml-0">
                <div className="numberTicket pt-2"> {counterAll} </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col statusTicketCol">
          <div className="container statusTicketRow pl-0 pr-0">
            <div className="row p-0">
              <div className="col pr-0 mr-0">
                <button className="statusTicketO" onClick={showOpen}>
                  Open Tickets
                </button>
              </div>
              <div className="col pl-0 ml-0">
                <div className="numberTicket pt-2 "> {counterOpend} </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col statusTicketCol">
          <div className="container statusTicketRow pl-0 pr-0">
            <div className="row p-0">
              <div className="col pr-0 mr-0">
                <button className="statusTicketS" onClick={showSolved}>
                  Solved Tickets
                </button>
              </div>
              <div className="col pl-0 ml-0">
                <div className="numberTicket pt-2 "> {counterSolved} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* <div class="MUIDataTableToolbar-left-15"><div class="MUIDataTableSearch-main-89" style="opacity: 1; transform: none; transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;"><svg class="MuiSvgIcon-root MUIDataTableSearch-searchIcon-90" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg><div class="MuiFormControl-root MuiTextField-root MUIDataTableSearch-searchText-91 MuiFormControl-fullWidth"><div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl" data-test-id="Search"><input aria-invalid="false" type="text" aria-label="Search" class="MuiInputBase-input MuiInput-input" value=""/></div></div><button class="MuiButtonBase-root MuiIconButton-root MUIDataTableSearch-clearIcon-92" tabindex="0" type="button"><span class="MuiIconButton-label"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span><span class="MuiTouchRipple-root"></span></button></div></div> */}
      <MUIDataTable options={options} data={data} columns={colums} />
    </>
  );
}

export default TicketTable;
