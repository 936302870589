import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import userManagement from "./userManagement";
import updateUser from "./updateUser";
import simActivation from "./simActivation";

export default combineReducers({
  auth,
  message,
  userManagement,
  updateUser,
  simActivation,
});
