import axios from "axios";
import api from "./api";
import TokenService from "./token.service";
import qs from "qs"; 

class AuthService {
  login(username, password) {
    const data = { username: username, password: password };
    const url = "${process.env.REACT_APP_API_URL}/login";
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
       
      },
      data: qs.stringify(data),
      url,
    };
    axios(options);
  }

  logout() {
    TokenService.removeUser();
  }

  register(
    username,
    email,
    password,
    firstName,
    middleName,
    lastName,
    department,
    role
  ) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
      firstName,
      middleName,
      lastName,
      department,
      role: [role.name],
    });
  }
}

export default new AuthService();
