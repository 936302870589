import React, { Component } from "react";
import jdLogo from "../../images/jdLogo.png";
import upload from "../../images/upload.png";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import "./bulkNewIccıdStyle.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Lottie from "react-lottie";
import SideMenu from "../../SideMenu";
import * as location from "../../1055-world-locations.json";
import * as success from "../../1127-success.json";
import GetProfileImage from "../getProfileImage/GetProfileImage";
var userInformation = JSON.parse(localStorage.getItem("user"));

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const vIdNumber = (value) => {
  var illegalChars = /\D/; // allow letters, numbers, and underscores
  if (value.length < 2 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The id number must be between 2 and 20 characters.
      </div>
    );
  } else if (illegalChars.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid id number.
      </div>
    );
  }
};
const vDealerIdNumber = (value) => {
  var illegalChars = /\D/; // allow letters, numbers, and underscores
  if (value.length < 2 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The dealer group number must be between 2 and 20 characters.
      </div>
    );
  } else if (illegalChars.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid dealer group number.
      </div>
    );
  }
};

class BulkNewIccıd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iccid: "",
      dealerCode: "",
      simType: "",
      loading: null,
      isVisible: false,
      errorMessage: false,
    };
  }

  createIccid() {
    this.setState({
      isVisible: true,
      errorMessage: false,
    });
    const data = {
      iccid: this.state.iccid,
      dealerCode: this.state.dealerCode,
      simType: this.state.simType,
    };
    setTimeout(() => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/iccidCatalog/saveIccid`, data, {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        })
        .then((res) => {
          this.setState({
            loading: true,
          });
          setTimeout(() => {
            this.setState({
              complated: true,
            });
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            isVisible: false,
            errorMessage: error.response.data.message,
          });
        });
    }, 2000);
  }
  onSubmit(e) {
    e.preventDefault();
  }

  render() {
    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: location.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: success.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="content" style={{ width: "95%" }}>
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>CREATE NEW ICCID</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <div className="container newIccıdContainer">
              <Form
                onSubmit={this.onSubmit}
                ref={(c) => {
                  this.form = c;
                }}
              >
                <div>
                  <br />
                  <div className="form-group">
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <label id="lblactivation">
                            ICCID Number <span className="textnumber2">*</span>
                          </label>
                          <Input
                            id="customer"
                            type="text"
                            name="IdNumber"
                            className="form-control"
                            placeholder="Enter ICCID Number"
                            value={this.state.iccid}
                            onChange={(event) =>
                              this.setState({
                                iccid: event.target.value,
                              })
                            }
                            validations={[required, vIdNumber]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label id="lblactivation">
                            Dealer Code
                            <span className="textnumber2">*</span>
                          </label>
                          <Input
                            id="customer"
                            type="text"
                            name="IdNumber"
                            className="form-control"
                            placeholder="Dealer Code"
                            value={this.state.dealerCode}
                            onChange={(event) =>
                              this.setState({
                                dealerCode: event.target.value,
                              })
                            }
                            validations={[required, vDealerIdNumber]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label id="lblactivation">
                            Sim Card Type <span className="textnumber2">*</span>
                          </label>
                          <select
                            id="customeri"
                            name="state"
                            placeholder="Select Sim Type"
                            value={this.state.simType}
                            onChange={(event) =>
                              this.setState({
                                simType: event.target.value,
                              })
                            }
                          >
                            <option selected>Sim Card Type</option>
                            <option>M2M</option>
                            <option>NB-IOT</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.errorMessage && (
                    <h4 style={{ color: "#fefefe", marginLeft: "19px" }}>
                      {" "}
                      {this.state.errorMessage}{" "}
                    </h4>
                  )}
                </div>

                {/* {console.log(this.state)} */}
                <button
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="btn ml-3 mt-5 clearBtn"
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    this.createIccid();
                  }}
                  className="btn ml-3 mt-5 createIccıdBtn"
                >
                  Create ICCID
                </button>
              </Form>
              <br />
              <br />
              <br />
              <br />
              <br />
              {!this.state.isVisible ? (
                <></>
              ) : (
                <>
                  {true ? (
                    <>
                      {!this.state.loading ? (
                        <Lottie
                          options={defaultOptions1}
                          height={200}
                          width={200}
                        />
                      ) : (
                        <Lottie
                          options={defaultOptions2}
                          height={100}
                          width={100}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <br />
              <br />
              <Link to="/bulkUpload">
                <button
                  // onClick={() => {
                  //     this.saveChanges();
                  // }}
                  className="btn ml-3 bulkUploadBtn"
                >
                  BULK UPLOAD{" "}
                  <img className="ml-1" src={upload} alt="upload Logo" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BulkNewIccıd;
