import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./components/login.component";
import bg from "./images/bgNew.png";
import SimOrder from "./components/simOrder.page";
import Home from "./components/home.component";
import BoardUser from "./components/board-user.component";
import add from "./components/add";
import Billing from "./components/billing.page";
import Settings from "./components/settings.page";
import { history } from "./helpers/history";
import SimOrderSuccessful from "./components/simOrderSuccessful.page";
import Stepper from "./components/stepper";
import ContractDetails from "./components/contractDetails/ContractDetails";
import LiveUsage from "./components/contractDetails/LiveUsage";
import AddMoreData from "./components/contractDetails/AddMoreData";
import ForgetMyPassword from "./components/forgetMyPassword/ForgetMyPassword";
import UserManagement from "./components/user.management.page.component";
import ChangePassword from "./components/changePassword/ChangePassword";
import ChangePasswordSuccess from "./components/changePasswordSuccess/ChangePasswordSuccess";
import IointelDashboard from "./components/iointelDashboard/IointelDashboard";
import BulkNewIccıd from "./components/bulkNewIccıd/BulkNewIccıd";
import BulkUpload from "./components/bulkUpload/BulkUpload";
import MailActivation from "./components/mailActivation/MailActivation";
import PricingPage from "./components/pricingPage/pricingPage";
import SupportPage from "./componentsFaz2/supportDealer/supportPage";
import { UserTicketsPage } from "./componentsFaz2/supportSuperAdmin/userTickets/UserTicketsPage";
import SubmittedTicketsDetail from "./componentsFaz2/supportDealer/submittedTickets/SubmittedTicketsDetail";
import SupportPanel from "./componentsFaz2/supportSuperAdmin/supportPanel/SupportPanel";
import CleanIotLogin from "./componentsFaz2/cleanIotLogin/CleanIotLogin";



var userInformation = JSON.parse(localStorage.getItem("user"));

class App extends Component {

  render() {

    return (

      <div style={{ width: "99%" }}>
        <img src={bg} id="bg" alt="Background Image" />

        <Router history={history}>
          <div>
            <div>
              <div>
                <Switch>
                  {userInformation === null ? (
                    <>
                      <Route exact path="/" component={Login} />
                      <Route path="/login" component={CleanIotLogin} />
                      <Route
                        path="/ForgetMyPassword"
                        component={ForgetMyPassword}
                      />
                      <Route
                        path="/mailActivation/:token"
                        component={MailActivation}
                      />
                    </>
                  ) : (
                    <>
                      {userInformation.role === "ROLE_MOD" && (
                        <>
                          <Route exact path={["/"]} component={Home} />
                          <Route path="/simManagement" component={BoardUser} />
                          <Route path="/simActivation" component={Stepper} />
                          <Route path="/simOrder" component={SimOrder} />
                          <Route
                            path="/simOrderSuccessful"
                            component={SimOrderSuccessful}
                          />
                          <Route
                            path="/userManagement"
                            component={UserManagement}
                          />
                          <Route path="/pricing" component={PricingPage} />
                          <Route path="/settings" component={Settings} />
                          <Route
                            path="/ChangePassword"
                            component={ChangePassword}
                          />
                          <Route
                            path="/ChangePasswordSuccess"
                            component={ChangePasswordSuccess}
                          />
                          <Route
                            path="/ContractDetails"
                            component={ContractDetails}
                          />
                          <Route path="/LiveUsage" component={LiveUsage} />
                          <Route path="/AddMoreData" component={AddMoreData} />
                          <Route path="/Support" component={SupportPage} />
                          <Route path="/submittedTicketDetail" component={SubmittedTicketsDetail} />

                        </>
                      )}
                      {userInformation.role === "ROLE_ADMIN" && (
                        <>
                          <Route exact path={["/"]} component={Home} />
                          <Route path="/simManagement" component={BoardUser} />
                          <Route
                            path="/userManagement"
                            component={UserManagement}
                          />
                          <Route path="/billing" component={Billing} />
                          <Route path="/pricing" component={PricingPage} />
                          <Route path="/settings" component={Settings} />
                          <Route path="/simOrder" component={SimOrder} />
                          <Route
                            path="/ChangePassword"
                            component={ChangePassword}
                          />
                          <Route
                            path="/ChangePasswordSuccess"
                            component={ChangePasswordSuccess}
                          />
                          <Route
                            path="/simOrderSuccessful"
                            component={SimOrderSuccessful}
                          />
                          <Route path="/simActivation" component={Stepper} />
                          <Route
                            path="/ContractDetails"
                            component={ContractDetails}
                          />
                          <Route path="/LiveUsage" component={LiveUsage} />
                          <Route path="/Support" component={SupportPage} />
                          <Route path="/AddMoreData" component={AddMoreData} />
                          <Route path="/submittedTicketDetail" component={SubmittedTicketsDetail} />
                        </>
                      )}

                      {userInformation.role === "ROLE_SUPERADMIN" && (
                        <>
                          <Route exact path={["/"]} component={IointelDashboard} />
                          <Route path="/add" component={add} />
                          <Route path="/simManagement" component={BoardUser} />
                          <Route path="/simActivation" component={Stepper} />

                          <Route
                            path="/createNewIccid"
                            component={BulkNewIccıd}
                          />
                          <Route path="/bulkUpload" component={BulkUpload} />
                          <Route path="/settings" component={Settings} />
                          <Route
                            path="/ChangePassword"
                            component={ChangePassword}
                          />
                          <Route
                            path="/ChangePasswordSuccess"
                            component={ChangePasswordSuccess}
                          />
                          <Route
                            path="/ContractDetails"
                            component={ContractDetails}
                          />
                          <Route path="/LiveUsage" component={LiveUsage} />
                          <Route path="/SupportIointel" component={UserTicketsPage} />
                          <Route path="/SubmittedIointelTicketDetail" component={SupportPanel} />
                          <Route path="/AddMoreData" component={AddMoreData} />
                        </>
                      )}

                      {userInformation.role === "ROLE_USER" && (
                        <>
                          <Route exact path={["/"]} component={Home} />
                          <Route path="/simManagement" component={BoardUser} />
                          <Route path="/simActivation" component={Stepper} />

                          <Route
                            path="/ContractDetails"
                            component={ContractDetails}
                          />
                          <Route path="/LiveUsage" component={LiveUsage} />
                          <Route path="/Support" component={SupportPage} />
                          <Route path="/AddMoreData" component={AddMoreData} />
                          <Route path="/pricing" component={PricingPage} />
                          <Route path="/settings" component={Settings} />
                          <Route path="/submittedTicketDetail" component={SubmittedTicketsDetail} />
                          <Route
                            path="/ChangePassword"
                            component={ChangePassword}
                          />
                          <Route
                            path="/ChangePasswordSuccess"
                            component={ChangePasswordSuccess}
                          />
                        </>
                      )}
                    </>
                  )}
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
