import { borderBottom, borderTop, color, minHeight } from "@mui/system";
import React, { useEffect, useState, Component } from "react";
import "./supplyDetailsStyle.css";
import sendButton from "../../../images/sendButton.png";
import axios from "axios";
import GetProfileImage from "../../../components/getProfileImage/GetProfileImage";
import Button from "@restart/ui/esm/Button";
// import SideMenu from "../../../SideMenu";

var ticketDetail = JSON.parse(localStorage.getItem("ticketDetail"));
var userInformation = JSON.parse(localStorage.getItem("user"));

export default class supplyTicketDetails extends Component {
  constructor(props) {
    super(props);
    this.sendMessage = this.sendMessage.bind(this);

    this.state = {
      response: {},
      userMessage: [],
      adminMessage: [],
      messages: [],
      message: "",
    };
  }

  componentDidMount() {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/ticket/getTicket/${ticketDetail[2]}`,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            response: response.data,
          });
        });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/ticketMessage/getAllMessages/${ticketDetail[2]}`,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            messages: response.data,
          });
        });
    } catch (error) { }
  }

  changeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };
  sendMessage() {
    const userMessage = {
      userMessage: this.state.message,
    };
    this.state.message !== "" &&
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/ticketMessage/saveUserMessage/${ticketDetail[2]}`,
          userMessage,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then(
          (response) => response.status === 200 && window.location.reload()
        );
  }
  render() {
    const { category, page } = this.state;
    const messagess = this.state.messages.map(
      (x) =>
        (x.userMessage !== null && <label>You: {x.userMessage}</label>) ||
        (x.adminMessage !== null && <label>Iointel: {x.adminMessage}</label>)
    );

    console.log(this.state.messages);
    return (
      <>
        {console.log(this.state.response)}
        <div className="row">
          <div className="col-5">
            <span id="lblactivation">Category</span>
            <span className="textnumber2">*</span>
            <input
              className="mt-2"
              id="customeria"
              name="category"
              value={this.state.response.category}
              diabled
            />
          </div>
          <div className="col-5">
            <span id="lblactivation">Page</span>
            <input
              className="mt-2"
              id="customeria"
              name="page"
              placeholder="please select category of your problem "
              value={this.state.response.page}
              disabled
            />
          </div>
        </div>
        <div className="pl-3">
          <div className="row pt-2">
            <span className="ticketTitle">Title</span><span className="textnumber2">*</span>
          </div>
          <div className="row">
            <input
              id="titleInput"
              className=" form-control"
              type="text"
              style={{ color: "#E3E2DD", cursor: "default" }}
              disabled
              value={this.state.response.title}
              onKeyDown={(e) => e.keyCode === 13 && sendButton(e)}
            />
          </div>
          <div className="row">
            <label className="ticketDetails">
              Please supply details about your request/problem
            </label>
          </div>
          <div className="row">
            <span
              className="scroll"
              id="detailsInput"
              style={{ color: "#E3E2DD", cursor: "default", paddingLeft: "11px" }}
            >
              {/* mapping  */}

              {/* {userMessages}
                {adminMessages} */}
              {messagess}
            </span>

            <div className="input-group">
              <input
                id="titleInput"
                className=" form-control"
                type="text"
                autoComplete="off"
                placeholder="Type a message"
                value={this.state.message}
                onChange={this.changeMessage}
                style={{
                  color: "#E3E2DD",
                  borderTop: "1px solid #D3D2CD",
                  minHeight: "33px",
                }}
              />
              <button
                onClick={this.sendMessage}
                style={{
                  backgroundColor: "rgba(227, 226, 221, 0.5)",
                  borderTop: "1px solid #D3D2CD",
                }}
              >
                <img src={sendButton} />
              </button>
            </div>
          </div>
          <Button href="/support" className="backBtn">
            Back
          </Button>
        </div>
      </>
    );
  }
}
