import React, { Component } from "react";
import jdLogo from "../images/jdLogo.png";
import simLogo from "../images/sim.png";
import "./simOrderStyle.css";
import { QuantityPicker } from "react-qty-picker";
import axios from "axios";
import { Link } from "react-router-dom";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";

var userInformation = JSON.parse(localStorage.getItem("user"));

class SimOrder extends Component {
  constructor() {
    super();
    this.state = {
      btnClass: true,
      iccidType: "M2M",
      orderCount: 1,
      dealerCode: "",
      isVisible: false,
      boolean: false,
      orderNumber: "",
    };
  }
  setBtnClass(val) {
    this.setState({
      btnClass: val,
    });
  }

  m2m() {
    this.setState({
      iccidType: "M2M",
    });
  }
  cciot() {
    this.setState({
      iccidType: "CCIOT",
    });
  }

  onClickEvent = (e) => {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  };

  getPickerValue = (value) => {
    // console.log(value) // Here you can get the value of the Quantity picker

    this.setState({
      orderCount: value,
    });
  };

  componentDidMount() {
    this.setState({
      dealerCode: userInformation.dealerCode,
    });
  }
  submitSims() {
    // if(this.state.orderCount = 0){

    // }
    const data = {
      simCardType: this.state.iccidType,
      orderQuantity: this.state.orderCount,
      payerId: this.state.dealerCode,
      email: userInformation.username,
    };
    this.setState({});

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/order/createOrder`, data, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          // alert(response.data);
          this.setState({
            orderNumber: response.data,
          });
          this.onClickSuccessful();
          localStorage.setItem(
            "orderNumber",
            JSON.stringify(response.data.data)
          );
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error.data.message);
      });
  }
  onClickSuccessful() {
    window.location.href = "/simOrderSuccessful";
  }

  render() {
    const { isVisible, disable, boolean, } = this.state;
    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="maincontainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SIM-CARD ORDER</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <br />
            <div className="d-flex justify-content-center" id="mainOrderContainer">
              <div className="row">
                <div className="col-9">
                  <div className="row" className={userInformation.dealerType == "CleanIot" ? "textContainerClean" : "textContainer"}>
                    <span className={userInformation.dealerType == "CleanIot" ? "textClean" : "text"}>
                      Order SIM Cards in <span className={userInformation.dealerType == "CleanIot" ? "textnumberClean" : "textnumber"}>3</span> easy
                      steps!
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="" id="simLogo">
                      <img src={simLogo} alt="simLogo" />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <span className={userInformation.dealerType == "CleanIot" ? "text2Clean" : "text2"}>
                      IO INTEL SIM Card. Comes in two choices.
                      <span className={userInformation.dealerType == "CleanIot" ? "textnumber2Clean" : "textnumber2"}>{" "}NB-IOT</span> or{" "}
                      <span className={userInformation.dealerType == "CleanIot" ? "textnumber2Clean" : "textnumber2"}>M2M</span>. Please contact with
                      IOINTEL to find out which card types best suited for you.
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row" id="textContainer2">
                    <div className="row">
                      <span className={userInformation.dealerType == "CleanIot" ? "text3Clean" : "text3"}> 1- Choose card type </span>
                    </div>
                    <div className="row">
                      <div style={{ paddingTop: "20px" }}>
                        <button
                          type="button"
                          className={userInformation.dealerType == "CleanIot" ? (this.state.btnClass ? "btnAClean" : "btnclkAClean") : (this.state.btnClass ? "btnA" : "btnclkA")}
                          onClick={() => {
                            // btnClass ? setBtnClass(false) : setBtnClass(true);
                            this.setBtnClass(false);
                            this.cciot();
                          }}
                        >
                          NB-IoT
                        </button>
                        <button
                          type="button"
                          className={userInformation.dealerType == "CleanIot" ? (this.state.btnClass ? "btnclkAClean ml-2" : "btnAClean ml-2") : (this.state.btnClass ? "btnclkA ml-2" : "btnA ml-2")}
                          onClick={() => {
                            // btnClassA ? setBtnClassA(false) : setBtnClassA(true);
                            this.setBtnClass(true);
                            this.m2m();
                          }}
                        >
                          M2M
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row" id="textContainer2">
                    <div className="row">
                      <span className={userInformation.dealerType == "CleanIot" ? "text3Clean" : "text3"}> 2- Choose card quantity </span>
                    </div>
                    <div className="row">
                      <div style={{ paddingTop: "20px" }}>
                        <QuantityPicker
                          value={1}
                          max={30000}
                          min={1}
                          onChange={this.getPickerValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" id="textContainer2">
                    <div className="row">
                      <span className={userInformation.dealerType == "CleanIot" ? "text3Clean" : "text3"}> 3- Click order </span>
                    </div>
                    <div className="w-100"></div>
                    <div className="row" style={{ paddingTop: "20px" }}>
                      <div>
                        {!isVisible ? (
                          <button
                            type="button"
                            className="btn"
                            id="btnB"
                            onClick={() => {
                              this.onClickEvent();
                              this.submitSims();
                            }}
                          >
                            Order
                          </button>
                        ) : (
                          <button disabled className="proceeding">Proceeding..</button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SimOrder;
