import axios from "axios";
import { connect } from "react-redux";
import React, { Component } from "react";

import EventBus from "../../common/EventBus";
import { history } from "../../helpers/history";
import { clearMessage } from "../../actions/message";
import { logout } from "../../actions/auth";
import Tooltip from "@mui/material/Tooltip";
var userInformation = JSON.parse(localStorage.getItem("user"));
class GetProfileImage extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showUserBoard: false,
      currentUser: [],

      image: undefined,
    };

    history.listen((location) => {
      props.dispatch(clearMessage());
    });
  }
  componentDidMount() {
    const user = this.props.user;
    this.getImage();

    if (user) {
      user.role === "ROLE_SUPERADMIN"
        ? this.setState({
            showSuperAdminBoard: true,
            currentUser: user,
          })
        : this.setState({
            currentUser: user,
            showModeratorBoard: user.role.includes("ROLE_MOD"),
            showAdminBoard: user.role.includes("ROLE_ADMIN"),
            showUserBoard: user.role.includes("ROLE_USER"),
          });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined,
    });
  }

  getImage = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/image/get/image/dealerCode/${userInformation.dealerCode}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )

      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = URL.createObjectURL(blob);
        this.setState({
          image: image,
        });
      });
  };

  render() {
    let firstLetters = userInformation.name
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    return (
      <div>
        {this.state.image !== undefined ? (
          <img className="dealerImage" src={this.state.image}></img>
        ) : (
          <>
            <Tooltip title="Setup your profile" placement="left">
              <a href="/settings">
                <div className="row justify-content-center dealerImageA">
                  <div
                    className="col-and"
                    style={{
                      paddingTop: "22px",
                      paddingLeft: "26px",
                      paddingRight: "1px",
                    }}
                  >
                    <label className="imageTxt1">{firstLetters[0]}</label>
                  </div>
                  <div
                    className="col"
                    style={{
                      paddingTop: "28px",
                      paddingRight: "15px",
                      paddingLeft: "1px",
                    }}
                  >
                    <label className="imageTxt2">{firstLetters[1]}</label>
                  </div>
                </div>
              </a>
            </Tooltip>
          </>
        )}
        <div className="userInfo">{userInformation.name}</div>
        <a
          href="/"
          onClick={() => {
            this.logOut();
          }}
        >
          <div className="logOutT">
            <label style={{ cursor: "pointer", marginTop: "0px" }}>
              LogOut
            </label>
          </div>
        </a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(GetProfileImage);
