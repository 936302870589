import React from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";
import "../homeStyle.css";
import allStates from "./data/allstates.json";

var countt = JSON.parse(localStorage.getItem("count"));
const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

const statesWithRevive = [

  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", " Delaware", "Florida", "Georgia", " Hawaii",
  "Idaho", "Illinois", "Indiana", " Iowa", "Kansas", "Kentucky", "Louisiana", " Maine", "Maryland", "Massachusetts", "Michigan",
  "Minnesota", " Mississippi", "Missouri", " Montana", " Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York",
  "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming",
];

const stateAbbrWithCities = {
  AL: { name: "ALABAMA" },
  AK: { name: "ALASKA" },
  AB: { name: "ALBERTA" },
  AS: { name: "AMERICAN SAMOA" },
  AZ: { name: "ARIZONA" },
  AR: { name: "ARKANSAS" },
  BC: { name: "BRITISH COLUMB" },
  CA: { name: "CALIFORNIA" },
  CO: { name: "COLORADO" },
  CT: { name: "CONNECTICUT" },
  DE: { name: "DELAWARE" },
  FL: { name: "FLORIDA" },
  XX: { name: "FOREIGN" },
  GA: { name: "GEORGIA" },
  GU: { name: "GUAM" },
  HI: { name: "HAWAII" },
  ID: { name: "IDAHO" },
  IL: { name: "ILLINOIS" },
  IN: { name: "INDIANA" },
  IA: { name: "IOWA" },
  KS: { name: "KANSAS" },
  KY: { name: "KENTUCKY" },
  LA: { name: "LOUISIANA" },
  ME: { name: "MAINE" },
  MB: { name: "MANITOBA" },
  MD: { name: "MARYLAND" },
  MA: { name: "MASSACHUSETTS" },
  MI: { name: "MICHIGAN" },
  MN: { name: "MINNESOTA" },
  MS: { name: "MISSISSIPPI" },
  MO: { name: "MISSOURI" },
  MT: { name: "MONTANA" },
  MP: { name: "N. MARIANA ISL" },
  NE: { name: "NEBRASKA" },
  NV: { name: "NEVADA" },
  NB: { name: "NEW BRUNSWICK" },
  NH: { name: "NEW HAMPSHIRE" },
  NJ: { name: "NEW JERSEY" },
  NM: { name: "NEW MEXICO" },
  NY: { name: "NEW YORK" },
  NL: { name: "NEWFOUNDLAND" },
  NC: { name: "NORTH CAROLINA" },
  ND: { name: "NORTH DAKOTA" },
  NT: { name: "NORTHWEST TERR" },
  NS: { name: "NOVA SCOTIA" },
  NU: { name: "NUNAVUT" },
  OH: { name: "OHIO" },
  OK: { name: "OKLAHOMA" },
  ON: { name: "ONTARIO" },
  OR: { name: "OREGON" },
  PA: { name: "PENNSYLVANIA" },
  PE: { name: "PRINCE EDWARD" },
  PR: { name: "PUERTO RICO" },
  QC: { name: "QUEBEC" },
  RI: { name: "RHODE ISLAND" },
  SK: { name: "SASKATCHEWAN" },
  SC: { name: "SOUTH CAROLINA" },
  SD: { name: "SOUTH DAKOTA" },
  TN: { name: "TENNESSEE" },
  TX: { name: "TEXAS" },
  UT: { name: "UTAH" },
  VT: { name: "VERMONT" },
  VI: { name: "VIRGIN INLANDS" },
  VA: { name: "VIRGINIA" },
  WA: { name: "WASHINGTON" },
  DC: { name: "WASHINGTON D.C." },
  WV: { name: "WEST VIRGINIA" },
  WI: { name: "WISCONSIN" },
  WY: { name: "WYOMING" },
  YT: { name: "YUKON TERR" },
};

const MapChart = ({ setTooltip, testClick }) => {
  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoAlbersUsa"
        className="mapA"
        style={{
          paddingTop: "0px",
          alignItems: "center",
          marginTop: "0px",
          height: "25rem",
          width: "49rem",
        }}>
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => {
                const cur = allStates.find((s) => s.val === geo.id);
                const reviveCity = stateAbbrWithCities[cur.id];
                return (
                  <Geography
                    className="geo-test"
                    key={geo.rsmKey}
                    stroke="#FFF"
                    geography={geo}
                    data-tip={geo}
                    onClick={() => {
                      testClick(reviveCity);
                    }}
                    style={{
                      default: {
                        fill: "#bfbfbf",
                        outline: "none",
                      },
                      hover: {
                        fill: "#0A1330",
                        outline: "none",
                        cursor: "pointer",
                      },
                      pressed: {
                        fill: "#0A1330",
                        outline: "none",
                      },
                    }}
                  />
                );
              })}
              {geographies.map((geo) => {
                const centroid = geoCentroid(geo);
                const cur = allStates.find((s) => s.val === geo.id);
                const reviveCity = stateAbbrWithCities[cur.id];
                return (
                  <g key={geo.rsmKey + "-name"}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (statesWithRevive.includes(geo?.properties?.name) ? (
                        <Marker coordinates={centroid}>
                          {/* <circle r="10" fill="#676aa7"></circle> */}
                          <text
                            y="3"
                            fill="#ffffff"
                            fontSize={14}
                            textAnchor="middle"
                          >
                            {/* {cur.id} */}
                            {/* {reviveCity ? reviveCity.cities.length : cur.id} */}
                          </text>
                        </Marker>
                      ) : (
                        <></>
                        // <Annotation
                        //   subject={centroid}
                        //   dx={offsets[cur.id][0]}
                        //   dy={offsets[cur.id][1]}
                        // >
                        //   <text x={4} fontSize={14} alignmentBaseline="middle">
                        //     {cur.id}
                        //   </text>
                        // </Annotation>
                      ))}
                  </g>
                );
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    </>
  );
};

export default MapChart;

// import React from "react";
// import { geoCentroid } from "d3-geo";
// import {
//   ComposableMap,
//   Geographies,
//   Geography,
//   Marker,
//   Annotation
// } from "react-simple-maps";

// import allStates from "./data/allstates.json";

// const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

// const offsets = {
//   VT: [50, -8],
//   NH: [34, 2],
//   MA: [30, -1],
//   RI: [28, 2],
//   CT: [35, 10],
//   NJ: [34, 1],
//   DE: [33, 0],
//   MD: [47, 10],
//   DC: [49, 21]
// };

// const MapChart = ({ setTooltipContent }) => {
//   return (
//     <ComposableMap projection="geoAlbersUsa" data-tip="">
//       <Geographies geography={geoUrl}>
//         {({ geographies }) => (
//           <>
//             {geographies.map((geo) => {
//               const color =
//                 geo.id === "48" || geo.id === "06" ? "#000" : "#DDD";
//                 // const cur = allStates.find((s) => s.val === geo.id);
//                 // const reviveCity = stateAbbrWithCities[cur.id];
//               return (
//                 <Geography
//                   key={geo.rsmKey}
//                   stroke="#FFF"
//                   geography={geo}
//                   fill={color}
//                   onMouseEnter={() => {
//                     console.log(geo);
//                     setTooltipContent(geo.properties.name);
//                   }}
//                   onMouseLeave={() => {
//                     setTooltipContent("");
//                   }}
//                 />
//               );
//             })}
//           </>
//         )}
//       </Geographies>
//     </ComposableMap>
//   );
// };

// export default MapChart;
