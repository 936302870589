import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
// import Stepper from "bs-stepper";
import "./simActivationStyle.css";
import finish from "../images/finish.png";
import axios from "axios";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import jdLogo from "../images/jdLogo.png";
import RepeatingProducts from "./repeatingProducts";
// import Stepper from "react-stepper-horizontal";
import Stepper from "./stepperH/indexS";
import Lottie from "react-lottie";
import * as location from "../1055-world-locations.json";
import * as success from "../1127-success.json";
import { Link } from "react-router-dom";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vcity = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores
  if (!/^[a-zA-Z\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid city.
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};
const viccid = (value) => {
  var illegalChars = /\D/; // allow letters, numbers, and underscores
  if (value.length < 18 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The sim number must be between 18 and 20 characters.
      </div>
    );
  } else if (illegalChars.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid sim number.
      </div>
    );
  }
};
const vusername = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The responsible person must be between 3 and 20 characters.
      </div>
    );
  } else if (!/^[a-zA-Z\s]*$/.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid responsible person.
      </div>
    );
  }
};
const vphone = (value) => {
  var illegalChars = /(\d)\1{2}\1{3}\1{4}/;
  if (value.length < 10 || value.length > 12) {
    return (
      <div className="alert alert-danger" role="alert">
        The phone must be between 10 and 12 characters.
      </div>
    );
  } else if (illegalChars.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid phone.
      </div>
    );
  }
};

const vcompany = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores
  // if (value.length < 3 || value.length > 20) {
  //   return (
  //     <div className="alert alert-danger" role="alert">
  //       The company name must be between 3 and 20 characters.
  //     </div>
  //   );
  // } else
  if (!/^[0-9a-zA-Z\s.-]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid company name.
      </div>
    );
  }
};

const vzipCode = (value) => {
  // var illegalChars = /\D/; // allow letters, numbers, and underscores
  if (value.length !== 5) {
    return (
      <div className="alert alert-danger" role="alert">
        The zipcode must be 5 characters.
      </div>
    );
  } else if (!/^\d{5}(?:[-\s]\d{4})?$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid zipcode.
      </div>
    );
  }
};

const vaddressLineOne = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores
  if (!/^[a-zA-Z0-9-_.\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid address line 1.
      </div>
    );
  }
};
const vaddressLineTwo = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores
  if (!/^[a-zA-Z0-9-_.\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid address line 2.
      </div>
    );
  }
};
var userInformation = JSON.parse(localStorage.getItem("user"));
var iccidd = JSON.parse(localStorage.getItem("iccid"));
const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: location.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default class StepperA extends Component {
  constructor() {
    super();
    this.state = {
      iccid: "",
      iccidd: "",
      customerDetail: {
        customerId: "",
        responsiblePerson: "",
        company: "",
        email: "",
        phone: "",
        country: "USA",
        state: "",
        city: "",
        zipcode: "",
        addressLineOne: "",
        addressLineTwo: "",
      },
      repeatingProductId: "",
      repeatingProductDescription: "",

      dealer_code: "",
      successful: false,
      errorMessage: null,
      page: 1,
      data: [],
      dataa: [],
      loading: undefined,
      loadingg: undefined,
      complated: true,
      complatedd: true,
      isVisible: true,
      isVisiblee: false,
      boolean: false,
      tariffId: "",
      error: false,
      simType: "",
      steps: [
        { title: "SIM Details" },
        { title: "Customer Details" },
        { title: "Plan Selection" },
        { title: "Summary" },
        { title: "Confirmation" },
      ],
    };
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
  }
  onClickNext() {
    this.setState({
      page: this.state.page + 1,
    });
  }
  onClickPrev() {
    this.setState({
      page: this.state.page - 1,
    });
  }

  addCustomer() {
    this.setState({
      isVisiblee: true,
    });
    const dealer_Code = {
      parentid: userInformation.dealerCode,
      payerid: userInformation.dealerCode,
    };
    setTimeout(() => {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/customer/addCustomer`,
          dealer_Code,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          this.setState((x) => ({
            ...x,
            customerDetail: {
              ...x.customerDetail,
              customerId: response.data.newCustId,
            },
          }));
          this.setState({
            // isVisiblee: true,
          });
        })
        .then((res) => {
          this.submitCustomerDetail();
        })
        .then((res) => {
          this.handleSimRegister();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isVisiblee: false,
          });
        });
    }, 3000);
  }

  submitIccid(e) {
    if (iccidd === null) {
      const newiccid = {
        newiccid: this.state.iccid,
      };
      this.setState({
        isVisible: false,
      });
      setTimeout(() => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/contract/checkIccid/dealerCode/${userInformation.dealerCode}`,
            newiccid,
            {
              headers: {
                Authorization: `Bearer ${userInformation.access_token}`,
                User: `${userInformation.username}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/api/contract/getSimType/iccid/${this.state.iccid}`,
                  {
                    headers: {
                      Authorization: `Bearer ${userInformation.access_token}`,
                      User: `${userInformation.username}`,
                    },
                  }
                )
                .then((response) => {
                  this.setState({
                    simType: response.data,
                  });
                });
            }
          })
          .then((json) => {
            this.setState({
              data: json,
              loading: true,
            });

            setTimeout(() => {
              this.setState({
                complated: true,
              });
              this.onClickNext();
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
            // alert(error.response.data);
            this.setState({
              errorMessage: error.response.data.message,
              isVisible: true,
            });

          });
      }, 2000);
    } else {
      const newiccid = {
        newiccid: iccidd,
      };
      this.setState({
        isVisible: false,
      });
      setTimeout(() => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/contract/checkIccid/dealerCode/${userInformation.dealerCode}`,
            newiccid,
            {
              headers: {
                Authorization: `Bearer ${userInformation.access_token}`,
                User: `${userInformation.username}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/api/contract/getSimType/iccid/${this.state.iccidd}`,
                  {
                    headers: {
                      Authorization: `Bearer ${userInformation.access_token}`,
                      User: `${userInformation.username}`,
                    },
                  }
                )
                .then((response) => {
                  this.setState({
                    simType: response.data,
                  });
                });
            }
          })
          .then((json) => {
            this.setState({
              data: json,
              loading: true,
            });

            setTimeout(() => {
              this.setState({
                complated: true,
              });
              this.onClickNext();
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
            // alert(error.response.data);
            this.setState({
              errorMessage: error.response.data.message,
              isVisible: true,
            });

          });
      }, 2000);
    }
  }

  submitCustomerDetail(e) {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/customer/addCustomerDetail`,
        this.state.customerDetail,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
        // this.setState({
        //   successful: false,
        // });
      });
  }

  handleSimRegister = (e) => {
    if (iccidd === null) {
      const data = {
        iccid: this.state.iccid,
        custid: this.state.customerDetail.customerId,
        tariffid: this.state.tariffId.toString(),
      };

      setTimeout(() => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/contract/addContract/repeatingProductId/${this.state.repeatingProductId}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${userInformation.access_token}`,
                User: `${userInformation.username}`,
              },
            }
          )
          .then((response) => {
            // alert(response.data.msg);
            this.onClickNext();
          })
          .then((json) => {

            this.setState({
              dataa: json,
              loadingg: true,
            });
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }, 2000);
    } else {
      const data = {
        iccid: this.state.iccidd,
        custid: this.state.customerDetail.customerId,
        tariffid: this.state.tariffId.toString(),
      };

      setTimeout(() => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/contract/addContract/repeatingProductId/${this.state.repeatingProductId}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${userInformation.access_token}`,
                User: `${userInformation.username}`,
              },
            }
          )
          .then((response) => {
            // alert(response.data.msg);
            this.onClickNext();
          })
          .then((json) => {

            this.setState({
              dataa: json,
              loadingg: true,
            });
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }, 2000);
    }
  };

  handleUpdateState = (field = "", value = "") => {
    if (field.includes("customerDetail")) {
      field = field.substring(field.indexOf(".") + 1);
      this.setState({
        customerDetail: { ...this.state.customerDetail, [field]: value },
      });
    } else {
      this.setState({ [field]: value });
    }
  };

  componentDidMount() {
    localStorage.removeItem("iccid");

    if (iccidd !== null) {
      this.setState({ iccidd: iccidd });
    }
    if (localStorage) {
      const formDataFromLocalStorage = localStorage.getItem("formData");
      if (formDataFromLocalStorage) {
        const formData = JSON.parse(formDataFromLocalStorage);
        this.setState({ ...formData });
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();
  }

  setRepeatingProductId(id) {
    this.setState({ repeatingProductId: id });
  }
  setRepeatingProductDescription(description) {
    this.setState({ repeatingProductDescription: description });
  }
  repeatingProductIdNextButton() {
    if (this.state.repeatingProductId !== "") {
      this.onClickNext();
    } else {
      this.setState({
        error: true,
      });
    }
  }
  successfulNext() {
    this.setState({
      successful: true,
    });
  }

  render() {
    const { iccid, customerDetail, page, steps, boolean, } = this.state;
    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="activationContainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            {/* <RepeatingProducts /> */}
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SIM ACTIVATION</label>
              </div>
              <div className="col-and" id="jdLogo">
                <GetProfileImage />
              </div>
            </div>
            <br />
            <div>
              <div style={{ width: "95%" }}>
                <Stepper
                  steps={steps}
                  activeStep={page - 1}
                  activeColor="transparent"
                  defaultColor="transparent"
                  completeColor="#0A1330"
                  defaultBarColor="#949697"
                  completeBarColor="#D04102"
                  circleFontColor="#949697"
                  completedCircleFontColor="#D04102"
                  activeTitleColor="#D04102"
                  completeTitleColor="#949697"
                  barStyle="solid"
                  defaultBorderColor="#949697"
                  completeBorderColor="#D04102;"
                  activeBorderColor="#D04102"
                  defaultBorderStyle="solid"
                  completeBorderStyle="solid"
                  activeBorderStyle="solid"
                  circleFontSize={14}
                  titleFontSize={14}
                  lineMarginOffset={0}
                />
              </div>
              <div className="simActivationContainer">
                <Form
                  onSubmit={this.onSubmit}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  {page === 1 && (
                    <div>
                      <br />
                      <div className="form-group">
                        <label id="lblactivation">Enter Sim Number</label>
                        {iccidd === null ? (
                          <Input
                            id="inputactivaton"
                            type="text"
                            name="iccid"
                            className="form-control"
                            placeholder="SIMs Number #"
                            value={iccid}
                            validations={[viccid]}
                            onChange={(event) =>
                              this.handleUpdateState("iccid", event.target.value)
                            }
                          />
                        ) : (
                          <Input
                            id="inputactivaton"
                            type="text"
                            name="iccidd"
                            className="form-control"
                            placeholder="SIMs Number #"
                            value={this.state.iccidd}
                            validations={[viccid]}
                            onChange={(event) =>
                              this.handleUpdateState("iccidd", event.target.value)
                            }
                          />
                        )}
                        {this.state.errorMessage !== null && (
                          <p
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              margin: "10px",
                            }}
                          >
                            {" "}
                            {this.state.errorMessage}{" "}
                          </p>
                        )}
                      </div>
                      <button
                        id="nextFirstBtn"
                        className="btn"
                        onClick={() => {
                          this.submitIccid();
                        }}
                      >
                        Next
                      </button>
                      {this.state.isVisible ? (
                        <></>
                      ) : (
                        <>
                          {!this.state.completed ? (
                            <>
                              {!this.state.loading ? (
                                <Lottie
                                  options={defaultOptions1}
                                  height={200}
                                  width={200}
                                />
                              ) : (
                                <Lottie
                                  options={defaultOptions2}
                                  height={100}
                                  width={100}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {!this.state.successful && page === 2 && (
                    <div>
                      <br />
                      <div className="form-group ml-0 ">
                        <div className="container ml-0 detailsContainer">
                          <div className="row">
                            <div className="col">
                              <label id="lblactivation">
                                Responsible Person{" "}
                                <span className="textnumber2">*</span>
                              </label>
                              <Input
                                id="customer"
                                type="text"
                                name="responsiblePerson"
                                className="form-control"
                                placeholder="Name, surname or company name"
                                value={customerDetail.responsiblePerson}
                                onChange={(event) =>
                                  this.handleUpdateState(
                                    "customerDetail.responsiblePerson",
                                    event.target.value
                                  )
                                }
                                validations={[required, vusername]}
                              />
                            </div>
                            <div className="col">
                              <label id="lblactivation">Company</label>
                              <Input
                                id="customer"
                                type="text"
                                name="company"
                                className="form-control"
                                placeholder="Company name"
                                value={customerDetail.company}
                                onChange={(event) =>
                                  this.handleUpdateState(
                                    "customerDetail.company",
                                    event.target.value
                                  )
                                }
                                validations={[vcompany]}
                              />
                            </div>
                            <div className="w-100"></div>
                            <div className="col">
                              <label id="lblactivation">
                                E-Mail <span className="textnumber2">*</span>
                              </label>
                              <Input
                                id="customer"
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Customers valid e-mail address"
                                value={customerDetail.email}
                                onChange={(event) =>
                                  this.handleUpdateState(
                                    "customerDetail.email",
                                    event.target.value
                                  )
                                }
                                validations={[required, email]}
                              />
                            </div>
                            <div className="col">
                              <label id="lblactivation">
                                Phone <span className="textnumber2">*</span>
                              </label>
                              <Input
                                id="customer"
                                type="text"
                                name="phone"
                                className="form-control"
                                placeholder="Mobile or static phone number"
                                value={customerDetail.phone}
                                onChange={(event) =>
                                  this.handleUpdateState(
                                    "customerDetail.phone",
                                    event.target.value
                                  )
                                }
                                validations={[required, vphone]}
                              />
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="w-100"></div>
                            <div className="container detailsContainer2">
                              <div className="row">
                                <div className="col">
                                  <label id="lblactivation">Country</label>
                                  <Input
                                    id="customeri"
                                    type="text"
                                    style={{ marginTop: "5%" }}
                                    name="country"
                                    className="form-control"
                                    placeholder="please type"
                                    value={customerDetail.country}
                                    disabled
                                  />
                                </div>
                                <div className="col">
                                  <label id="lblactivation">
                                    State <span className="textnumber2">*</span>
                                  </label>
                                  <select
                                    id="customeri"
                                    name="state"
                                    style={{ marginTop: "5px" }}
                                    // value={this.state.role}
                                    // onChange={this.onChangedRole}
                                    // validations={[required, vrole]}
                                    placeholder="please select"
                                    value={customerDetail.state}
                                    onChange={(event) =>
                                      this.handleUpdateState(
                                        "customerDetail.state",
                                        event.target.value
                                      )
                                    }
                                  >
                                    <option selected>SELECT STATE</option>
                                    <option>ALABAMA </option>
                                    <option>ALASKA</option>
                                    <option>ALBERTA</option>
                                    <option>AMERICAN SAMOA</option>
                                    <option>ARIZONA</option>
                                    <option>ARKANSAS</option>
                                    <option>BRITISH COLUMB </option>
                                    <option>CALIFORNIA</option>
                                    <option>COLORADO</option>
                                    <option>CONNECTICUT</option>
                                    <option>DELAWARE</option>
                                    <option>FLORIDA</option>
                                    <option>FOREIGN</option>
                                    <option>GEORGIA</option>
                                    <option>GUAM</option>
                                    <option>HAWAII</option>
                                    <option>IDAHO</option>
                                    <option>ILLINOIS</option>
                                    <option>INDIANA</option>
                                    <option>IOWA</option>
                                    <option>KANSAS</option>
                                    <option>KENTUCKY</option>
                                    <option>LOUISIANA</option>
                                    <option>MAINE</option>
                                    <option>MANITOBA</option>
                                    <option>MARYLAND</option>
                                    <option>MASSACHUSETTS</option>
                                    <option>MICHIGAN</option>
                                    <option>MINNESOTA</option>
                                    <option>MISSISSIPPI</option>
                                    <option>MISSOURI</option>
                                    <option>MONTANA</option>
                                    <option>N. MARIANA ISL</option>
                                    <option>NEBRASKA</option>
                                    <option>NEVADA</option>
                                    <option>NEW BRUNSWICK</option>
                                    <option>NEW HAMPSHIRE</option>
                                    <option>NEW JERSEY</option>
                                    <option>NEW MEXICO</option>
                                    <option>NEW YORK</option>
                                    <option>NEWFOUNDLAND</option>
                                    <option>NORTH CAROLINA</option>
                                    <option>NORTH DAKOTA</option>
                                    <option>NORTHWEST TERR</option>
                                    <option>NOVA SCOTIA</option>
                                    <option>NUNAVUT</option>
                                    <option>OHIO</option>
                                    <option>OKLAHOMA</option>
                                    <option>ONTARIO</option>
                                    <option>OREGON</option>
                                    <option>PENNSYLVANIA</option>
                                    <option>PRINCE EDWARD</option>
                                    <option>PUERTO RICO</option>
                                    <option>QUEBEC</option>
                                    <option>RHODE ISLAND</option>
                                    <option>SASKATCHEWAN</option>
                                    <option>SOUTH CAROLINA</option>
                                    <option>SOUTH DAKOTA</option>
                                    <option>TENNESSEE</option>
                                    <option>TEXAS</option>
                                    <option>UTAH</option>
                                    <option>VERMONT</option>
                                    <option>VIRGIN INLANDS</option>
                                    <option>VIRGINIA</option>
                                    <option>WASHINGTON</option>
                                    <option>WASHINGTON D.C.</option>
                                    <option>WEST VIRGINIA</option>
                                    <option>WISCONSIN</option>
                                    <option>YUKON TERR</option>
                                  </select>
                                </div>
                                <div className="col">
                                  <label id="lblactivation">
                                    City <span className="textnumber2">*</span>
                                  </label>
                                  <Input
                                    id="customeri"
                                    type="text"
                                    style={{ marginTop: "4%" }}
                                    name="city"
                                    className="form-control"
                                    placeholder="please type"
                                    value={customerDetail.city}
                                    onChange={(event) =>
                                      this.handleUpdateState(
                                        "customerDetail.city",
                                        event.target.value
                                      )
                                    }
                                    validations={[required, vcity]}
                                  />
                                </div>
                                <div className="col">
                                  <label id="lblactivation">
                                    Zipcode <span className="textnumber2">*</span>
                                  </label>
                                  <Input
                                    id="customeri"
                                    type="text"
                                    style={{ marginTop: "4%" }}
                                    name="zipcode"
                                    className="form-control"
                                    placeholder="please type"
                                    value={customerDetail.zipcode}
                                    onChange={(event) =>
                                      this.handleUpdateState(
                                        "customerDetail.zipcode",
                                        event.target.value
                                      )
                                    }
                                    validations={[required, vzipCode]}
                                  />
                                </div>
                                <div className="w-100"></div>
                                <div className="col">
                                  <label id="lblactivation">
                                    Address Line 1{" "}
                                    <span className="textnumber2">*</span>
                                  </label>
                                  <Input
                                    id="customer"
                                    type="text"
                                    name="addressLineOne"
                                    className="form-control"
                                    placeholder="Business or residental address"
                                    value={customerDetail.addressLineOne}
                                    onChange={(event) =>
                                      this.handleUpdateState(
                                        "customerDetail.addressLineOne",
                                        event.target.value
                                      )
                                    }
                                    validations={[required, vaddressLineOne]}
                                  />
                                </div>
                                <div className="col">
                                  <label id="lblactivation">Address Line 2</label>
                                  <Input
                                    id="customer"
                                    type="text"
                                    name="addressLineTwo"
                                    className="form-control"
                                    placeholder="Business or residental address"
                                    value={customerDetail.addressLineTwo}
                                    onChange={(event) =>
                                      this.handleUpdateState(
                                        "customerDetail.addressLineTwo",
                                        event.target.value
                                      )
                                    }
                                    validations={[vaddressLineTwo]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br /> <br /> <br />
                        <div>
                          <button
                            id="previousbtn"
                            className="btn m-2"
                            type="button"
                            onClick={() => this.onClickPrev()}
                          >
                            Previous
                          </button>
                          {this.state.customerDetail.responsiblePerson === "" ||
                            this.state.customerDetail.email === "" ||
                            this.state.customerDetail.phone === "" ||
                            this.state.customerDetail.state === "" ||
                            this.state.customerDetail.city === "" ||
                            this.state.customerDetail.zipcode === "" ||
                            this.state.customerDetail.addressLineOne === "" ? (
                            <button
                              id="nextbtn"
                              className="btn m-2"
                              disabled
                              onClick={() => {
                                this.form.validateAll();
                                this.onClickNext();
                              }}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              id="nextbtn"
                              className="btn m-2"
                              onClick={() => {
                                this.form.validateAll();
                                this.onClickNext();
                              }}
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {page === 3 && (
                    <div>
                      <br />
                      <div className="form-group">
                        <div>
                          <RepeatingProducts
                            simType={this.state.simType}
                            repeatingProductId={this.state.repeatingProductId}
                            repeatingProductDescription={
                              this.state.repeatingProductDescription
                            }
                            setRepeatingProductId={(id) =>
                              this.setState({ repeatingProductId: id })
                            }
                            setTariffId={(tariffId) =>
                              this.setState({ tariffId: tariffId })
                            }
                            setRepeatingProductDescription={(description) =>
                              this.setState({
                                repeatingProductDescription: description,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          id="previousbtn"
                          className="btn m-2"
                          type="button"
                          onClick={() => {
                            this.onClickPrev();
                            this.setState({
                              repeatingProductId: ""
                            })
                          }}
                        >
                          Previous
                        </button>
                        {this.state.repeatingProductId === "" ? (
                          <button disabled id="nextbtn" className="btn m-2">
                            Next
                          </button>
                        ) : (
                          <button
                            id="nextbtn"
                            className="btn m-2"
                            onClick={() => {
                              this.repeatingProductIdNextButton();
                            }}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {page === 4 && (
                    <div>
                      <br />
                      <div className="form-group">
                        <div>
                          <label id="lblactivation">Defined Sim Number</label>
                          <label id="simnumber">
                            {
                              (this.state.iccidd != ""
                                ? this.state.iccidd
                                : this.state.iccid)
                            }
                          </label>
                        </div>
                        <br />
                        <div>
                          <label id="lblactivation">Customer Details</label>
                          <div className="row" style={{ paddingLeft: "12px" }}>
                            <div className="col-3">
                              <label id="txt">
                                Responsible Person:{" "}
                                {this.state.customerDetail.responsiblePerson}
                              </label>
                              <label id="txt">
                                Company Name: {this.state.customerDetail.company}
                              </label>
                              <label id="txt">
                                E-mail: {this.state.customerDetail.email}
                              </label>
                              <label id="txt">
                                Phone: {this.state.customerDetail.phone}
                              </label>
                            </div>
                            <div className="col-3">
                              <label id="txt">
                                Address: {this.state.customerDetail.addressLineOne}
                              </label>
                              <label id="txt">
                                {this.state.customerDetail.addressLineTwo}
                              </label>
                            </div>
                            <div className="col-3">
                              <label id="txt">
                                Zipcode: {this.state.customerDetail.zipcode}
                              </label>
                              <label id="txt">
                                City: {this.state.customerDetail.city}
                              </label>
                              <label id="txt">
                                State: {this.state.customerDetail.state}
                              </label>
                              <label id="txt">Country: USA</label>
                            </div>
                          </div>
                          <br />
                          <div>
                            <label id="lblactivation">Selected Product Plan</label>
                            <label className="selectedPlan ml-3">
                              {this.state.simType === "M2M"
                                ? this.state.repeatingProductDescription.split(
                                  " ",
                                  2
                                )
                                : this.state.repeatingProductDescription}
                            </label>
                          </div>
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                      <br />
                      <br />
                      <div>
                        {this.state.isVisiblee ? (
                          <>
                            <button
                              id="previous2btn"
                              className="btn m-2"
                              type="button"
                              disabled
                              onClick={() => this.onClickPrev()}
                            >
                              Previous
                            </button>
                            <button
                              disabled
                              id="submitbtn"
                              className="btn m-2"
                              onClick={() => {
                                this.addCustomer();
                              }}
                            >
                              Submit
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              id="previous2btn"
                              className="btn m-2"
                              type="button"
                              onClick={() => {
                                this.onClickPrev();
                                this.setState({
                                  repeatingProductId: ""
                                })
                              }}
                            >
                              Previous
                            </button>
                            <button
                              id="submitbtn"
                              className="btn m-2"
                              onClick={() => {
                                this.addCustomer();
                              }}
                            >
                              Submit
                            </button>
                          </>
                        )}
                      </div>
                      {this.state.isVisiblee ? (
                        <>
                          {true ? (
                            <>
                              {!this.state.loadingg ? (
                                <Lottie
                                  options={defaultOptions1}
                                  height={200}
                                  width={200}
                                />
                              ) : (
                                <Lottie
                                  options={defaultOptions2}
                                  height={100}
                                  width={100}
                                />
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {page === 5 && (
                    <div>
                      <div className="form-group">
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="row justify-content-center">
                          <div className="col-1">
                            <img src={finish} id="finish" alt="Finish Image" />
                          </div>
                          <div className="col-2">
                            <label id="lblfinish">All is done!</label>
                          </div>
                        </div>
                      </div>
                      <Link to="/simManagement">
                        <button type="submit" id="finishbtn" className="btn mt-5">
                          Finish
                        </button>
                      </Link>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
