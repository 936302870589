import React, { Component } from 'react'
// import DoughnutChart from './totalUserChart/index'
// import jdLogo from "../images/jdLogo.png";
// import Frame from "../images/Frame.png";
// import TotalUserChart from './totalUserChart/index';
// import { SimActivationChart } from './simActivationChart/SimActivationChart';
import DefinedDoughtnutChart from './doughtnutChart/DefinedDoughtnutChart';
import TotalDoughtnutChart from './doughtnutChart/TotalDoughtnutChart';
import ApexChart from './barChart/BarChart';
import "./homeStyle.css";
import MapIndex from './map/MapIndex';
import PlanPackage from './planPackage/planPackage';
import GetProfileImage from './getProfileImage/GetProfileImage';
import cleanLoginBg from "../images/beyazZemin.png";
import cleanLoginFrame from "../images/cleanLoginFrame.png";
import SideMenu from "../SideMenu";

// import MapApp from './mapp/MapApp';
var userInformation = JSON.parse(localStorage.getItem("user"));


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boolean: false,
    };
  }

  render() {
    let { boolean, } = this.state;

    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          {userInformation.dealerType == "CleanIot" ? (<>
            <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
            <img src={cleanLoginFrame} className="cleanBg" alt="login bg frame" />
          </>) : (null)}
          <div className="maincontainer">
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>DASHBOARD</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <div className="containerDashboard">
              <div className="row">
                <div className="col colA" style={{ paddingLeft: "0px" }}>
                  <div className="boxTotal">
                    <TotalDoughtnutChart />
                  </div>
                  <br />
                  <div className="w-100"></div>
                  <br /><br />
                  <div className="boxDefined">
                    <DefinedDoughtnutChart />
                  </div>
                </div>
                <div className="col colB">
                  <div className="boxMap">
                    <MapIndex />
                    {/* <MapApp /> */}
                  </div>
                  <div className="w-100"></div>
                  <div className="boxApex">
                    <ApexChart />
                  </div>
                </div>
                <div className="col-and colC" style={{ paddingRight: "0px", }}>
                  <PlanPackage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}