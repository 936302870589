import React, { Component } from "react";
import axios from "axios";
import jdLogo from "../images/jdLogo.png";
import { isEmail } from "validator";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import "./addStyle.css";
import Lottie from "react-lottie";
import SideMenu from "../SideMenu";
import * as location from ".././1055-world-locations.json";
import * as success from ".././1127-success.json";
import GetProfileImage from "./getProfileImage/GetProfileImage";
var userInformation = JSON.parse(localStorage.getItem("user"));


const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div id="validAlert" className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

class add extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      dealerCode: "",
      isVisible: false,
      loading: false,
      errorMessage: false,
      dealerType: "",
    };
  }

  addDealer() {
    this.setState({
      isVisible: true,
      errorMessage: false,
    });
    setTimeout(() => {
      const data = {
        username: this.state.username,
        dealerCode: this.state.dealerCode,
        dealerType: this.state.dealerType
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/user/saveUser`, data, {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/api/user/addRoleToUser/username/${this.state.username}/role/ROLE_ADMIN`, {},
                {
                  headers: {
                    Authorization: `Bearer ${userInformation.access_token}`,
                    User: `${userInformation.username}`,
                  },
                }
              )
              .then((res) => {
                this.setState({
                  loading: true,
                });
                setTimeout(() => {
                  window.location.href = "/";
                }, 4000);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })

        .catch((error) => {
          console.log(error);
          this.setState({
            isVisible: false,
            errorMessage: error.response.data.message,
          });
        });
    }, 2000);
  }

  handleUpdateState = (field = "", value = "") => {
    this.setState({ [field]: value });
  };



  changedealerType = (event) => {
    this.setState({
      dealerType: event.target.value,
    });
  };
  render() {
    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: location.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: success.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    
    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="content" style={{ width: "95%" }}>
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>ADD DEALER</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>

            <div id="test-l-1" className="content">
              <Form>
                <div className="form-group">
                  <label id="lblactivation">Enter Dealer Code</label>
                  <input
                    id="inputactivaton"
                    type="text"
                    name="dealerCode"
                    className="form-control"
                    placeholder="Dealer Code"
                    value={this.state.dealerCode}
                    required
                    onChange={(event) =>
                      this.handleUpdateState("dealerCode", event.target.value)
                    }
                  />
                </div>
                <div className="form-group">
                  <label id="lblactivation">Enter Dealer E-Mail</label>
                  <Input
                    id="inputactivaton"
                    type="email"
                    name="username"
                    className="form-control"
                    placeholder="Dealer E-Mail"
                    value={this.state.username}
                    required
                    validations={[email]}
                    onChange={(event) =>
                      this.handleUpdateState("username", event.target.value)
                    }
                  />
                </div>

                <select
                  className="mt-2"
                  id="customeria"
                  name="category"
                  placeholder="please select category of your problem "
                  value={this.state.dealerType}
                  onChange={this.changedealerType}
                  required
                >
                  <option value="MVNO">MVNO</option>
                  <option value="CleanIot">CleanIot</option>
                </select>




              </Form>
              <br /> <br />
              {this.state.errorMessage && (
                <h1 style={{ color: "#fefefe" }}> {this.state.errorMessage} </h1>
              )}
              <button
                id="addNextBtn"
                className="btn"
                onClick={() => {
                  this.addDealer();
                }}
              >
                Submit
              </button>
              {!this.state.isVisible ? (
                <></>
              ) : (
                <>
                  {true ? (
                    <>
                      {!this.state.loading ? (
                        <Lottie
                          options={defaultOptions1}
                          height={200}
                          width={200}
                        />
                      ) : (
                        <Lottie
                          options={defaultOptions2}
                          height={100}
                          width={100}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default add;
