import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import EventBus from "./common/EventBus";
import ioiLogo from "./images/iointel.png";
import a from "./images/a.png";
import deshBoard from "./images/deshBoard.png";
import sims from "./images/sims.png";
import user from "./images/user.png";
import billing from "./images/billing.png";
import settings from "./images/settings.png";
import help from "./images/help.png";
import addUser from "./images/addUser.png";
import simOrder from "./images/simOrder.png";
import plus from "./images/plus.png";
import cleanLogo2 from "./images/cleanLogo2.png";
import dashboardClean from "./images/dashboardClean.png";
import simClean from "./images/simClean.png";
import plusClean from "./images/plusClean.png";
import userClean from "./images/userClean.png";
import billingClean from "./images/billingClean.png";
import settingsClean from "./images/settingsClean.png";
import supportClean from "./images/supportClean.png";
import { Router, Switch, Route, Link, Redirect } from "react-router-dom";
import pricing from "./images/pricing.png";

var userInformation = JSON.parse(localStorage.getItem("user"));

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            showSuperAdminBoard: false,
            showUserBoard: false,
            currentUser: [],
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
            boolean: false,
        };

        history.listen((location) => {
            props.dispatch(clearMessage()); // clear message when changing location
        });
    }
    componentDidMount() {
        const user = this.props.user;

        if (user) {
            user.role === "ROLE_SUPERADMIN"
                ? this.setState({
                    showSuperAdminBoard: true,
                    currentUser: user,
                })
                : this.setState({
                    currentUser: user,
                    showModeratorBoard: user.role.includes("ROLE_MOD"),
                    showAdminBoard: user.role.includes("ROLE_ADMIN"),
                    showUserBoard: user.role.includes("ROLE_USER"),
                });
        }

        EventBus.on("logout", () => {
            this.logOut();
        });
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    logOut() {
        this.props.dispatch(logout());
        this.setState({
            showModeratorBoard: false,
            showAdminBoard: false,
            showSuperAdminBoard: false,
            showUserBoard: false,
            currentUser: undefined,
        });
    }

    setVisible() {
        this.setState({
            isVisible: true,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleA() {
        this.setState({
            isVisible: false,
            isVisibleA: true,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleB() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: true,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleC() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: true,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleD() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: true,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleE() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: true,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleF() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: true,
            isVisibleG: false,
            isVisibleH: false,
        });
    }
    setVisibleG() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: true,
            isVisibleH: false,
        });
    }
    setVisibleH() {
        this.setState({
            isVisible: false,
            isVisibleA: false,
            isVisibleB: false,
            isVisibleC: false,
            isVisibleD: false,
            isVisibleE: false,
            isVisibleF: false,
            isVisibleG: false,
            isVisibleH: true,
        });
    }
    render() {
        const {
            currentUser,
            showModeratorBoard,
            showAdminBoard,
            showSuperAdminBoard,
            showUserBoard,
            boolean,
        } = this.state;
        return (
            <div>
                <nav id="menu" className="navbar flex-column">
                    <div id="ioilogo">
                        {userInformation.dealerType == "CleanIot" ? (<img src={cleanLogo2} alt="IOT Logo" />) : (<img src={ioiLogo} alt="IOI Logo" />)}
                    </div>
                    <div className="navbar-nav mr-auto">
                        {userInformation.role === "ROLE_SUPERADMIN" && (
                            <>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisible();
                                    }}
                                >
                                    <a href={"/"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/createNewIccid") ||
                                                window.location.href.includes("/add") ||
                                                window.location.href.includes("/userManagement") ||
                                                window.location.href.includes("/billing") ||
                                                window.location.href.includes("/pricing") ||
                                                window.location.href.includes("/settings") ||
                                                window.location.href.includes("/Support") ||
                                                window.location.href.includes("/bulkUpload") ||
                                                window.location.href.includes("/simActivation") ? (

                                                null
                                            ) :
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            }
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={dashboardClean} alt="deshboard" />) : (<img className="p-2" src={deshBoard} alt="deshboard" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Dashboard
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                {/* <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisible();
                                    }}
                                >
                                    <a href={"/"} className="nav-link">
                                        <div className="row" id="menuitem" >

                                            {window.location.href.includes("/settings") ||
                                                window.location.href.includes("/createNewIccid") ||
                                                window.location.href.includes("/Support") ||
                                                window.location.href.includes("/bulkUpload") ||
                                                window.location.href.includes("/add") ?
                                                (
                                                    null
                                                ) :
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            }
                                            <img className="p-2" src={deshBoard} alt="deshboard" />
                                            <label style={{ cursor: "pointer" }}>
                                                Dashboard
                                            </label>
                                        </div>
                                    </a>
                                </li> */}

                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target="#newIccıd"
                                    className="collapsed"
                                    onClick={() => {
                                        this.setVisibleA();
                                    }}
                                >
                                    <a href={"/createNewIccid"} className="nav-link">
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/createNewIccid") || window.location.href.includes("/bulkUpload") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            <img className="p-2" src={sims} alt="Creat New ICCID" />
                                            <label style={{ cursor: "pointer" }}>
                                                Create New ICCID
                                                <img src={plus} className="plus" alt="plus" />
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                {window.location.href.includes("/createNewIccid") ? (
                                    <>
                                        <ul id="newIccıd">
                                            <Link
                                                to={"/bulkUpload"}
                                                className="nav-link"
                                                style={{ padding: "0px" }}
                                            >
                                                <div className="row" className="menuitemSim">
                                                    <li>
                                                        <label style={{ cursor: "pointer" }}>
                                                            Bulk Upload
                                                        </label>
                                                    </li>
                                                </div>
                                            </Link>
                                        </ul>
                                    </>
                                ) : null}

                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleD();
                                    }}
                                >
                                    <a href={"/add"} className="nav-link">
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/add") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            <img
                                                id="addLogo"
                                                className="p-2"
                                                style={{ width: "37px", height: "35px" }}
                                                src={addUser}
                                                alt="add dealer"
                                            />
                                            <label style={{ cursor: "pointer" }}>
                                                Add Dealer
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleF();
                                    }}
                                >
                                    {window.location.href.includes("/settings") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/settings"} className="nav-link">
                                        <div className="row" id="menuitem">
                                            <img
                                                className="p-2"
                                                src={settings}
                                                alt="settings"
                                            />
                                            <label style={{ cursor: "pointer" }}>
                                                Settings
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleG();
                                    }}
                                >
                                    <a href={"/SupportIointel"} className="nav-link">
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/SupportIointel") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            <img className="p-2" src={help} alt="help" />
                                            <label style={{ cursor: "pointer" }}>Support</label>
                                        </div>
                                    </a>
                                </li>
                            </>
                        )}

                        {userInformation.role === "ROLE_ADMIN" && (
                            <>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisible();
                                    }}
                                >
                                    <a href={"/"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simManagement") ||
                                                window.location.href.includes("/simOrder") ||
                                                window.location.href.includes("/userManagement") ||
                                                window.location.href.includes("/billing") ||
                                                window.location.href.includes("/pricing") ||
                                                window.location.href.includes("/settings") ||
                                                window.location.href.includes("/Support") ||
                                                window.location.href.includes("/simActivation") ? (

                                                null
                                            ) :
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            }
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={dashboardClean} alt="deshboard" />) : (<img className="p-2" src={deshBoard} alt="deshboard" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Dashboard
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target="#new"
                                    className="collapsed"
                                    onClick={() => {
                                        this.setVisibleA();
                                    }}
                                >
                                    <a href={"/simManagement"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simManagement") || window.location.href.includes("/simActivation")  ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={simClean} alt="SIMs management" />) : (<img className="p-2" src={sims} alt="SIMs management" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                SIMs Management
                                                {window.location.href.includes(
                                                    "/simManagement"
                                                ) ? (
                                                    userInformation.dealerType == "CleanIot" ? (<img src={plusClean} className="plus" alt="plus" />) : (<img src={plus} className="plus" alt="plus" />)
                                                ) : null}
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                {window.location.href.includes("/simManagement") ? (
                                    <>
                                        <ul id="new">
                                            <Link
                                                to={"/simActivation"}
                                                className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}
                                                style={{ padding: "0px" }}
                                            >
                                                <div className="row" className={userInformation.dealerType == "CleanIot" ? "menuitemSimClean" : "menuitemSim"}>
                                                    <li>
                                                        <label style={{ cursor: "pointer" }}>
                                                            Sim Activation
                                                        </label>
                                                    </li>
                                                </div>
                                            </Link>
                                        </ul>
                                    </>
                                ) : null}
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleB();
                                    }}
                                >
                                    <a href={"/simOrder"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simOrder") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img
                                                className="p-2"
                                                style={{ width: "36px", height: "37px" }}
                                                src={simOrder}
                                                alt="Sim Order Logo"
                                            />) : (<img
                                                className="p-2"
                                                style={{ width: "36px", height: "37px" }}
                                                src={simOrder}
                                                alt="Sim Order Logo"
                                            />)}
                                            <label
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                SIM-CARD Order
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleC();
                                    }}
                                >
                                    <a href={"/userManagement"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes(
                                                "/userManagement"
                                            ) ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={userClean} alt="User management" />) : (<img className="p-2" src={user} alt="User management" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                User Management
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleE();
                                    }}
                                >
                                    <a href={"/billing"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/billing") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={billingClean} alt="Billing" />) : (<img className="p-2" src={billing} alt="Billing" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Billing
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleH();
                                    }}
                                >
                                    {window.location.href.includes("/pricing") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/pricing"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" style={{ width: "36px", height: "37px" }} src={pricing} alt="Pricing" />) : (<img className="p-2" style={{ width: "36px", height: "37px" }} src={pricing} alt="Pricing" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Pricing
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleF();
                                    }}
                                >
                                    {window.location.href.includes("/settings") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/settings"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={settingsClean} alt="Settings" />) : (<img className="p-2" src={settings} alt="Settings" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Settings
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleG();
                                    }}
                                >
                                    <a href={"/Support"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/Support") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={supportClean} alt="Help" />) : (<img className="p-2" src={help} alt="Help" />)}
                                            <label style={{ cursor: "pointer" }}>Support</label>
                                        </div>
                                    </a>
                                </li>
                            </>
                        )}

                        {userInformation.role === "ROLE_MOD" && (
                            <>
                                {/* <GetProfileImage /> */}

                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisible();
                                    }}
                                >
                                    <a href={"/"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simManagement") ||
                                                window.location.href.includes("/simOrder") ||
                                                window.location.href.includes("/userManagement") ||
                                                window.location.href.includes("/billing") ||
                                                window.location.href.includes("/pricing") ||
                                                window.location.href.includes("/settings") ||
                                                window.location.href.includes("/Support") ||
                                                window.location.href.includes("/simActivation") ? (

                                                null
                                            ) :
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            }
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={dashboardClean} alt="deshboard" />) : (<img className="p-2" src={deshBoard} alt="deshboard" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Dashboard
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target="#new"
                                    className="collapsed"
                                    onClick={() => {
                                        this.setVisibleA();
                                    }}
                                >
                                    <a href={"/simManagement"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simManagement") || window.location.href.includes("/simActivation")  ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={simClean} alt="SIMs management" />) : (<img className="p-2" src={sims} alt="SIMs management" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                SIMs Management
                                                {window.location.href.includes(
                                                    "/simManagement"
                                                ) ? (
                                                    userInformation.dealerType == "CleanIot" ? (<img src={plusClean} className="plus" alt="plus" />) : (<img src={plus} className="plus" alt="plus" />)
                                                ) : null}
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                {window.location.href.includes("/simManagement") ? (
                                    <>
                                        <ul id="new">
                                            <Link
                                                to={"/simActivation"}
                                                className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}
                                                style={{ padding: "0px" }}
                                            >
                                                <div className="row" className={userInformation.dealerType == "CleanIot" ? "menuitemSimClean" : "menuitemSim"}>
                                                    <li>
                                                        <label style={{ cursor: "pointer" }}>
                                                            Sim Activation
                                                        </label>
                                                    </li>
                                                </div>
                                            </Link>
                                        </ul>
                                    </>
                                ) : null}
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleB();
                                    }}
                                >
                                    <a href={"/simOrder"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simOrder") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img
                                                className="p-2"
                                                style={{ width: "36px", height: "37px" }}
                                                src={simOrder}
                                                alt="Sim Order Logo"
                                            />) : (<img
                                                className="p-2"
                                                style={{ width: "36px", height: "37px" }}
                                                src={simOrder}
                                                alt="Sim Order Logo"
                                            />)}
                                            <label
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                SIM-CARD Order
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleC();
                                    }}
                                >
                                    <a href={"/userManagement"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes(
                                                "/userManagement"
                                            ) ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={userClean} alt="User management" />) : (<img className="p-2" src={user} alt="User management" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                User Management
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleH();
                                    }}
                                >
                                    {window.location.href.includes("/pricing") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/pricing"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" style={{ width: "36px", height: "37px" }} src={pricing} alt="Pricing" />) : (<img className="p-2" style={{ width: "36px", height: "37px" }} src={pricing} alt="Pricing" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Pricing
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleF();
                                    }}
                                >
                                    {window.location.href.includes("/settings") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/settings"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={settingsClean} alt="Settings" />) : (<img className="p-2" src={settings} alt="Settings" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Settings
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleG();
                                    }}
                                >
                                    <a href={"/Support"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/Support") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={supportClean} alt="Help" />) : (<img className="p-2" src={help} alt="Help" />)}
                                            <label style={{ cursor: "pointer" }}>Support</label>
                                        </div>
                                    </a>
                                </li>
                            </>
                        )}

                        {userInformation.role === "ROLE_USER" && (
                            <>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisible();
                                    }}
                                >
                                    <a href={"/"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simManagement") ||
                                                window.location.href.includes("/simOrder") ||
                                                window.location.href.includes("/userManagement") ||
                                                window.location.href.includes("/billing") ||
                                                window.location.href.includes("/pricing") ||
                                                window.location.href.includes("/settings") ||
                                                window.location.href.includes("/Support") ||
                                                window.location.href.includes("/simActivation") ? (

                                                null
                                            ) :
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            }
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={dashboardClean} alt="deshboard" />) : (<img className="p-2" src={deshBoard} alt="deshboard" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Dashboard
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    data-toggle="collapse"
                                    data-target="#new"
                                    className="collapsed"
                                    onClick={() => {
                                        this.setVisibleA();
                                    }}
                                >
                                    <a href={"/simManagement"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/simManagement") || window.location.href.includes("/simActivation")  ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={simClean} alt="SIMs management" />) : (<img className="p-2" src={sims} alt="SIMs management" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                SIMs Management
                                                {window.location.href.includes(
                                                    "/simManagement"
                                                ) ? (
                                                    userInformation.dealerType == "CleanIot" ? (<img src={plusClean} className="plus" alt="plus" />) : (<img src={plus} className="plus" alt="plus" />)
                                                ) : null}
                                            </label>
                                        </div>
                                    </a>
                                </li>

                                {window.location.href.includes("/simManagement") ? (
                                    <>
                                        <ul id="new">
                                            <Link
                                                to={"/simActivation"}
                                                className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}
                                                style={{ padding: "0px" }}
                                            >
                                                <div className="row" className={userInformation.dealerType == "CleanIot" ? "SimmenuitemSimClean" : "menuitem"}>
                                                    <li>
                                                        <label style={{ cursor: "pointer" }}>
                                                            Sim Activation
                                                        </label>
                                                    </li>
                                                </div>
                                            </Link>
                                        </ul>
                                    </>
                                ) : null}
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleH();
                                    }}
                                >
                                    {window.location.href.includes("/pricing") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/pricing"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" style={{ width: "36px", height: "37px" }} src={pricing} alt="Pricing" />) : (<img className="p-2" style={{ width: "36px", height: "37px" }} src={pricing} alt="Pricing" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Pricing
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleF();
                                    }}
                                >
                                    {window.location.href.includes("/settings") ? (
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                paddingTop: "8px",
                                            }}
                                        >
                                            <img src={a} alt="a" />
                                        </div>
                                    ) : null}
                                    <a href={"/settings"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={settingsClean} alt="Settings" />) : (<img className="p-2" src={settings} alt="Settings" />)}
                                            <label style={{ cursor: "pointer" }}>
                                                Settings
                                            </label>
                                        </div>
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setVisibleG();
                                    }}
                                >
                                    <a href={"/Support"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                        <div className="row" id="menuitem">
                                            {window.location.href.includes("/Support") ? (
                                                <div
                                                    style={{ position: "absolute", left: "0px" }}
                                                >
                                                    <img src={a} alt="a" />
                                                </div>
                                            ) : null}
                                            {userInformation.dealerType == "CleanIot" ? (<img className="p-2" src={supportClean} alt="Help" />) : (<img className="p-2" src={help} alt="Help" />)}
                                            <label style={{ cursor: "pointer" }}>Support</label>
                                        </div>
                                    </a>
                                </li>
                            </>
                        )}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        {userInformation ? (
                            <div className="navbar-nav "></div>
                        ) : (
                            <div className="navbar-nav ">
                                {currentUser && (
                                    <li className="nav-item">
                                        <a href={"/"} className={userInformation.dealerType == "CleanIot" ? "nav-linka" : "nav-link"}>
                                            <div className="row" id="menuitem">
                                                <label style={{ cursor: "pointer" }}>
                                                    Login
                                                </label>
                                            </div>
                                        </a>
                                    </li>
                                )}
                            </div>
                        )}
                    </div>
                </nav>
            </div>
        )
    }
}
export default SideMenu;