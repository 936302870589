import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "./user.management.page.component.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSortDown } from "@fortawesome/free-solid-svg-icons";
import ModalAddUser from "./modalAddUser";
import Register from "./addUser";
import UserUpdateModal from "./update";
import axios from "axios";
import action from "../images/action.png";
import actionClean from "../images/actionClean.png";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";

var userInformation = JSON.parse(localStorage.getItem("user"));

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      boolean: false,
      filter: "",
      contacts: [],
      showActives: true,
      selectedContact: null,
    };
    this.closePopup = this.closePopup.bind(this);
    this.delete = this.delete.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({
      isLoading: true,
      contacts: [],
    });

    fetch(
      `${process.env.REACT_APP_API_URL}/api/user/getUsers/dealerCode/` +
      userInformation.dealerCode,
      {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      }
    )
      .then((response) => response.json())

      .then((parsedJSON) =>
        parsedJSON.map((user) => ({
          // id: `${user.id}`,
          username: `${user.username}`,
          email: `${user.username}`,
          name: `${user.name}`,
          department: `${user.department}`,
          isActive: `${user.enabled ? 1 : 0}`,
          phone: `${user.phone}`,
          state: `${user.state}`,
          country: `${user.country}`,
          city: `${user.city}`,
          zipcode: `${user.zipcode}`,
          addressLine1: `${user.addressLine1}`,
          addressLine2: `${user.addressLine2}`,
          dealerCode: `${user.dealerCode}`,
          role: `${user.role}`,
          modified_date: `${user.modified_date}`.split(" ", 1),
        }))
      )
      .then((contacts) => {
        this.setState({
          contacts,
          isLoading: false,
        });
      })
      .catch((error) => console.log("parsing failed", error));
  }

  async delete(contract) {
    this.setState({ loading: true });
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/changeStatus/username/${contract.username}/status/deactivate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      }
    );
    this.closePopup();
    await this.fetchData();
    this.setState({ loading: false });
  }

  searchTxt(e) {
    this.setState({ filter: e.target.value });
  }
  updateActive(active) {
    this.setState({ showActives: active });
  }
  closePopup() {
    this.setState({ selectedContact: null });
  }
  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.contacts];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ contacts: arrayCopy });
  }
  render() {
    let { filter, contacts, showActives, Datasearch, isLoading, boolean } = this.state;
    const data = contacts.filter((item) => item.isActive == showActives);

    Datasearch = data.filter((item) => {
      return Object.keys(item).some((key) => item[key].includes(filter));
    });

    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="maincontainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>USER MANAGEMENT</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <div className="row ">
              <div className="col status">
                <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"}>User Status</label>
                <ButtonGroup aria-label="Basic example">
                  <button
                    className={userInformation.dealerType == "CleanIot" ? (showActives ? "activatedbtnClean" : "deactivatedbtnClean") : (showActives ? "activatedbtn" : "deactivatedbtn")}
                    onClick={() => this.updateActive(true)}
                  >
                    Activated
                  </button>
                  <button
                    className={userInformation.dealerType == "CleanIot" ? (showActives ? "deactivatedbtnClean" : "activatedbtnClean") : (showActives ? "deactivatedbtn" : "activatedbtn")}
                    onClick={() => this.updateActive(false)}
                  >
                    Deactivated
                  </button>
                </ButtonGroup>
              </div>
              <div className="col search">
                <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"}>Search User</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={userInformation.dealerType == "CleanIot" ? "form-control searchstyleClean" : "form-control searchstyle"}
                    placeholder="Username, Role"
                    value={filter}
                    onChange={this.searchTxt.bind(this)}
                  />
                  {userInformation.dealerType == "CleanIot" ? (<>
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        id="searchicon"
                        style={{
                          background: "#000000",
                          color: "#B1CB32",
                          borderEndEndRadius: "4px",
                          borderStartEndRadius: "4px",
                        }}
                      ><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                  </>) : (<>
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        id="searchicon"
                        style={{
                          background: "#0A1330",
                          color: "#D04102",
                          borderEndEndRadius: "4px",
                          borderStartEndRadius: "4px",
                        }}
                      ><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                  </>)}
                </div>
              </div>
              <div className="col align-items-end">
                <ModalAddUser />
                <Register
                  closePopup={this.closePopup}
                  reFetch={this.fetchData}
                  title="Add User"
                />
              </div>
            </div>
            <br />

            <table className={userInformation.dealerType == "CleanIot" ? "aClean" : "a"}>
              <thead className={userInformation.dealerType == "CleanIot" ? "theadClean" : "thead"}>
                <tr>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("name")}
                  >
                    Full Name
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>

                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("username")}
                  >
                    E-Mail Details
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col">Assigned Role</th>
                  <th scope="col">Last Modified</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody id="tablebody">
                {!isLoading && contacts.length > 0
                  ? Datasearch.map((contact) => (
                    <tr key={contact.username} className="border_bottom">
                      <td>{contact.name}</td>
                      <td>{contact.username}</td>
                      <td
                        className={
                          contact.isActive == 1 ? "activated" : "deactivated"
                          // userInformation.dealerType == "CleanIot" ? (contact.isActive == 1 ? "activated" : "deactivated") : (contact.isActive == 1 ? "activatedClean" : "deactivatedClean")
                        }
                      >
                        {contact.isActive == 1 ? "ACTIVATED" : "DEACTIVATED"}
                      </td>
                      <td>{contact.role}</td>
                      <td>
                        {contact.modified_date[0] === "null"
                          ? "-"
                          : contact.modified_date}
                      </td>
                      <td>
                        <div className="btn-group dropup">
                          <button
                            id="dropupbtn"
                            type="button"
                            className="btn"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {userInformation.dealerType == "CleanIot" ? (<img src={actionClean} alt="Action Logo" />) : (<img src={action} alt="Action Logo" />)}
                          </button>
                          {contact.role !== "ROLE_ADMIN" ? (
                            <ul className="dropdown-menu">
                              <li
                                className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                                onClick={() =>
                                  this.setState({ selectedContact: contact })
                                }
                              >
                                Edit
                              </li>
                              {showActives && (
                                <li
                                  className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                                  onClick={() => this.delete(contact)}
                                >
                                  Delete
                                </li>
                              )}
                            </ul>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
            {this.state.selectedContact && (
              <UserUpdateModal
                userInfo={this.state.selectedContact}
                title="Update User"
                closePopup={this.closePopup}
                reFetch={this.fetchData}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default UserManagement;
