import React from "react";
import TicketTable from "./TicketTable";

export const SubmittedTicketsPage = () => {
  return (
    <div className="pl-0">
      <br />
      <TicketTable />
    </div>
  );
};
