import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { isEmail } from "validator";
import Input from "react-validation/build/input";
import loginbg from "../images/loginbg.png";
import loginPageBg from "../images/loginPageBg.png";
import loginlogo from "../images/loginlogo.png";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import "./login.css";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import qs from "qs";
import icon1 from "../images/loginIcon1.png";
import icon2 from "../images/loginIcon2.png";

import {
  Button1,
  Container,
  FormContent,
  FormH1,
  FormWrap,
} from "./login.elements";
import axios from "axios";

const imgUrl = require("../images/loginbg.png").default;

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
}; 
class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: false,
    };
  }

  onChangeUsername(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const data = { username: this.state.email, password: this.state.password };
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url,
    };
    axios(options)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));

        window.location.href = "/";
      })
      .catch((err) => {
        this.setState({
          message: true,
        });

        console.log(err);
      });

    // const { dispatch, history } = this.props;

    // if (this.checkBtn.context._errors.length === 0) {
    //   dispatch(login(this.state.email, this.state.password))
    //     .then(() => {
    //       history.push("/");
    //       console.log(this.state)
    //       window.location.reload();
    //     })
    //     .catch(() => {
    //       this.setState({
    //         loading: false,
    //       });
    //     });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  render() {
    const { isLoggedIn, message } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <Container>
        <img src={loginPageBg} id="bg" alt="login bg" />
        <img src={loginlogo} id="loginlogo" alt="iointel logo" />
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}

        <FormWrap>
          <FormContent>
            <Form
              onSubmit={this.handleLogin}
              ref={(c) => {
                this.form = c;
              }}
            >
              <FormH1 className="h">Login</FormH1>
              <div className="row">
                <div className="iconLoginComponent">
                  <img
                    className="iconLogin"
                    // className="close-icon"
                    src={icon1}
                    alt="emailIcon"
                  />
                </div>
                <Input
                  type="text"
                  id="loginform"
                  className="mb-2"
                  placeholder="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeUsername}
                  validations={[required, email]}
                />
              </div>
              <div className="row">
                <div className="passwordIconComponent">
                  <img
                    className="passwordIcon"
                    // className="close-icon"
                    src={icon2}
                    alt="passwordIcon"
                  />
                </div>
                <Input
                  type="password"
                  className="mb-2"
                  id="loginform"
                  placeholder="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
              </div>
              {this.state.message && (
                <p style={{ marginTop: "10px", color: "#fefefe" }}>
                  Please check your email or password{" "}
                </p>
              )}
              <div className="row justify-content-center">
                <br />
                <Button1
                  className="btn btn-block btnlogin"
                  // id="btnlogin"
                  // disabled={this.state.loading}
                >
                  {/* {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )} */}
                  <span>Login</span>
                </Button1>
              </div>
              <div className="row justify-content-center">
                <a className="forget" href="/ForgetMyPassword">
                  Forget My Password
                </a>
              </div>
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Login);
