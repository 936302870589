import React, { Component, useState } from "react";
import jdLogo from "../../images/jdLogo.png";
import "./bulkUploadStyle.css";
import upload from "../../images/upload.png";
import download from "../../images/download.JPG";
import okey from "../../images/okey.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { padding } from "@mui/system";
import axios from "axios";
import Pagination from "../Pagination";
import Lottie from "react-lottie";
import Posts from "../Posts";
import SideMenu from "../../SideMenu";
import * as location from "../../1055-world-locations.json";
import * as success from "../../1127-success.json";
import GetProfileImage from "../getProfileImage/GetProfileImage";
var userInformation = JSON.parse(localStorage.getItem("user"));

class BulkUpload extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: "",
      iccid: "",
      dealerCode: "",
      statusOfSim: "",
      dateOfAssigned: "",
      activationEnd: "",
      currentPage: 1,
      postsPerPage: 12,
      iccids: [],
      loading: null,
      isVisible: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  submit() {
    this.setState({
      isVisible: true,
    });

    const data = new FormData();
    data.append("file", this.state.selectedFile);
    console.warn(this.state.selectedFile);
    let url = `${process.env.REACT_APP_API_URL}/api/iccidCatalog/upload/${this.state.dealerCode}`;
    setTimeout(() => {
      axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        })
        .then((res) => {
          this.setState({
            iccids: res.data.catalog,
            loading: true,
          });
          setTimeout(() => {
            this.setState({
              complated: true,
            });
            window.location.href = "/"
          }, 4000);
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            isVisible: false,
          });
        });
    }, 2000);
  }
  handleUpdateState = (field = "", value = "") => {
    this.setState({ [field]: value });
  };
  render() {
    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: location.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: success.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let { postsPerPage, currentPage, iccids } = this.state;

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = iccids.slice(indexOfFirstPost, indexOfLastPost);


    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="content" style={{ width: "95%" }}>
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>BULK ICCID UPLOAD</label>
              </div>

              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <div className="row">
              <label
                for="file-upload"
                className="custom-file-upload btn ml-3 pr-4 uploadTemlateBtn"
              >
                UPLOAD TEMPLATE <img id="imgb" src={upload} alt="upload Logo" />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={this.handleInputChange}
              />
              <label style={{ color: "#fefefe", marginLeft: "5%" }}>
                Dealer Code *
              </label>
              <input
                style={{ marginLeft: "2%", marginTop: "1%" }}
                id="inputactivaton"
                type="text"
                name="dealerCode"
                className="form-control"
                placeholder="Dealer Code"
                value={this.state.dealerCode}
                required
                onChange={(event) =>
                  this.handleUpdateState("dealerCode", event.target.value)
                }
              />
            </div>

            {this.state.selectedFile.name && (
              <p style={{ color: "#fefefe" }}>
                {" "}
                Your selected file: {this.state.selectedFile.name}
              </p>
            )}
            <div className="w-100"></div>
            <br />
            <table className="a">
              <thead className="thead ">
                <tr>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("username")}
                  >
                    ICCID Number
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("username")}
                  >
                    Dealer Code
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th scope="col">Status of SIM</th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("startDate")}
                  >
                    Date Assigned
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("endDate")}
                  >
                    Activation End
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody id="tablebody">
                {this.state.iccids.length > 0
                  ? currentPosts.map((contact) => (
                    <tr
                      // key={}
                      className="border_bottom"
                    >
                      <td>{contact.iccid === null ? "-" : contact.iccid}</td>
                      <td>
                        {contact.dealerCode === null ? "-" : contact.dealerCode}
                      </td>
                      <td>{contact.simType === null ? "-" : contact.simType}</td>
                      <td>
                        {contact.startDate === null ? "-" : contact.startDate}
                      </td>
                      <td>{contact.endDate === null ? "-" : contact.endDate}</td>
                      <td></td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
            <br />
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={iccids.length}
              paginate={(number) => this.setState({ currentPage: number })}
              next={() => this.setState({ currentPage: currentPage + 1 })}
              previous={() => this.setState({ currentPage: currentPage - 1 })}
            />
            <div className="w-100"></div>
            <br />
            <br />
            <br />

            <button onClick={() => this.submit()} className="btn submitIccıd">
              SUBMIT NEW ICCIDs <img className="ml-1" src={okey} alt="okey Logo" />
            </button>
            {!this.state.isVisible ? (
              <></>
            ) : (
              <>
                {true ? (
                  <>
                    {!this.state.loading ? (
                      <Lottie
                        options={defaultOptions1}
                        height={200}
                        width={200}
                      />
                    ) : (
                      <Lottie
                        options={defaultOptions2}
                        height={100}
                        width={100}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default BulkUpload;
