import axios from "axios";
import React, { useState } from "react";
import Group326 from "../../images/Group326.png";
import Group309 from "../../images/Group309.png";
import Group312 from "../../images/Group312.png";
import Line from "../../images/Line.png";
import "./ChangePasswordStyle.css";
var userInformation = JSON.parse(localStorage.getItem("user")); 

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [rePassord, setRePassword] = useState("");

  const submitPassword = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/updatePassword/username/${userInformation.username}/password/${password}`, {},
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((res) => { 
        window.location.href = "/ChangePasswordSuccess";
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      <div className="containerPassword">
        <div className="row rowPassword1 justify-content-center">
          <div>
            <img src={Group326} alt="Logo Image" />
          </div>
        </div>
        <div className="row rowPassword2 justify-content-center">
          <span className="textP1">Change Your Password</span>
          <div className="w-100"></div>
          <span className="textP2">
            Use this form to change your password. Once changed, your new
            password will be in effect next time you login.
          </span>
        </div>
        <div className="row rowPassword3 justify-content-center">
          <div className="row">
            <img src={Group312} alt="Key Image" />
          </div>
          <br />
          <div className="w-100"></div>
          <br />
          <div className="row">
            <input
              className="passwordInput"
              type="password"
              value={password}
              placeholder="new password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <br />
          <div className="w-100"></div>
          <br />

          <div className="row">
            <input
              className="passwordInput"
              type="password"
              value={rePassord}
              placeholder="re-enter password"
              onChange={(e) => setRePassword(e.target.value)}
            />
          </div>
          <br />
          <div className="w-100"></div>
          <br />
          <div className="row">
            {password === rePassord && password !== "" ? (
              <button onClick={submitPassword} className="btn resetPassword">
                Continue
              </button>
            ) : (
              <button
                disabled
                // onClick={submitPassword}
                className="btn resetPassword"
              >
                Continue
              </button>
            )}
          </div>
          <br />
          <div className="w-100"></div>
          <br />
        </div>
        <div className="row rowPassword4 justify-content-center">
          <div className="row" style={{ paddingBottom: "10px" }}>
            <a>
              <span className="a1">Contact </span>
            </a>
            <img src={Line} alt="Logo Image" />
            <a>
              <span className="a1"> Privacy Policy </span>
            </a>
            <img src={Line} alt="Logo Image" />
            <a>
              <span className="a1"> FQA</span>
            </a>
          </div>
          <div className="w-100"></div>
          <div className="row">
            <span className="footerPassword">
              You landed this page because we received a request for reset your
              password. If you didn't request or you think landed this page by
              mistake, you can safely leave.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
