import React, { useState } from "react";
import "./KnowledgeBaseStyle.css";
import downArrow from "../../../images/Vector.svg";
import upArrow from "../../../images/upArrow.png";
var userInformation = JSON.parse(localStorage.getItem("user"));

export default function KnowledgeBase() {
  const [boolean, setBoolean] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);

  const questions = [
    {
      index: 1,
      title: "I need to reset my password",
      description: "1. Navigate to:  ",
      link: "https://mvnoportal.iointel.com/",
      description1: "2. Click",
      link1: "https://mvnoportal.iointel.com/ForgetMyPassword",
      description2: "Forget My Password",
      description3: "3. Enter your email and Click Reset Password",
      description4:
        "4. Check the email address connected to your account for a password reset email",
      description5:
        "5. From the email, click Reset password and enter your new password",
      description6: "6. Click",
      link2: "https://mvnoportal.iointel.com/ChangePassword",
      description7: "Change password",
    },
    {
      index: 2,
      title: "Dashboard Overview",
      description: "1. Navigate to:  ",
      link: "https://mvnoportal.iointel.com/",    
 
      description1: "2. Enter your email and password and click Login",
      description3: "3. Go to Dashboard Overview Page",
      description5:
        "The Dashboard page appears to display information about Sim Activation, Total Customers, Daily Sim Activation, Activation State Map, and Product Package Information ",
    },
    {
      index: 3,
      title: "My dashboard data are not synced",
      description:
        "In this case, we recommend that you to refresh it may take some time to reflect on the graphs.",
    },
    {
      index: 4,
      title: "SIM Management",
      description:
        "You can see the status of sim cards defined for your account. Also, you can Perform actions such as activation, deactivation, and suspension.",
    },
    {
      index: 5,
      title: "How to activate a sim card",
      description: "1. Go to  ",
      link: "https://mvnoportal.iointel.com/simManagement",
      description1: "2. Click Action (...) icon and click Activate",
      description3: "3. Go to",
      link2: "https://mvnoportal.iointel.com/simActivation",
      description9: "https://mvnoportal.iointel.com/simActivation",
      description4: "4. Click Next button",
      description5: "5. Enter your customer details and Click Next Button",
      description6: "6. Make your choice of plan and Click Next Button",
      description8: "7. Check the information you entered on the summary page and click the next button to proceed to the confirmation step.",
    },
    {
      index: 6,
      title: "SIM activation is not working",
      description:
        "In this case, please open a ticket by adding the error message you received.",
    },
    {
      index: 7,
      title: "How to change client's contract details",
      description: "1. Go to  ",
      link: "https://mvnoportal.iointel.com/simManagement",    
      description1: "2. Click Action (...) and select the status you want to change",
    },
    {
      index: 8,
      title: "User Management",
      description: "1. Go to  ",
      link: "https://mvnoportal.iointel.com/userManagement",
      description1: "If you want an additional user, click Add User button and fill the mandatory field and click confirm button.",
      description3: "If you want to change user role change, Click Action (...) and select the status you want to change",
    },
    {
      index: 9,
      title: "How can see and pay my invoice",
      description: "Only users with the admin roles can see the invoice.",
      description1: "1. Go to ",
      link1: "https://mvnoportal.iointel.com/billing ",
      description2: "https://mvnoportal.iointel.com/billing",
      description3: "2. You can download your invoice by clicking the action.",
      description4:
        "Also, you can see paid/unpaid invoices in the select status field ",
    },
  ];

  // const show = (i, y) => {
  //   setActiveIndex(i);
  //   setIsVisible(!isVisible);
  //   console.log(y);
  // };

  return (
    <div>
      <div>
        {questions.map((contents, i) => (
          <>
            <div className="rowTitle" style={{ color: "#fefefe" }}>
              <div onClick={() => {}}>
                <div
                  className="row contentTitle"
                  style={activeIndex !== i ? { cursor: "pointer" } : null}
                >
                  <div className="col" onClick={() => setActiveIndex(i)}>
                    <label
                      style={activeIndex !== i ? { cursor: "pointer" } : null}
                    >
                      {contents.title}
                    </label>
                  </div>
                  <div className="col-end">
                    {i === activeIndex ? (
                      <>
                        <img
                          src={upArrow}
                          alt="upArrow"
                          onClick={() => setActiveIndex()}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    ) : (
                      <img
                        src={downArrow}
                        alt="downArrow"
                        onClick={() => setActiveIndex(i)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {i === activeIndex && i <= 6 &&(
                <div>
                  <div className={userInformation.dealerType == "CleanIot" ?  "row-auto contentDescriptionClean": "row-auto contentDescription"}>
                    <label key={contents.index}>
                      {contents.description}
                      <a href={contents.link} style={{textDecoration: "underline"}} target="_blank" rel="noreferrer noopener"> {contents.link}</a>
                      <br />
                      {contents.description1}
                      <a href={contents.link1} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description2}</a>
                      <br />
                      {contents.description3}
                      <a href={contents.link2} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description9}</a>
                      <br />
                      {contents.description4}
                      <br />
                      {contents.description5}
                      <br />
                      {contents.description6}
                      <a href={contents.link2} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description7}</a>
                      <br />
                      {contents.description8}
                    </label>
                  </div>
                </div>
              )}
              {i === activeIndex && i === 7 && (
                <div>
                  <div className={userInformation.dealerType == "CleanIot" ?  "row-auto contentDescriptionClean": "row-auto contentDescription"}>
                    <label key={contents.index}>
                      {contents.description}
                      <a href={contents.link} style={{textDecoration: "underline"}} target="_blank" rel="noreferrer noopener"> {contents.link}</a>
                      <br /><br />
                      {contents.description1}
                      <a href={contents.link1} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description2}</a>
                      <br />
                      {contents.description3}
                      <a href={contents.link2} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description9}</a>
                      <br />
                      {contents.description4}
                      <br />
                      {contents.description5}
                      <br />
                      {contents.description6}
                      <a href={contents.link2} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description7}</a>
                      <br />
                      {contents.description8}
                    </label>
                  </div>
                </div>
              )}
              {i === activeIndex && i === 8 && (
                <div>
                  <div className={userInformation.dealerType == "CleanIot" ?  "row-auto contentDescriptionClean": "row-auto contentDescription"}>
                    <label key={contents.index}>
                      {contents.description}
                      <a href={contents.link} style={{textDecoration: "underline"}} target="_blank" rel="noreferrer noopener"> {contents.link}</a>
                      <br /><br />
                      {contents.description1}
                      <a href={contents.link1} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description2}</a>
                      <br />
                      {contents.description3}
                      <a href={contents.link2} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description9}</a>
                      <br /><br />
                      {contents.description4}
                      <br />
                      {contents.description5}
                      <br />
                      {contents.description6}
                      <a href={contents.link2} style={{textDecoration: "underline"}}  target="_blank" rel="noreferrer noopener"> {contents.description7}</a>
                      <br />
                      {contents.description8}
                    </label>
                  </div>
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
