import { USER_LIST } from "../actions/types";
const initialState = JSON.parse(localStorage.getItem("userList"));
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LIST:
      return {
        ...state,
        userList: payload,
      };
    default:
      return state;
  }
}
