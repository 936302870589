import React, { Component } from "react";
import Stepper from "bs-stepper";
import "./supportSideMenuStyle.css";
import line2 from "../../../images/line2.png";
import { OpenTicketPage } from "../openTicket/openTicketPage";
import { SubmittedTicketsPage } from "../submittedTickets/submittedTicketsPage";
import KnowledgeBase from "../knowledgeBase/KnowledgeBase";
import SubmittedTicketsDetail from "../submittedTickets/SubmittedTicketsDetail";

class SupportSideMenu extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
    };
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
  }

  onSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <div id="stepper1" className="bs-stepper" style={{ width: "100%" }}>
          <div className="row">
            <div className="col pt-5" style={{ maxWidth: "210px" }}>
              <div className="row step" data-target="#test-l-1">
                <button className="step-trigger">
                  <span className="bs-stepper-circle">Knowledge Base</span>
                </button>
              </div>
              <div className="row step" data-target="#test-l-2">
                <button className="step-trigger">
                  <span className="bs-stepper-circle">Open a Ticket</span>
                </button>
              </div>
              <div className="row step" data-target="#test-l-3">
                <button className="step-trigger">
                  <span className="bs-stepper-circle">Submitted Tickets</span>
                </button>
              </div>
              <div hidden className="row step" data-target="#test-l-4">
                <button className="step-trigger">
                  <span className="bs-stepper-circle">O</span>
                </button>
              </div>
            </div>
            <div className="col" style={{ maxWidth: "10px" }}>
              <img src={line2} alt="line" />
            </div>
            {/* <span className="verticalBorder"/> */}
            <div className="col pl-0">
              <div className="bs-stepper-content">
                <form onSubmit={this.onSubmit}>
                  <div id="test-l-1" className="content">
                    <div className="form-group">
                      <KnowledgeBase />
                    </div>
                    {/* <button className="btn btn-primary" onClick={() => this.stepper.next()}>Next</button> */}
                  </div>
                  <div id="test-l-2" className="content">
                    <OpenTicketPage />
                    {/* <button className="btn btn-primary" onClick={() => this.stepper.to(1)}>Next</button> */}
                  </div>
                  <div id="test-l-3" className="content text-center">
                    <SubmittedTicketsPage />
                    {/* <button className="btn btn-primary" onClick={() => this.stepper.next()}>Next</button> */}
                    {/* <button type="submit" className="btn btn-primary mt-5">Submit</button> */}
                  </div>
                  <div id="test-l-4" className="content">
                    <SubmittedTicketsDetail />
                    {/* <button className="btn btn-primary" onClick={() => this.stepper.next()}>Next</button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SupportSideMenu;
