import axios from "axios";
import React, { useState } from "react";
import AddImage from "./addImage";
import Lottie from "react-lottie";
import * as location from "../../../1055-world-locations.json";
import * as success from "../../../1127-success.json";

var userInformation = JSON.parse(localStorage.getItem("user"));

export const OpenTicketPage = () => {
  const [category, setCategory] = useState("");
  const [pageCategory, setPageCategory] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isVisible, setIsvisible] = useState(false);
  const [loading, setLoading] = useState();
  const [changePage, setChangePage] = useState(false);

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const changeCategory = (event) => {
    setCategory(event.target.value);
  };
  const changePageCategory = (event) => {
    setPageCategory(event.target.value);
  };

  const changeTitle = (event) => {
    setTitle(event.target.value);
  };
  const changeMessage = (event) => {
    setMessage(event.target.value);
  };

  const sendButton = () => {
    const data = {
      category,
      title,
      page: pageCategory,
      dealerCode: userInformation.dealerCode + "-" + userInformation.username,
    };
    const userMessage = {
      userMessage: message,
    };
    console.log(category, "category");
    if (
      category !== "" &&
      category !== "please select category of your problem" &&
      title !== ""
    ) {
      setIsvisible(true);
      setTimeout(() => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/api/ticket/addTicket`, data, {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          })
          .then((response) => {
            setLoading(true);
            axios.post(
              `${process.env.REACT_APP_API_URL}/api/ticketMessage/saveUserMessage/${response.data.id}`,
              userMessage,
              {
                headers: {
                  Authorization: `Bearer ${userInformation.access_token}`,
                  User: `${userInformation.username}`,
                },
              }
            );
            setTimeout(() => {
              setChangePage(true);
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
            setIsvisible(false);
          });
      }, 2000);
    }

    console.log(category, pageCategory, title, message);
  };
  return (
    <div className="container pl-0">
      {changePage ? (window.location.href = "/Support") : null}
      <div className="row">
        <div className="col-5">
          <span id="lblactivation">Category</span>
          <span className="textnumber2">*</span>
          <select
            className="mt-2"
            id="customeria"
            name="category"
            placeholder="please select category of your problem "
            value={category}
            onChange={changeCategory}
            required
          >
            <option selected>please select category of your problem</option>
            <option value="Report a Problem">Report a Problem</option>
            <option value="Feedback">Feedback</option>
          </select>
        </div>
        <div className="col-5">
          <span id="lblactivation">Page</span>
          <select
            id="customeria"
            name="pageCategory"
            className="mt-2"
            placeholder="please select category of your problem "
            value={pageCategory}
            onChange={changePageCategory}
          >
            <option selected>please select category of your problem </option>
            <option value="Dashboard">Dashboard</option>
            <option value="SIMs Management">SIMs Management</option>
            <option value="SIM Details">SIM Details</option>
            <option value="Customer Details">Customer Details</option>
            <option value="Plan Selection">Plan Selection</option>
            <option value="Summary">Summary</option>
            <option value="Confirmation">Confirmation</option>
            <option value="Contract Details">Contract Details</option>
            <option value="Live Usage">Live Usage</option>
            <option value="Add More Data">Add More Data</option>
            <option value="SIM-CARD Order">SIM-CARD Order</option>
            <option value="User Management">User Management</option>
            <option value="Billing">Billing</option>
            <option value="Pricing">Pricing</option>
            <option value="Settings">Settings</option>
            <option value="Support">Support</option>
          </select>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <span className="ticketTitle">Title</span><span className="textnumber2">*</span>
        </div>
        <div className="row">
          <input
            id="titleInput"
            autoComplete="off"
            className=" form-control"
            type="text"
            placeholder="Type your title"
            style={{ color: "#E3E2DD" }}
            value={title}
            onChange={changeTitle}
          />
        </div>
        <div className="row">
          <label className="ticketDetails">
            Please supply details about your request/problem
          </label>
        </div>
        <div className="row">
          <textarea
            id="detailsInput"
            className=" form-control"
            type="text"
            placeholder=""
            style={{ color: "#E3E2DD" }}
            aria-label="With textarea"
            value={message}
            onChange={changeMessage}
            onKeyDown = {(e)=> e.keyCode === 13 && sendButton(e)}
          />
        </div>
      </div>
      <div className="row pl-3 pt-2">
        <label className="screenshotText">
          Browse for a file to attach or drag and drop screenshot here
        </label>
      </div>
      <AddImage />
      <button className="sendBtn" onClick={sendButton}>
        Send
      </button>
      {!isVisible ? (
        <></>
      ) : (
        <>
          {true ? (
            <>
              {!loading ? (
                <Lottie options={defaultOptions1} height={200} width={200} />
              ) : (
                <Lottie options={defaultOptions2} height={100} width={100} />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
