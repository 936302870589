import axios from "axios";
import React, { useEffect, useState } from "react";
import ProductsBigCard from "./productsBigCard";
import "./simActivationStyle.css";
var userInformation = JSON.parse(localStorage.getItem("user"));

const RepeatingProducts = ({
  repeatingProductId,
  repeatingProductDescription,
  setRepeatingProductDescription,
  setRepeatingProductId,
  setTariffId,
  simType,
}) => {
  useEffect(() => {
    getProducts();
  }, []);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  // const [state, setState] = useState(false);

  const getProducts = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/tariff/tariffList/simType/${simType}/repeatingProductId/0`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      );
      setProducts(res.data);

      setLoading(true);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div>
      <div>
        {loading && (
          <div>
            <ProductsBigCard
              item={products}
              repeatingProductDescription={repeatingProductDescription}
              setRepeatingProductDescription={setRepeatingProductDescription}
              repeatingProductId={repeatingProductId}
              setRepeatingProductId={setRepeatingProductId}
              setTariffId={setTariffId}
              simType={simType}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default RepeatingProducts;
