import {
    SIM_ACTIVATION
   } from "../actions/types";
   const initialState = JSON.parse(localStorage.getItem("simActivation"));
   export default function (state = initialState, action) {
     const { type, payload } = action;
     
   switch (type) {
     case SIM_ACTIVATION:
       return {
         ...state,
         simActivation: payload};
         default:
         return state;
     }
 }