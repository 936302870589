import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import styled, { css } from "styled-components";

const PreviewImageSize = "200px";

const ConfirmationMessage = styled.div`
  color: green;
  font-style: bold;
  ${({ isVisible }) =>
    isVisible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const StyledDropzone = styled.div`
  .dzu-dropzone {
    /* convert to horizontal previews */
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 40px; /* space for buttons */
    max-height: none !important;
    overflow: hidden;
    border: 2px solid grey;
    border-radius: 10px;
  }
  .dzu-previewContainer {
    /* convert preview to square tile */
    padding: 0;
    justify-content: center;
    align-items: start;
    overflow: hidden;
    flex-shrink: 0;

    margin: 4px;
    height: calc(${PreviewImageSize} + 28px);
    width: calc(${PreviewImageSize} + 2px);
    border-bottom: none;

    img {
      /* create square bordered image */
      border: 1px solid grey;
      max-height: none;
      max-width: none;
      height: ${PreviewImageSize};
      width: ${PreviewImageSize};
      pointer-events: none;
      user-select: none;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .dzu-submitButtonContainer {
    /* align submit button to bottom */
    position: absolute;
    margin: 0;
    top: 0;
    z-index: 1;
    left: 100px;
    margin: 10px;
  }
  .dzu-inputLabelWithFiles {
    /* align add files button to bottom */
    position: absolute;
    margin: 0;
    top: 0;
    z-index: 1;
    margin: 10px;
  }
  .dzu-inputLabel {
    font-family: 'Nunito';
    font-size: 50px;
    font-weight: 300;
    color: #9D2A00;
}
  .dzu-previewStatusContainer {
    /* place loading bar at bottom */
    position: absolute;
    bottom: 0;
  }

  // doesn't work, svgs being upadated dyanmically
  /* .dzu-previewButton {
    background-image: url("anchor.svg") !important;
  } */
`;

export const ImgUpload = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const getUploadParams = () => {
    return { url: "https://test-mvnoportal.iointel.com:8996/api/ticketImages/addTicketImages/4" };
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
    setUploadSuccess(false);
  };

  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    setUploadSuccess(true);
  };

  return (
    <>
      <ConfirmationMessage isVisible={uploadSuccess}>
        Successfully uploaded!
      </ConfirmationMessage>
      <StyledDropzone>
        <Dropzone
          maxFiles={6}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          inputContent="Drag Files or Click to Browse"
          styles={{ dropzone: { minHeight: 200, maxHeight: 250,  } }}
        />
      </StyledDropzone>
    </>
  );
};
