import axios from "axios";
import React, { useEffect, useState } from "react";
import "./planPackageStyle.css";
var userInformation = JSON.parse(localStorage.getItem("user"));

const PlanPackage = () => {
  const [boolean, setBoolean] = useState(false);
  const [description, setDescription] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    getPlans();
  }, []);
  const getPlans = () => {
    const payerId = {
      payerid: userInformation.dealerCode,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/dashboard/productStats`, payerId, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((res) => { 
        setDescription(res.data.description);
        setCount(res.data.count);
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const TEAM_COLORS = ["#E77D2A", '#DA772A', '#CD712B', '#C06A2B', '#B3642B', '#A65E2C', '#99582C', '#8C512C', '#8C512C','#7F4B2D', '#72452D', '#653F2E',
   '#58382E','#4B322E','#3E2C2F','#31262F','#241F2F','#171930','#0A1330'];
  const playerColor = data.map((contact, i) => (
    TEAM_COLORS[i] 
  ))

  return (
    <div> 
      <div id="grad">
        <div className={userInformation.dealerType == "CleanIot" ? "containerCardClean" : "containerCard"}>
          {data.map((contact, i) => (
            <>
              <div
                className={userInformation.dealerType == "CleanIot" ? (i === activeIndex ? "cardOpenClean" : "cardOneClean") : (i === activeIndex ? "cardOpen" : "cardOne")}
                // className={i === activeIndex ? "cardOpen" : "cardOne"}
                style={{background:`${playerColor[i]}`}}
              >
                <div className="row" onClick={() => setActiveIndex(i)}>
                  <span className="col-8 justify-content-start">
                    {contact.description === "nb-iot plan @ 64 kbps"
                      ? "NB-IoT"
                      : contact.description.split(" ", 2)}
                  </span>
                  <span className="col-4 justify-content-and rightT">
                    {contact.count}
                  </span>
                </div>
                {i === activeIndex && (
                  <div style={{ marginTop: "10px"}}>
                    <label className={userInformation.dealerType == "CleanIot" ? "txtAClean" : "txtA"} style={{marginTop:"-10.5px"}}> {Math.round(contact.percent)}% </label>
                    <label className={userInformation.dealerType == "CleanIot" ? "txtBClean" : "txtB"} style={{marginTop:"-8px"}}>from last 30 days</label>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
export default PlanPackage;
// import React, { Component } from 'react'
// import axios from "axios";
// import "./planPackageStyle.css";
// var userInformation = JSON.parse(localStorage.getItem("user"));

// export default class planPackage extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       description: "",
//       count: "",
//       data: [],
//       activeIndex: false,
//     };
//   }

//   componentDidMount(){
//     setTimeout(() => {
//       this.getPlans()
//     }, 2000);
//   }

//   getPlans() {
//     const payerId = {
//       payerid: userInformation.dealerCode,
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_URL}/api/dashboard/productStats`, payerId, {
//         headers: {
//           Authorization: `Bearer ${userInformation.access_token}`,
//           User: `${userInformation.username}`,
//         },
//       })
//       .then((res) => {
//         console.log(res.data);
//         this.setState({
//           description: res.data.description,
//           count:res.data.count,
//           data: res.data,
//         })
//         // setDescription(res.data.description);
//         // setCount(res.data.count);
//         // setData(res.data);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   render() {
//     const { activeIndex, data } = this.state;
//     return (
//       <div>
//       <div id="grad">
//         <div className="containerCard">
//             <>
//               <div
//                 className={0 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#E77D2A", borderTopLeftRadius:"5px", borderTopRightRadius:"5px"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 0})}>
//                   <span className="col-8 justify-content-start">
//                     {data[0].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[0].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[0].count}
//                   </span>
//                 </div>
//                 {0 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[0].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={1 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#DA772A"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 1})}>
//                   <span className="col-8 justify-content-start">
//                     {data[1].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[1].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[1].count}
//                   </span>
//                 </div>
//                 {1 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[1].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={2 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#CD712B"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 2})}>
//                   <span className="col-8 justify-content-start">
//                     {data[2].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[2].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[2].count}
//                   </span>
//                 </div>
//                 {2 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[2].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={3 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#C06A2B"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 3})}>
//                   <span className="col-8 justify-content-start">
//                     {data[3].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[3].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[3].count}
//                   </span>
//                 </div>
//                 {3 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[3].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={4 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#B3642B"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 4})}>
//                   <span className="col-8 justify-content-start">
//                     {data[4].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[4].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[4].count}
//                   </span>
//                 </div>
//                 {4 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[4].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={5 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#A65E2C"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 5})}>
//                   <span className="col-8 justify-content-start">
//                     {data[5].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[5].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[5].count}
//                   </span>
//                 </div>
//                 {5 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[5].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={6 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#99582C"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 6})}>
//                   <span className="col-8 justify-content-start">
//                     {data[6].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[6].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[6].count}
//                   </span>
//                 </div>
//                 {6 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[6].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={7 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#8C512C"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 7})}>
//                   <span className="col-8 justify-content-start">
//                     {data[7].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[7].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[7].count}
//                   </span>
//                 </div>
//                 {7 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[7].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={8 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#7F4B2D"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 8})}>
//                   <span className="col-8 justify-content-start">
//                     {data[8].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[8].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[8].count}
//                   </span>
//                 </div>
//                 {8 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[8].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={9 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#72452D"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 9})}>
//                   <span className="col-8 justify-content-start">
//                     {data[9].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[9].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[9].count}
//                   </span>
//                 </div>
//                 {9 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[9].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={10 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#653F2E"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 10})}>
//                   <span className="col-8 justify-content-start">
//                     {data[10].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[10].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[10].count}
//                   </span>
//                 </div>
//                 {10 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[10].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={10 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#58382E"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 11})}>
//                   <span className="col-8 justify-content-start">
//                     {data[11].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[11].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[11].count}
//                   </span>
//                 </div>
//                 {11 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[11].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={12 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#4B322E"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 12})}>
//                   <span className="col-8 justify-content-start">
//                     {data[12].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[12].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[12].count}
//                   </span>
//                 </div>
//                 {12 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[12].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={13 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#3E2C2F"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 13})}>
//                   <span className="col-8 justify-content-start">
//                     {data[13].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[13].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[13].count}
//                   </span>
//                 </div>
//                 {13 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[13].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={14 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#31262F"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 14})}>
//                   <span className="col-8 justify-content-start">
//                     {data[14].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[14].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[14].count}
//                   </span>
//                 </div>
//                 {14 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[14].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={15 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#241F2F"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 15})}>
//                   <span className="col-8 justify-content-start">
//                     {data[15].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[15].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[15].count}
//                   </span>
//                 </div>
//                 {15 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[15].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={16 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#171930"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 16})}>
//                   <span className="col-8 justify-content-start">
//                     {data[16].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[16].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[16].count}
//                   </span>
//                 </div>
//                 {16 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[16].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={17 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#0A1330", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: 17})}>
//                   <span className="col-8 justify-content-start">
//                     {data[17].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[17].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data.count}
//                   </span>
//                 </div>
//                 {17 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[16].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//             </>
//         </div>
//       </div>
//     </div>
//     )
//   }
// }


// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import "./planPackageStyle.css";
// var userInformation = JSON.parse(localStorage.getItem("user"));

// const PlanPackage = () => {
//   const [description, setDescription] = useState();
//   const [count, setCount] = useState();
//   const [data, setData] = useState([]);
//   const [activeIndex, this.setState({ activeIndex: })] = useState([]);

//   useEffect(() => {
//     getPlans();
//   });
//   function getPlans() {
//     const payerId = {
//       payerid: userInformation.dealerCode,
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_URL}/api/dashboard/productStats`, payerId, {
//         headers: {
//           Authorization: `Bearer ${userInformation.access_token}`,
//           User: `${userInformation.username}`,
//         },
//       })
//       .then((res) => {
//         console.log(res.data);
//         setDescription(res.data.description);
//         setCount(res.data.count);
//         setData(res.data);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   return (
//     <div>
//       <div id="grad">
//         <div className="containerCard">
//             <>
//               <div
//                 className={0 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#E77D2A", borderTopLeftRadius:"5px", borderTopRightRadius:"5px"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(0)}>
//                   <span className="col-8 justify-content-start">
//                     {data[0].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[0].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[0].count}
//                   </span>
//                 </div>
//                 {0 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[0].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={1 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#DA772A"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(1)}>
//                   <span className="col-8 justify-content-start">
//                     {data[1].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[1].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[1].count}
//                   </span>
//                 </div>
//                 {1 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[1].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={2 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#CD712B"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(2)}>
//                   <span className="col-8 justify-content-start">
//                     {data[2].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[2].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[2].count}
//                   </span>
//                 </div>
//                 {2 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[2].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={3 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#C06A2B"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(3)}>
//                   <span className="col-8 justify-content-start">
//                     {data[3].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[3].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[3].count}
//                   </span>
//                 </div>
//                 {3 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[3].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={4 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#B3642B"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(4)}>
//                   <span className="col-8 justify-content-start">
//                     {data[4].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[4].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[4].count}
//                   </span>
//                 </div>
//                 {4 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[4].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={5 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#A65E2C"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(5)}>
//                   <span className="col-8 justify-content-start">
//                     {data[5].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[5].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[5].count}
//                   </span>
//                 </div>
//                 {5 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[5].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={6 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#99582C"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(6)}>
//                   <span className="col-8 justify-content-start">
//                     {data[6].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[6].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[6].count}
//                   </span>
//                 </div>
//                 {6 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[6].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={7 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#8C512C"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(7)}>
//                   <span className="col-8 justify-content-start">
//                     {data[7].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[7].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[7].count}
//                   </span>
//                 </div>
//                 {7 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[7].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={8 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#7F4B2D"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(8)}>
//                   <span className="col-8 justify-content-start">
//                     {data[8].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[8].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[8].count}
//                   </span>
//                 </div>
//                 {8 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[8].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={9 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#72452D"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(9)}>
//                   <span className="col-8 justify-content-start">
//                     {data[9].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[9].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[9].count}
//                   </span>
//                 </div>
//                 {9 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[9].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={10 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#653F2E"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(10)}>
//                   <span className="col-8 justify-content-start">
//                     {data[10].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[10].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[10].count}
//                   </span>
//                 </div>
//                 {10 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[10].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={10 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#58382E"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(11)}>
//                   <span className="col-8 justify-content-start">
//                     {data[11].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[11].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[11].count}
//                   </span>
//                 </div>
//                 {11 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[11].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={12 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#4B322E"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(12)}>
//                   <span className="col-8 justify-content-start">
//                     {data[12].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[12].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[12].count}
//                   </span>
//                 </div>
//                 {12 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[12].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={13 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#3E2C2F"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(13)}>
//                   <span className="col-8 justify-content-start">
//                     {data[13].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[13].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[13].count}
//                   </span>
//                 </div>
//                 {13 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[13].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={14 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#31262F"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(14)}>
//                   <span className="col-8 justify-content-start">
//                     {data[14].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[14].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[14].count}
//                   </span>
//                 </div>
//                 {14 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[14].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={15 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#241F2F"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(15)}>
//                   <span className="col-8 justify-content-start">
//                     {data[15].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[15].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[15].count}
//                   </span>
//                 </div>
//                 {15 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[15].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={16 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#171930"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(16)}>
//                   <span className="col-8 justify-content-start">
//                     {data[16].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[16].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data[16].count}
//                   </span>
//                 </div>
//                 {16 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[16].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className={17 === activeIndex ? "card cardOpen" : "card cardOne"}
//                 style={{background:"#0A1330", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}
//               >
//                 <div className="row" onClick={() => this.setState({ activeIndex: })(17)}>
//                   <span className="col-8 justify-content-start">
//                     {data[17].description === "nb-iot plan @ 64 kbps"
//                       ? "NB-IoT"
//                       : data[17].description.split(" ", 2)}
//                   </span>
//                   <span className="col-4 justify-content-and rightT">
//                     {data.count}
//                   </span>
//                 </div>
//                 {17 === activeIndex && (
//                   <div style={{ marginTop: "10px"}}>
//                     <label className="txtA" style={{marginTop:"-10.5px"}}> {Math.round(data[16].percent)}% </label>
//                     <label className="txtB" style={{marginTop:"-8px"}}>from last 30 days</label>
//                   </div>
//                 )}
//               </div>
//             </>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default PlanPackage;
