import React, { useEffect, useState } from "react";
import "bs-stepper/dist/css/bs-stepper.min.css";
import "./contractDetailsStyle.css";
import jdLogo from "../../images/jdLogo.png";
import "bs-stepper/dist/css/bs-stepper.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import AddMoreData from "./AddMoreData";
import GetProfileImage from "../getProfileImage/GetProfileImage";
import SideMenu from "../../SideMenu";

var userInformation = JSON.parse(localStorage.getItem("user"));
var retrievedObject = JSON.parse(localStorage.getItem("testObject"));

function ContractDetails() {
  const [state, setState] = useState([]);
  const [description, setDescription] = useState();
  const [charge, setCharge] = useState();
  const [repeatingProductId, setRepeatingProductId] = useState();
  const [a, setA] = useState(false);
  const [simType, setSimType] = useState();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/contract/getSimType/iccid/${retrievedObject.iccid}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("simType", JSON.stringify(response.data));
      });

    const data = { contractid: retrievedObject.id };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/contract/getRepeatingProducts`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) => {
        setDescription(response.data.description.split(" ", 2));
        setRepeatingProductId(response.data.repeatingProductId);
        setCharge(response.data.charge);
        localStorage.setItem(
          "repeatingProductId",
          JSON.stringify(response.data.repeatingProductId)
        );
        localStorage.setItem("charge", JSON.stringify(response.data.charge));
        localStorage.setItem(
          "description",
          JSON.stringify(response.data.description.split(" ", 2))
        );
      })

      .catch((error) => {
        console.log(error);
        // alert(error.response.data);
      });

    fetch(
      `${process.env.REACT_APP_API_URL}/api/customer/getCustomerDetail/customerId/${retrievedObject.customer_id}`,
      {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      }
    )
      .then((res) => res.json())
      .then((response) => setState(response));
  }, []);
  return (
    <div className="row">
      <div className="col menuCol">
        <SideMenu />
      </div>
      <div className="col">
        <div className="maincontainer">
          <br />
          <br />
          <div className="row">
            <div className="col">
              <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>CONTRACT DETAILS</label>
            </div>
            <div className="col-and">
              <GetProfileImage />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label id="lblactivation">Defined Sim Number</label>
              <label id="detailslbl">{retrievedObject.iccid}</label>
              <br />
              <div>
                <Link to="/LiveUsage" className="liveBtn">
                  Live Usage Details
                </Link>
              </div>
            </div>
            <br />
            <div>
              <label id="lblactivation">Customer Details</label>
              <label id="detailslbl">Name: {state.responsiblePerson}</label>
              <label id="detailslbl">Company Name: {state.company}</label>
              <label id="detailslbl">E-mail: {state.email}</label>
              <label id="detailslbl">Phone: {state.phone}</label>
              <br />
              <label id="detailslbl">Address: {state.addressLineOne}</label>
              <label id="detailslbl">{state.addressLineTwo}</label>
              <br />
              <label id="detailslbl">Zipcode: {state.zipcode}</label>
              <label id="detailslbl">City: {state.city}</label>
              <label id="detailslbl">State: {state.state}</label>
              <label id="detailslbl">Country: {state.country}</label>
              <br />
              <div>
                <label id="lblactivation">Selected Product Plan</label>
                <span className="selectedProductPlan"> {description} </span>
              </div>
            </div>
          </div>
          <a href="/simManagement">
            <button className="backBtn">Back</button>
          </a>
          {a ? (
            <AddMoreData
              repeatingProductId={repeatingProductId}
              simType={simType}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ContractDetails;
