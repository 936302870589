import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import "./addUserStyle.css";
import { connect } from "react-redux";
// import { register } from "../actions/auth";
import axios from "axios";
// import { faTextWidth } from "@fortawesome/free-solid-svg-icons";
import ellipse1 from "../images/Ellipse1.png";
import ellipse2 from "../images/Ellipse2.png";
var userInformation = JSON.parse(localStorage.getItem("user"));

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const vfirstName = (value) => {
  // var illegalChars = /\W/;
  // (!/^[a-z][a-z\s]*$/i.test(value))
  if (value.length < 2 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The first name must be between 2 and 20 characters.
      </div>
    );
  } else if (!/^[a-zA-Z\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid first name.
      </div>
    );
  }
};

const vmiddleName = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores

  if (!/^[a-zA-Z\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid middle name.
      </div>
    );
  }
};

const vlastName = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores

  if (value.length < 2 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The last name must be between 2 and 20 characters.
      </div>
    );
  } else if (!/^[a-zA-Z\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid last name.
      </div>
    );
  }
};

const vdepartment = (value) => {
  // var illegalChars = /\W/; allow letters, numbers, and underscores
  if (!/^[a-zA-Z\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid department.
      </div>
    );
  }
};
function reFetch(e) {
  window.location.reload();
}
var user = JSON.parse(localStorage.getItem("user"));
class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    // this.onChangeEmail = this.onChangeEmail.bind(this);
    // this.onChangePassword = this.onChangePassword.bind(this);
    // this.onChangeFirstName = this.onChangeFirstName.bind(this);
    // this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
    // this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangedDepartment = this.onChangedDepartment.bind(this);
    this.onChangedRole = this.onChangedRole.bind(this);
    this.onChangedUsername = this.onChangedUsername.bind(this);
    this.onChangedPhone = this.onChangedPhone.bind(this);

    this.state = {
      username: "",
      name: "",
      department: "",
      role: "",
      dealerCode: "",
      successful: false,
      phone: "",
    };
  }

  onChangedUsername(e) {
    this.setState({
      email: e.target.value,
      username: e.target.value,
    });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangedDepartment(e) {
    this.setState({
      department: e.target.value,
    });
  }
  onChangedPhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  onChangedRole(e) {
    this.setState({
      //  [e.target.name]: e.target.value
      role: e.target.value,
      dealerCode: user.dealerCode,
    });
  }

  handleRegister = (e) => {
    const data = {
      username: this.state.username,
      dealerCode: this.state.dealerCode,
      department: this.state.department,
      name: this.state.name,
      role: this.state.role,
      phone: this.state.phone,
    };
    e.preventDefault();
    this.form.validateAll(); 
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/user/saveUser`, data, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => { 
        this.setState({
          successful: true,
        });
        reFetch();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("Please fill the required fields");
        }
        this.setState({
          successful: false,
        });
      });
  };

  render() {
    const { message } = this.props; 

    return (
      <div>
        <div
          className="modal fade"
          id="addCustomer"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="headeradd">
                  {this.props.title}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Form
                  onSubmit={this.handleRegister}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  {!this.state.successful && (
                    <div>
                      <div className="row" id="labelform">
                        <div className="col">
                          <label htmlFor="firstName">
                            Full Name <span className="textnumber2">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="addInput"
                            type="text"
                            className="form-control"
                            placeholder="Enter username"
                            name="firstName"
                            value={this.state.name}
                            onChange={this.onChangeName}
                            // validations={[required, vfirstName]}
                          />
                        </div>
                      </div>
                      <div className="row" id="labelform">
                        <div className="col">
                          <label htmlFor="email">
                            Email <span className="textnumber2">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="addInput"
                            type="text"
                            className="form-control"
                            placeholder="User’s valid e-mail address"
                            name="email"
                            value={this.state.username}
                            onChange={this.onChangedUsername}
                            validations={[required, email]}
                          />
                        </div>
                      </div>
                      <div className="row" id="labelform">
                        <div className="col">
                          <label htmlFor="firstName">
                            Phone <span className="textnumber2">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <Input
                            id="addInput"
                            type="text"
                            className="form-control"
                            placeholder="Mobile or static phone number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.onChangedPhone}
                            // validations={[required, vfirstName]}
                          />
                        </div>
                      </div>
                      <div className="row" id="labelform">
                        <div className="col">
                          <label htmlFor="department">Department</label>
                        </div>
                        <div className="col">
                          <Input
                            id="addInput"
                            type="text"
                            className="form-control"
                            placeholder="Enter username"
                            name="department"
                            value={this.state.department}
                            onChange={this.onChangedDepartment}
                            validations={[vdepartment]}
                          />
                        </div>
                      </div>
                      <div className="row" id="labelform">
                        <div className="col-3">
                          <label>
                            Role <span className="textnumber2">*</span>
                          </label>
                        </div>
                        <div className="col">
                          <select
                            id="selectrole"
                            name="role"
                            placeholder="Select user role"
                            value={this.state.role}
                            onChange={this.onChangedRole}
                            validations={[required]}
                          >
                            <option>Select User Role</option>
                            <option name="ROLE_MOD">ROLE_MOD</option>
                            <option name="ROLE_USER">ROLE_USER</option>
                          </select>
                        </div>
                      </div>

                      <div className="row" id="labelp">
                        <div className="col">
                          <label>
                            There can be only one admin user associated with
                            your account. Moderator and standard user privileges
                            are listed below.
                          </label>
                        </div>
                      </div>
                      <div className="row" id="permissions">
                        <div className="col">
                          <label>Permissions</label>
                        </div>
                      </div>
                      <div className="ul ml-2" id="labelRole">
                        {this.state.role === "ROLE_MOD" && (
                          <>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Dashboard</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Sim Management</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Sim Card Order</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">
                                  User Management
                                </span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Settings</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Help</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse2} alt="a" /> Cannot use{" "}
                                <span className="textBold">Billing</span>
                              </label>
                            </div>
                          </>
                        )}
                        {this.state.role === "ROLE_USER" && (
                          <>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Dashboard</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Sim Management</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse2} alt="a" /> Can use{" "}
                                <span className="textBold">Sim Card Order</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse2} alt="a" /> Can use{" "}
                                <span className="textBold">
                                  User Management
                                </span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Settings</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse1} alt="a" /> Can use{" "}
                                <span className="textBold">Help</span>
                              </label>
                            </div>
                            <div className="li">
                              <label>
                                <img src={ellipse2} alt="a" /> Cannot use{" "}
                                <span className="textBold">Billing</span>
                              </label>
                            </div>
                          </>
                        )}
                        {this.state.role === "" && (
                          <>
                            <>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Can use{" "}
                                  <span className="textBold">Dashboard</span>
                                </label>
                              </div>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Can use{" "}
                                  <span className="textBold">
                                    Sim Management
                                  </span>
                                </label>
                              </div>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Can use{" "}
                                  <span className="textBold">
                                    Sim Card Order
                                  </span>
                                </label>
                              </div>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Can use{" "}
                                  <span className="textBold">
                                    User Management
                                  </span>
                                </label>
                              </div>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Can use{" "}
                                  <span className="textBold">Settings</span>
                                </label>
                              </div>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Can use{" "}
                                  <span className="textBold">Help</span>
                                </label>
                              </div>
                              <div className="li">
                                <label>
                                  <img src={ellipse2} alt="a" /> Cannot use{" "}
                                  <span className="textBold">Billing</span>
                                </label>
                              </div>
                            </>
                          </>
                        )}
                      </div>
                      <br />
                      <div className="row">
                        <div className="col" id="labelrequired">
                          <label>
                            <span className="textStar">*</span> These fields are
                            required!
                          </label>
                        </div>
                        <div
                          className="col-and"
                          style={{ marginRight: "20px" }}
                        >
                          <button className="btn btn-block" id="add">
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          this.state.successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                  <CheckButton
                    style={{ display: "none" }}
                    ref={(c) => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}
export default connect(mapStateToProps)(Register);
