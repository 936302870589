// import React, { useEffect, useRef } from "react";
// import Chart from "chart.js/auto";
// import "../doughtnutChart/doughtnutChartStyle.css";
// import axios from "axios";

// const IointelTotalDoughtnutChart = () => {

//     useEffect(() => {
//         getSims();
//       }, []);

// const getSims = () =>{
//     axios.post("${process.env.REACT_APP_API_URL}/api/dashboard/simStats")
//     .then(res => {
//         console.log(res)
//     })
//     .catch(err => {
//         console.error(err);
//     })
// }

//     const chartContainer = useRef();

//     const expenses = [ 522, 1566 ];
//     const labels = [
//         "Unused SIMs",
//         "Activated SIMs",
//     ];

//     const colors = [
//         "rgba(96,120,168, 1)",
//         "rgba(255,255,255, 1)",
//     ];

//     const data = {
//         labels: labels,
//         datasets: [
//             {
//                 data: expenses,
//                 backgroundColor: colors,

//                 borderWidth: 0,
//                 hoverOffset: 4
//             }
//         ]
//     };
//     const options = {
//         layout: {
//             padding: -50
//         },
//         plugins: {
//             legend: {
//                 display: false
//             }
//         },
//         cutout: 95
//     };

//     const config = {
//         type: "doughnut",
//         data: data,
//         options: options
//     };

//     useEffect(() => {
//         const newChartInstance = new Chart(chartContainer.current, config);
//         return () => {
//             newChartInstance.destroy();
//         };
//     }, []);

//     return (
//         <div className="chartContainer" style={{marginLeft:"15px"}}>
//             <p className="statistics-title"> Total SIMs </p>
//             <canvas ref={chartContainer} className="canvas" />
//             <p className="chartInsideTotalNumber">2088</p>
//             <p className="chartInsideTotalText">SIMs Total</p>
//             <span className="chartInsideCircle"/>
//         </div>
//     );
// };
// export default IointelTotalDoughtnutChart;

import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "../doughtnutChart/doughtnutChartStyle.css";
import axios from "axios";
var userInformation = JSON.parse(localStorage.getItem("user"));

const IointelTotalDoughtnutChart = () => {
  const [used, setUsed] = useState();
  const [total, setTotal] = useState();
  const [not_used, setNot_used] = useState();
  const [suspendedSimCount, setSuspendedSimCount] = useState();

  useEffect(() => {
    const newChartInstance = new Chart(chartContainer.current, config);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/dashboard/simStats`, {}, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((res) => { 
        setUsed(res.data.used);
        setTotal(res.data.total);
        setNot_used(res.data.not_used);
        setSuspendedSimCount(res.data.SuspendedSimCount);
      })

      .catch((err) => {
        // console.error(err);
      });
    return () => {
      newChartInstance.destroy();
    };
  });

  const chartContainer = useRef();

  const expenses = [not_used, used];
  const labels = ["Not Used", "Used"];

  const colors = [
    "#fefefe", 
    "rgba(10,19,48, 1)", 
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        data: expenses,
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    layout: {
      padding: -50,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 95,
  };

  const config = {
    type: "doughnut",
    data: data,
    options: options,
  };

  return (
    <div className="chartContainer">
      <p className="statistics-title"> Total SIMs</p>
      <canvas ref={chartContainer} className="canvas" />
      <p className="chartInsideTotalNumber"> {total} </p>
      <p className="chartInsideTotalText">SIMs Total</p> 
      <span className="chartInsideCircle" />
    </div>
  );
};

export default IointelTotalDoughtnutChart;
