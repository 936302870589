import styled from "styled-components";

export const Container = styled.div`
  min-height: 1110px;
  position: fixed;
  bottom: 10;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  // background: linear-gradient(0.85turn, #9e9ea8, #494957, #3e3e49,#494957, #767686);  
  // background-image:
  `;


export const FormWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;


export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-decoration: column;
  justify-content: center;
  margin-top: 4%;
  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  max-width: 480px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin-top: 200px;
  padding: 80px 32px;

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;
export const FormH1 = styled.h1`
  // margin-left:-350px;
  // margin-top:240px;
  // margin-bottom: 20px;
  // color: #fff;
  // font-size: 36px;
  // font-weight: 900;
  // text-align: center;
  // font-family: Roboto;
  // font-style:normal;
`;


export const AInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
  font-family: Nunito;
  font-weight:300px;
  font-size:21px;
  background-color:#434350;
  color:#fff;
  border-bottom: 1px solid #7d7f80;
  outline: none;

`;

export const FormButton = styled.button`
  background: #f9a022;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 24px;
`;
export const Button1 = styled.button`
  // border-radius: 50px;
  // background: #0A1330;
  // white-space: nowrap;
  // padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  // color: ${({ dark }) => (dark ? "#FDBC58" : "#fff")};
  // font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  // outline: none;
  // border: none;
  // cursor: pointer;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // transition: all 0.2 ease-in-out;
  // text-decoration: none;
  // font-family: Roboto Slab;
  // width: 153px;
  // margin-top: 5%;
`;

