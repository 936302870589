import React, { Component } from "react";

export default class ModalAddUser extends Component {

  render() {

    return (
      <div>
        <form className="form-inline d-flex justify-content-center md-form form-sm mt-0">
          <div className="container">
            <div className="row">
              <div className="col col-12 jsend ">
                <div className="col-3">
                  <button
                    type="button"
                    id="addUserButton"
                    className="btn btn-primary mb-2"
                    data-toggle="modal"
                    data-target="#addCustomer"
                    data-whatever="@mdo"
                  >
                    + ADD USER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}