import axios from "axios";
import React, { useEffect, useState } from "react";
import Group326 from "../../images/Group326.png";
import Group309 from "../../images/Group309.png";
import Group312 from "../../images/Group312.png";
import Line from "../../images/Line.png";
import "../forgetMyPassword/ForgetMyPasswordStyle.css";
import mailOKImage from "../../images/mailOKImage.png";
import { useParams } from "react-router-dom";
var userInformation = JSON.parse(localStorage.getItem("user"));

function MailActivation() {
  const { token } = useParams();
  useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/token/${token}`,)
      .then(res => { 
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);
      })
      .catch(err => {
          console.error(err);
      })
    
  }, []);

  return (
    <div>
      <div className="containerPassword">
        <div className="row rowPassword1 justify-content-center">
          <div>
            <img src={Group326} alt="Logo Image" />
          </div>
        </div>
        <div className="row rowPassword2 justify-content-center">
          <span className="textP1">Congratulations!</span>
          <div className="w-100"></div>
          <span className="textP2">
            Your email has confimed. You can now login to the system  
          </span>
        </div>
        <div className="row rowPassword3 justify-content-center">
          <div className="row"></div>
          <br />
          <div>
            <img src={mailOKImage} alt="Logo Image" />
            <span className="textP1">Confirmed!!</span>
          </div>
          <div className="w-100"></div>

          <br />
          <div className="row"></div>
          <span className="textP1">Redirecting</span>
          <br />
          <div className="w-100"></div>
          <br />
          <div className="row">
            <span className="footerPassword">
              If this page doesn't redirecting, copy and paste the following
              link in your browser: <a style={{color:"black"}} href="http://198.251.68.153/">http://198.251.68.153</a>
            </span>
             
          </div>
          <br />
          <div className="w-100"></div>
          <br />
        </div>
        <div className="row rowPassword4 justify-content-center">
          <div className="row" style={{ paddingBottom: "10px" }}>
            <a>
              <span className="a1">Contact </span>
            </a>
            <img src={Line} alt="Logo Image" />
            <a>
              <span className="a1"> Privacy Policy </span>
            </a>
            <img src={Line} alt="Logo Image" />
            <a>
              <span className="a1"> FQA</span>
            </a>
          </div>
          <div className="row">
            <span className="footerPassword">
              You received this email because we received a request for reset
              your password. If you didn't request or you think received this
              email by mistake, you can safely delete this email.
            </span>
          </div>
        </div>
      </div>

      {/* <button onClick={submitPassword}>Submit</button> */}
    </div>
  );
}

export default MailActivation;
