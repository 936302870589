import React, { Component } from 'react';
import "./getImageStyle.css";
import axios from "axios";

var userInformation = JSON.parse(localStorage.getItem("user"));
var ticketDetail = JSON.parse(localStorage.getItem("ticketDetail"));

class GetImage extends Component {
    constructor(props) {
        super(props);
        this.state = {

            image: [],
        };
    }

    componentDidMount() {
        this.getImage();
    }

    getImage = () => {
        try {
            axios
                .get(
                    `https://test-mvnoportal.iointel.com:8996/api/ticketImages/getTicketImages/${ticketDetail[2]}`,
                    {
                        headers: {
                            Authorization: `Bearer ${userInformation.access_token}`,
                        },
                    }
                )
                .then((response) => {
                    // console.log(response.data.map(x => (x.url)))
                    response.data.map(x => (
                        axios.get(
                            x.url,
                            {
                                responseType: "arraybuffer",
                                headers: {
                                    Authorization: `Bearer ${userInformation.access_token}`,
                                },
                            }
                        )
                            .then((response) => {
                                let blob = new Blob([response.data], {
                                    type: response.headers["Content-Type"],
                                });
                                let image = URL.createObjectURL(blob);
                                // console.log(image)
                                  this.setState({
                                    image: [...this.state.image, image]
                                  })

                            })
                    ))
                })

        } catch (error) {

        }
    };
    render() {

        const list = this.state.image.map(product => <img className="ticketImage" src={product} />)

        return (
            <div>
                {/* {console.log(this.state.image)} */}
                {list}
            </div>
        )
    }
}

export default GetImage;
