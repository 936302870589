import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetProfileImage from "../../components/getProfileImage/GetProfileImage";
import SupportSideMenu from './supportSideMenu/supportSideMenu';
import bg from "../../images/bgNew.png";
import cleanLoginBg from "../../images/beyazZemin.png";
import GULSAH from "../../images/GULSAH.png";
import SideMenu from "../../SideMenu";

var userInformation = JSON.parse(localStorage.getItem("user"));


function SupportPage() {
    const [boolean, setboolean] = useState(false);
    return (
        <div className="row">
            <div className="col menuCol">
                <SideMenu />
            </div>
            <div className="col">
                <div className="content" style={{ width: "95%" }}>
                    {userInformation.dealerType == "CleanIot" ? (
                        <>
                            <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                            <img src={GULSAH} className="cleanBg" alt="login bg frame" />
                        </>
                    ) : (
                        <>
                            <img src={bg} id="bg" alt="Background Image" />
                        </>
                    )}
                    <br />
                    <br />
                    <div className="row">
                        <div className="col">
                            <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SUPPORT</label>
                        </div>
                        <div className="col-and">
                            <GetProfileImage />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col search">
                            <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"} style={{ marginTop: "-22px" }}>Search for a question</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className={userInformation.dealerType == "CleanIot" ? "form-control searchstyleClean" : "form-control searchstyle"}
                                    placeholder="Type your question or search keywords"
                                // value={filter}
                                // onChange={this.searchTxt.bind(this)}
                                />
                                {userInformation.dealerType == "CleanIot" ? (<>
                                    <div className="input-group-prepend">
                                        <div
                                            className="input-group-text"
                                            id="searchicon"
                                            style={{
                                                background: "#000000",
                                                color: "#B1CB32",
                                                borderEndEndRadius: "4px",
                                                borderStartEndRadius: "4px",
                                            }}
                                        ><FontAwesomeIcon icon={faSearch} /></div>
                                    </div>
                                </>) : (<>
                                    <div className="input-group-prepend">
                                        <div
                                            className="input-group-text"
                                            id="searchicon"
                                            style={{
                                                background: "#0A1330",
                                                color: "#D04102",
                                                borderEndEndRadius: "4px",
                                                borderStartEndRadius: "4px",
                                            }}
                                        ><FontAwesomeIcon icon={faSearch} /></div>
                                    </div>
                                </>)}
                            </div>
                        </div>
                    </div>
                    <br />
                    <SupportSideMenu />
                </div>
            </div>
        </div>
    )
}
export default SupportPage;
