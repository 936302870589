import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./doughtnutChartStyle.css";
import axios from "axios";
var userInformation = JSON.parse(localStorage.getItem("user"));

const TotalDoughtnutChart = () => {
  const [totalSims, setTotalSims] = useState();
  const [activeSims, setActiveSims] = useState();
  const [usedSims, setUsedSims] = useState();
  const [boolean, setBoolean] = useState(false);

  useEffect(() => {
    const newChartInstance = new Chart(chartContainer.current, config);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/dashboard/totalSims/dealerCode/${userInformation.dealerCode}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((res) => { 
        setTotalSims(res.data.totalSims);
        setActiveSims(res.data.activeSims);
        setUsedSims(res.data.usedSims);
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      newChartInstance.destroy();
    };
  });

  const chartContainer = useRef();
  const expenses = [activeSims, usedSims];
  const labels = ["Unused SIMs", "Activated SIMs"];

  let colors;
  const color = userInformation.dealerType == "CleanIot" ? (colors = ["rgba(0, 0, 0, 1)", "rgba(196, 197, 197, 1)"]) : (colors = ["rgba(96,120,168, 1)", "rgba(255,255,255, 1)"])

  const data = {
    labels: labels,
    datasets: [
      {
        data: expenses,
        backgroundColor: color,

        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    layout: {
      padding: -50,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 95,
  };

  const config = {
    type: "doughnut",
    data: data,
    options: options,
  };

  // useEffect(() => {
  //   const newChartInstance = new Chart(chartContainer.current, config);
  //   return () => {
  //     newChartInstance.destroy();
  //   };
  // }, []);

  return (
    <div className="chartContainer">
      <p className={userInformation.dealerType == "CleanIot" ? "statistics-title-clean" : "statistics-title"}> Total SIMs </p>
      <canvas ref={chartContainer} className="canvas" />
      <p className={userInformation.dealerType == "CleanIot" ? "chartInsideTotalNumberClean" : "chartInsideTotalNumber"}> {totalSims} </p>
      <p className={userInformation.dealerType == "CleanIot" ? "chartInsideTotalTextClean" : "chartInsideTotalText"}>SIMs Total</p>
      {userInformation.dealerType == "CleanIot" ? (null) : (<span className="chartInsideCircle" />)}
    </div>
  );
};
export default TotalDoughtnutChart;
