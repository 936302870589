import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./doughtnutChartStyle.css";
import axios from "axios";
var userInformation = JSON.parse(localStorage.getItem("user"));

const DefinedDoughtnutChart = () => {
  const dataa = {
    payerid: userInformation.dealerCode,
  };
  const [activeSimCount, setActiveSimCount] = useState();
  const [customerSimCount, setCustomerSimCount] = useState();
  const [deactivatedSimCount, setDeactivatedSimCount] = useState();
  const [suspendedSimCount, setSuspendedSimCount] = useState();
  const [boolean, setBoolean] = useState(false);

  useEffect(() => {
    const newChartInstance = new Chart(chartContainer.current, config);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/dashboard/totalCustomerSims`,
        dataa,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((res) => {
        setActiveSimCount(res.data.ActiveSimCount);
        setCustomerSimCount(res.data.CustomerSimCount);
        setDeactivatedSimCount(res.data.DeactivatedSimCount);
        setSuspendedSimCount(res.data.SuspendedSimCount);
      })

      .catch((err) => {
        // console.error(err);
      });
    return () => {
      newChartInstance.destroy();
    };
  });

  const chartContainer = useRef();

  const expenses = [deactivatedSimCount, suspendedSimCount, activeSimCount];
  const labels = ["Deactive", "Suspend", "Active"];
  
  let colors;
  const color = userInformation.dealerType == "CleanIot" ? (
    colors = [
    "rgba(125, 127, 128, 1)",
    "rgba(0, 0, 0, 1)",
    "rgba(196, 197, 197, 1)",
  ]) : (colors = [
    "rgba(128, 134, 161, 1)",
    "rgba(10,19,48, 1)",
    "rgba(255,255,255, 1)",
  ])

  const data = {
    labels: labels,
    datasets: [
      {
        data: expenses,
        backgroundColor: color,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  const options = {
    layout: {
      padding: -50,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 95,
  };

  const config = {
    type: "doughnut",
    data: data,
    options: options,
  };

  return (
    <div className="chartContainerA">
      <p className={userInformation.dealerType == "CleanIot" ? "statistics-title-clean" : "statistics-title"}> Defined SIMs</p>
      <canvas ref={chartContainer} className="canvas" />
      <p className={userInformation.dealerType == "CleanIot" ? "chartInsideTotalNumberClean" : "chartInsideTotalNumber"}> {customerSimCount} </p>
      <p className={userInformation.dealerType == "CleanIot" ? "chartInsideTotalTextClean" : "chartInsideTotalText"}>Total Customer SIMs</p>
      {userInformation.dealerType == "CleanIot" ? (null) : (<span className="chartInsideCircle" />)}
    </div>
  );
};

export default DefinedDoughtnutChart;
