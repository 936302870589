import React, { useEffect, useState } from "react";
import "bs-stepper/dist/css/bs-stepper.min.css";
import "./contractDetailsStyle.css";
import jdLogo from "../../images/jdLogo.png";
import "bs-stepper/dist/css/bs-stepper.min.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import GetProfileImage from "../getProfileImage/GetProfileImage";
import SideMenu from "../../SideMenu";

var retrievedObject = JSON.parse(localStorage.getItem("testObject"));
var userInformation = JSON.parse(localStorage.getItem("user"));

function LiveUsage() {
  const [invoiceAmount, setInvoiceAmount] = useState();
  const [smsCounter, setSmsCounter] = useState();
  const [dataRemainingQuota, setDataRemainingQuota] = useState();
  const [description, setDescription] = useState();
  const [dataInitialQuota, setDataInitialQuota] = useState();

  useEffect(() => {
    const data = { contractid: retrievedObject.id };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/contract/getAccuInfo`, data, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => {
        setInvoiceAmount(response.data.invoiceAmount);
        setSmsCounter(response.data.smsCounter);
        setDataRemainingQuota(response.data.dataRemainingQuota);
        setDataInitialQuota(response.data.dataInitialQuota);
        setDescription(response.data.description.split(" ", 2));
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const usedData = dataInitialQuota - dataRemainingQuota;
  return (
    <div className="row">
      <div className="col menuCol">
        <SideMenu />
      </div>
      <div className="col">
        <div className="maincontainer">
          <br />
          <br />
          <div className="row">
            <div className="col">
              <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>Live Usage</label>
            </div>
            <div className="col-and">
              <GetProfileImage />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label id="lblactivation">Defined Sim Number</label>
              <label id="detailslbl">{retrievedObject.iccid}</label>
            </div>
            <br />
            <div>
              <label id="lblactivation">Usage Details</label>
              <label id="detailslbl">Invoice Amount: {invoiceAmount}</label>
              <label id="detailslbl">SMS Counter: {smsCounter}</label>
              <label id="detailslbl">Data Counter: {usedData}</label>
              <br />
              <label id="lblactivation">Default Data Package: {description}</label>
              <br />

              <br />
              <br />
              <div className="row">
                <span
                  className="bar1"
                  style={{
                    paddingLeft: "28.7px",
                    paddingRight: "30px",
                    marginBottom: "250px",
                  }}
                >
                  {" "}
                  {usedData} MB{" "}
                </span>
                <span
                  className="bar2"
                  style={{
                    paddingTop: "11px",
                  }}
                >
                  {(usedData / dataInitialQuota) * 100} % of total
                </span>
                <label
                  className="bar3"
                  style={{
                    paddingLeft: "80%",
                  }}
                >
                  %{100 - (usedData / dataInitialQuota) * 100} remaining
                </label>
              </div>
              <div
                className="row"
                style={{ marginTop: "-250px", marginLeft: "2.5px" }}
              >
                <Progress
                  percent={(usedData / dataInitialQuota) * 100}
                  status="success"
                  theme={{
                    success: {
                      symbol: " ",
                      color: "#3CA100",
                    },
                  }}
                />
              </div>
              {/* <label
            className="bar3"
            style={{
              paddingLeft: "80%",
            }}
          >
            %{100- (usedData / dataInitialQuota) * 100} remaining
          </label>
          <span
            className="bar1"
            style={{
              paddingLeft: "7px",
              paddingRight: "30px",
              marginBottom: "250px",
            }}
          >
            {" "}
            {usedData} MB{" "}
          </span>
          <span className="bar2" style={{ marginBottom: "10px" }}>
            {(usedData / dataInitialQuota) * 100} % of total
          </span> */}

              <div>
                <br />
                <a href="/AddMoreData" className="addDataBtn">
                  + ADD MORE DATA
                </a>
                {/* <Link to="/AddMoreData" className="addDataBtn"  >
              + ADD MORE DATA
            </Link> */}
              </div>
            </div>
          </div>
          <Link to="/ContractDetails">
            <button className="backBtn">Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LiveUsage;
