import React from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
var userInformation = JSON.parse(localStorage.getItem("user"));
var a;
class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Sim Activation",
          data: [],
        },
      ],

      options: {
        annotations: {},
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        xaxis: {
          labels: {
            style: {
              colors: [
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
              ],
            },
          },
          categories: [],
        },
        yaxis: {
          labels: {
            style: {
              colors: [
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
                "#fefefe",
              ],
            },
          },
        },
        colors: ["#fefefe"],
        chart: {
          toolbar: {
            show: false,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/dashboard/dailyData/dealerCode/${userInformation.dealerCode}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((res) => { 

        const dataArray = [];
        const dayArray = [];

        res.data.map((val) => {
          dataArray.push(val.total);
        });

        res.data.map((val) => {
          dayArray.push(val.day);
        });

        let key = 2;
        this.setState((prevState) => ({
          series: prevState.series.map((el) =>
            el.key === key ? el : { ...el, data: dataArray }
          ),
        }));

        this.setState((x) => ({
          ...x,
          options: {
            ...x.xaxis,
            xaxis: {
              ...x.categories,
              categories: dayArray,
            },
          },
        }));
 
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <div id="chart">
        <div>
          <span style={{ color: "#fefefe", marginLeft: "17px" }}>
            Daily Sim Activation{" "}
          </span>
          <span style={{ color: "#8F9193", marginLeft: "16px" }}>7 Days </span>
        </div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={320}
        />
      </div>
    );
  }
}

export default ApexChart;
