// import axios from "axios";
// import React, { useState } from "react";
// import Group326 from "../../images/Group326.png";
// import Group308 from "../../images/Group8.png";
// import Group312 from "../../images/Group312.png";
// import Line from "../../images/Line.png";

// var userInformation = JSON.parse(localStorage.getItem("user"));

// function ChangePasswordSuccess() {

//   return (
//     <div>
//       {/* {console.log(userInformation.username)} */}
//       <div className="containerPassword">
//         <div className="row rowPassword1 justify-content-center">
//           <div>
//             <img src={Group326} alt="Logo Image" />
//           </div>
//         </div>
//         <div className="row rowPassword2 justify-content-center">
//           <span className="textP1">Congratulations!</span>
//           <div className="w-100"></div>
//           <span className="textP2">
//             Your password has changed. You can now login to the system.
//           </span>
//           <br />
//           <br />
//           <span className="textP2">Password has changed!</span>
//         </div>
//         <div className="row rowPassword3 justify-content-center">
//           <div className="row">
//             <img src={Group308} alt="Logo Image" />
//           </div>
//           <br />
//           <div className="w-100"></div>

//           <br />
//           <div className="w-100"></div>

//           <div className="w-100"></div>
//           <br />
//         </div>
//         <div className="row rowPassword4 justify-content-center">
//           <div className="row" style={{ paddingBottom: "10px" }}>
//             <a>
//               <span className="a1">Contact </span>
//             </a>
//             <img src={Line} alt="Logo Image" />
//             <a>
//               <span className="a1"> Privacy Policy </span>
//             </a>
//             <img src={Line} alt="Logo Image" />
//             <a>
//               <span className="a1"> FQA</span>
//             </a>
//           </div>
//           <div className="row">
//             <span className="footerPassword">
//               You landed this page because we received a request for reset your
//               password. If you didn't request or you think landed this page by
//               mistake, you can safely leave.
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ChangePasswordSuccess;

import React, { Component } from "react";
import { connect } from "react-redux";

import Group326 from "../../images/Group326.png";
import Group308 from "../../images/Group8.png";
import ChangePasswordSuccessOK from "../../images/ChangePasswordSuccessOK.png";
import Group312 from "../../images/Group312.png";
import Line from "../../images/Line.png";
import EventBus from "../../common/EventBus";
import { history } from "../../helpers/history";
import { clearMessage } from "../../actions/message";
import { logout } from "../../actions/auth";
import finish from "../../images/finish.png";

var userInformation = JSON.parse(localStorage.getItem("user"));

class ChangePasswordSuccess extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showUserBoard: false,
      currentUser: [],
      isVisible: false,
      isVisibleA: false,
      isVisibleB: false,
      isVisibleC: false,
      isVisibleD: false,
      isVisibleE: false,
      isVisibleF: false,
      isVisibleG: false,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }
  componentDidMount() {
    const user = this.props.user;

    if (user) {
      user.role === "ROLE_SUPERADMIN"
        ? this.setState({
            showSuperAdminBoard: true,
            currentUser: user,
          })
        : this.setState({
            currentUser: user,
            showModeratorBoard: user.role.includes("ROLE_MOD"),
            showAdminBoard: user.role.includes("ROLE_ADMIN"),
            showUserBoard: user.role.includes("ROLE_USER"),
          });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
    setTimeout(() => {
      this.logOut();
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    }, 2000);
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showSuperAdminBoard: false,
      showUserBoard: false,
      currentUser: undefined,
    });
  }
  render() {
    return (
      <div>
        {/* {console.log(userInformation.username)} */}

        <div className="containerPassword">
         

          <div className="row rowPassword1 justify-content-center">
            <div>
              <img src={Group326} alt="Logo Image" />
            </div>
          </div>
          <div className="row rowPassword2 justify-content-center">
            <span className="textP1">Congratulations!</span>
            <div className="w-100"></div>
            <span className="textP2">
              Your password has changed. You can now login to the system.
            </span>
            <br />
            <br />
             
          </div>
          <div className="row rowPassword3 justify-content-center">
            <div className="row">
            <img src={finish} alt="Logo Image" />

            </div>
            <br />
            <div className="w-100"></div>
            <div>
              If this page doesn't redirecting, click
              <a href="/" className="nav-link" onClick={this.logOut}>
                <span style={{ cursor: "pointer", color: "black" }}>
                  here
                </span>
              </a>
            </div>
            <br />
            <div className="w-100"></div>

            <div className="w-100"></div>
            <br />
          </div>
          <div className="row rowPassword4 justify-content-center">
            <div className="row" style={{ paddingBottom: "10px" }}>
              <a>
                <span className="a1">Contact </span>
              </a>
              <img src={Line} alt="Logo Image" />
              <a>
                <span className="a1"> Privacy Policy </span>
              </a>
              <img src={Line} alt="Logo Image" />
              <a>
                <span className="a1"> FQA</span>
              </a>
            </div>
            <div className="w-100"></div>
            <div className="row">
              <span className="footerPassword">
                You landed this page because we received a request for reset
                your password. If you didn't request or you think landed this
                page by mistake, you can safely leave.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(ChangePasswordSuccess);
