import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Chip from "@material-ui/core/Chip";
import "../../supportDealer/submittedTickets/ticketTable.css";
import axios from "axios";
import Moment from 'moment';

var userInformation = JSON.parse(localStorage.getItem("user"));

const themeStatus = createTheme({
  palette: {
    primary: {
      main: "#11cb5f",
    },
    secondary: {
      main: "#ffea00",
    },
    check: {
      main: "#b2102f",
    },
  },
});
 
const colums = [
  { name: "title", label: "Ticket Title" },
  {
    name: "ticketStatus",
    label: "Ticket Status",
    options: {
      filter: true,
      customBodyRender: (value) => {
        return (
          <ThemeProvider theme={themeStatus}>
            <Chip
              label={value}
              color={
                value === "OPENED"
                  ? "secondary"
                  : value === "UNREAD"
                  ? "error"
                  : "secondary"
              }
            />
          </ThemeProvider>
        );
      },
    },
  },
  { name: "id", label: "Ticket Number" },
  { name: "dealerCode", label: "DealerCode/Name" },
  { name: "page", label: "Topic" },
  {
    name: "status",
    label: "Request",
    options: {
      filter: true,
      customBodyRender: (value) => {
        return (
          <ThemeProvider theme={themeStatus}>
            <Chip
              label={value}
              color={
                value === "SOLVED"
                  ? "primary"
                  : value === "OPEN"
                  ? "secondary"
                  : "error"
              }
            />
          </ThemeProvider>
        );
      },
    },
  },
  { name: "date", label: "Date" },
];

function TicketTableSuperAdmin() {
  const [products, setProducts] = useState();
  const [products1, setProducts1] = useState();
  const [products2, setProducts2] = useState();
  const [products3, setProducts3] = useState();
  const [counterAll, setCounterAll] = useState();
  const [counterSolved, setCounterSolved] = useState();
  const [counterOpend, setCounterOpend] = useState();
  const [open, setOpen] = useState(false);
  const [solved, setSolved] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/ticket/getStatusCount`, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setCounterAll(response.data.data);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ticket/getStatusCounts/SOLVED`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data);
        setCounterSolved(response.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/ticket/getStatusCounts/OPEN`, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setCounterOpend(response.data.data);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/ticket/getAllTickets`, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((response) =>
        response.data.map((user) => ({
          category: `${user.category}`,
          date: Moment(`${user.date}`).format("MM-DD-YYYY"),
          dealerCode: `${user.dealerCode}`,
          id: `${user.id}`,
          page: `${user.page}` === "null" ? "" : `${user.page}`,
          status: `${user.status}`,
          ticketStatus: `${user.ticketStatus}`,
          title: `${user.title}`,
        }))
      )
      .then((contacts) => {
        setProducts(contacts);
        setProducts1(contacts);
        setProducts2(contacts);
        setProducts3(contacts);
      });
  };

  let data = products;

  const showTotal = () => {
    setProducts(products3);
  };

  const showOpen = () => {
    setProducts(products1.filter((item) => item.status === "OPEN"));
    setOpen(true);
    setSolved(false);
  };
  const showSolved = () => {
    // console.log(products, data);
    setProducts(products2.filter((item) => item.status === "SOLVED"));
    setOpen(false);
    setSolved(true);
  };

  const handleRowClick = (rowData, rowMeta) => {
    console.log(rowData, rowMeta);
    localStorage.setItem("ticketDetail", JSON.stringify(rowData));
    window.location.href = "/SubmittedIointelTicketDetail";
  };

  const options = {
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    onRowClick: handleRowClick,
    onRowsDelete: (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => data[d.dataIndex].id); // array of all ids to to be deleted
      // setIdsToDelete(rowsDeleted.data.map(d => data[d.dataIndex].id));
      console.log(idsToDelete);

      const dataTicket = new FormData();
      dataTicket.append("ids", idsToDelete);
      axios
        .post(
          `https://test-mvnoportal.iointel.com:8996/api/ticket/deleteTickets`,
          dataTicket,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          window.alert("Deleted successfully!");
        });
    },
  };
  return (
    <>
      <div className="row">
        <div className="col statusTicketCol">
          <div className="container statusTicketRow pl-0 pr-0">
            <div className="row  p-0">
              <div className="col pr-0 mr-0">
                <button className="statusTicketT" onClick={showTotal}>
                  Total Tickets
                </button>
              </div>
              <div className="col pl-0 ml-0">
                <div
                  className="numberTicket pt-2"
                  style={{ paddingLeft: "32px" }}
                >
                  {" "}
                  {counterAll}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col statusTicketCol">
          <div className="container statusTicketRow pl-0 pr-0">
            <div className="row p-0">
              <div className="col pr-0 mr-0">
                <button className="statusTicketO" onClick={showOpen}>
                  Open Tickets
                </button>
              </div>
              <div className="col pl-0 ml-0">
                <div
                  className="numberTicket pt-2"
                  style={{ paddingLeft: "32px" }}
                >
                  {" "}
                  {counterOpend}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col statusTicketCol">
          <div className="container statusTicketRow pl-0 pr-0">
            <div className="row p-0">
              <div className="col pr-0 mr-0">
                <button className="statusTicketS" onClick={showSolved}>
                  Solved Tickets
                </button>
              </div>
              <div className="col pl-0 ml-0">
                <div
                  className="numberTicket pt-2"
                  style={{ paddingLeft: "32px" }}
                >
                  {" "}
                  {counterSolved}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <MUIDataTable options={options} data={data} columns={colums} />;
    </>
  );
}

export default TicketTableSuperAdmin;
