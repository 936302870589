import React, { Component } from "react";
import "./board-user.component.style.css";
import { Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSortDown } from "@fortawesome/free-solid-svg-icons";
import download from "../images/download.png";
import downloadClean from "../images/downloadClean.png";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";

var userInformation = JSON.parse(localStorage.getItem("user"));

export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      boolean: false,
      filter: "",
      contacts: [],
      showActives: true,
      showActivesA: false,
      showActivesB: false,
      paid: "",

      // selectedContact: null,
    };
    // this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    // this.fetchData();
    // this.a();
    this.showUnpaids();
  }

  // a() {
  //   const sendCustId = {
  //     customerid: userInformation.dealerCode,
  //   };
  //   fetch(
  //     "${process.env.REACT_APP_API_URL}/api/billing/get-customer-invoices/status/unpaid",
  //     {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(sendCustId),
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((parsedJSON) =>
  //       parsedJSON.map((r) => ({
  //         invoice_no: `${r.invoice_no}`,
  //         invoice_amount: `${r.invoice_amount}`,
  //         due_date: `${r.due_date}`,
  //         record_insert_date: `${r.record_insert_date}`,
  //       }))
  //     )
  //     .then((contacts) =>
  //       this.setState({
  //         contacts,
  //         isLoading: false,
  //       })
  //     )
  //     .catch((error) => console.log("parsing failed", error));
  // }

  showPaids() {
    this.setState({
      showActivesA: true,
      showActivesB: false,
      paid: "paid"
    });
    this.fetchData("paid");
  }
  showUnpaids() {
    this.setState({
      showActivesA: false,
      showActivesB: true,
      paid: "unpaid"

    });
    this.fetchData("unpaid");
  }

  fetchData(x) {
    const sendCustId = {
      customerid: userInformation.dealerCode,
    };

    this.setState({
      isLoading: true,
      contacts: [],
    });

    fetch(
      `${process.env.REACT_APP_API_URL}/api/billing/getCustomerInvoices/status/` +
      x,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
        body: JSON.stringify(sendCustId),
      }

    )
      .then((response) => response.json())
      .then((parsedJSON) =>
        parsedJSON.map((r) => ({
          invoice_no: `${r.invoice_no}`,
          invoice_amount: `${r.invoice_amount}`,
          due_date: `${r.due_date}`,
          record_insert_date: `${r.record_insert_date.split(" ", 1)}`,
          pdfLink: `${r.pdfLink}`,
          paymentLink: `${r.payment_link}`,
        }))
      )
      .then((contacts) => {
        this.setState({
          contacts,
          isLoading: false,
        });
      })
      .catch((error) => console.log("parsing failed", error));
  }

  searchTxt(e) {
    this.setState({ filter: e.target.value });
  }
  updateActive(active) {
    this.setState({ showActives: active });
  }
  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.contacts];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ contacts: arrayCopy });
  }

  render() {
    let { filter, contacts, showActives, showActivesA, showActivesB, Datasearch, isLoading, boolean, } = this.state;
    const data = contacts.filter((item) => item.isActive == showActives);

    Datasearch = contacts.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toLowerCase().includes(filter.toLocaleLowerCase())
      );
    });

    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="maincontainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>BILLING</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <div className="row ">
              <div className="col statusBill">
                <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"}>Select Status</label>
                <ButtonGroup aria-label="Basic example">
                  <button
                    className={userInformation.dealerType == "CleanIot" ? (showActivesA ? "activatedbtnClean" : "deactivatedbtnClean") : (showActivesA ? "activatedbtn" : "deactivatedbtn")}
                    onClick={() => this.showPaids()}
                  >
                    Paid
                  </button>
                  <button
                    className={userInformation.dealerType == "CleanIot" ? (showActivesB ? "activatedbtnClean" : "deactivatedbtnClean") : (showActivesB ? "activatedbtn" : "deactivatedbtn")}
                    onClick={() => this.showUnpaids()}
                  >
                    Unpaid
                  </button>
                </ButtonGroup>
              </div>
              <div className="col-4">
                <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"}>Search Invoice</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={userInformation.dealerType == "CleanIot" ? "form-control searchstyleClean" : "form-control searchstyle"}
                    placeholder="Invoice Number"
                    value={filter}
                    onChange={this.searchTxt.bind(this)}
                  />
                  {userInformation.dealerType == "CleanIot" ? (<>
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        id="searchicon"
                        style={{
                          background: "#000000",
                          color: "#B1CB32",
                          borderEndEndRadius: "4px",
                          borderStartEndRadius: "4px",
                        }}
                      ><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                  </>) : (<>
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        id="searchicon"
                        style={{
                          background: "#0A1330",
                          color: "#D04102",
                          borderEndEndRadius: "4px",
                          borderStartEndRadius: "4px",
                        }}
                      ><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                  </>)}
                </div>
              </div>
            </div>
            <br />
            <table className={userInformation.dealerType == "CleanIot" ? "aClean" : "a"}>
              <thead className={userInformation.dealerType == "CleanIot" ? "theadClean" : "thead"}>
                <tr>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("username")}
                  >
                    Invoice Number
                    <button
                      className="btn"
                      style={{ cursor: "pointer", color: "#A4A6A7" }}
                    >
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col">Invoice Amount</th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("dueDate")}
                  >
                    Due Date
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.sortBy("dateCreated")}
                  >
                    Date Created
                    <button className="btn" style={{ color: "#A4A6A7" }}>
                      <FontAwesomeIcon icon={faSortDown} />
                    </button>
                  </th>
                  {showActivesA ? (null) : (<th scope="col">Pay Now</th>)}
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody id="tablebody">
                {!isLoading && contacts.length > 0
                  ? Datasearch.map((contact) => (
                    <tr key={contact.invoice_no}>
                      <td>{contact.invoice_no} </td>
                      <td>{this.state.paid} </td>
                      <td>{contact.invoice_amount} </td>
                      <td>{contact.due_date}</td>
                      <td>{contact.record_insert_date}</td>
                      {showActivesA ? (null) : (<td><a href={contact.paymentLink} target="_blank" rel="noreferrer noopener"><span className="payPal">Pay With PayPal</span></a></td>)}
                      <td>
                        {showActives && (
                          <a
                            href={`https://api-test.iointel.com/crmapi/${contact.pdfLink}`}
                            target="_blank"
                          >
                            <button type="button" className="btn">
                              {userInformation.dealerType == "CleanIot" ? (<img src={downloadClean} alt="download Logo" />) : (<img src={download} alt="download Logo" />)}
                            </button>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    );
  }
}
