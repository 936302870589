import React, { useEffect } from 'react';
import AddImage from '../openTicket/addImage';
import SupplyTicketDetails from '../openTicket/SupplyTicketDetails';
import GetImage from '../../supportSuperAdmin/supportPanel/GetImage';
import GetProfileImage from "../../../components/getProfileImage/GetProfileImage";
import SideMenu from "../../../SideMenu";
var userInformation = JSON.parse(localStorage.getItem("user"));

var ticketDetail = JSON.parse(localStorage.getItem("ticketDetail"));
export default function SubmittedTicketsDetail() {
  useEffect(() => {
    //   console.log(ticketDetail[2])

  }, [])


  return (
    <div className="row">
      <div className="col menuCol">
        <SideMenu />
      </div>
      <div className="col">
        <div className="maincontainer">
          <br />
          <br />
          <div className="row">
            <div className="col">
              <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SUPPORT</label>
            </div>
            <div className="col-and">
              <GetProfileImage />
            </div>
          </div>
          <SupplyTicketDetails />
          <div className="row pl-3 pt-2">
            <label className="screenshotText">Browse for a file to attach or drag and drop screenshot here</label>
          </div>
          <div className="row pl-2 pt-2">
            <GetImage />

            <AddImage />
          </div>
        </div>
      </div>
    </div>
  )
}
