import React, { Component } from "react";
import jdLogo from "../images/jdLogo.png";
import simLogo from "../images/sim.png";
import finish from "../images/finish.png";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";

var orderNumber = JSON.parse(localStorage.getItem("orderNumber"));
var userInformation = JSON.parse(localStorage.getItem("user"));

class SimOrderSuccessful extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boolean: false,
    };
  }
  render() {
    const { boolean, } = this.state;
    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="activationContainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SIM-CARD ORDER</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <br />

            <div className="d-flex justify-content-center" id="mainOrderContainer">
              <div className="row">
                <div className="col-9">
                  <div className="row" className={userInformation.dealerType == "CleanIot" ? "textContainerClean" : "textContainer"}>
                    <span className={userInformation.dealerType == "CleanIot" ? "textClean" : "text"}>
                      Your order is
                      <span className={userInformation.dealerType == "CleanIot" ? "textnumberClean" : "textnumber"}> processing.</span>
                    </span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div id="simLogo">
                      <img src={simLogo} alt="simLogo" />
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <span className={userInformation.dealerType == "CleanIot" ? "text4Clean" : "text4"}>
                      Your order number is{" "}
                      <span className={userInformation.dealerType == "CleanIot" ? "textnumber2Clean" : "textnumber2"}> {orderNumber} </span>
                    </span>
                  </div>
                </div>
                {/* "col-6 mt-4 ml" */}
                <div className="col mr-5" style={{ paddingLeft: "0px", marginLeft: "-35px" }}>
                  <div className="row" style={{ paddingTop: "15px" }}>
                    <div className="col-4">
                      <img src={finish} id="finish" alt="Finish Image" />
                    </div>
                    <div className="col" style={{ paddingTop: "15px" }}>
                      <label id="lblfinish">Thank you!</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SimOrderSuccessful;
