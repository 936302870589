import { borderBottom, borderTop, color, minHeight } from "@mui/system";
import React, { useEffect, useState, Component } from "react";
import "../../supportDealer/openTicket/supplyDetailsStyle.css";
import sendButton from "../../../images/sendButton.png";
import requestOK from "../../../images/requestOK.png";
import axios from "axios";
import GetProfileImage from "../../../components/getProfileImage/GetProfileImage";
import Button from "@restart/ui/esm/Button";
import GetImage from "./GetImage";
import SideMenu from "../../../SideMenu";

var ticketDetail = JSON.parse(localStorage.getItem("ticketDetail"));
var userInformation = JSON.parse(localStorage.getItem("user"));

export default class SupportPanel extends Component {
  constructor(props) {
    super(props);
    this.onChangedStatus = this.onChangedStatus.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeTitle = this.changeTitle.bind(this);

    this.state = {
      response: {},
      status: "",
      userMessage: [],
      adminMessage: [],
      messages: [],
      message: "",
      category: "",
      page: "",
      title: "",
      ticketStatus: "",
      newDealerCode: "",
    };
  }

  onChangedStatus(e) {
    this.setState({
      //  [e.target.name]: e.target.value
      status: e.target.value,
    });
  }
  saveChanges() {
    if (
      this.state.category !== "" &&
      this.state.category !== "please select category of your problem" &&
      this.state.title !== ""
    ) {
      const status = this.state.status;
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/ticket/changeStatus/${ticketDetail[2]}/${status}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/SupportIointel";
          }
        });
      const newData = {
        title: this.state.title,
        category: this.state.category,
        page: this.state.page,
        id: ticketDetail[2],
        status: this.state.status,
        ticketStatus: this.state.ticketStatus,
        dealerCode: this.state.newDealerCode,
      };
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/ticket/addTicket`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      );
    }
  }

  componentDidMount() {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/ticket/changeTicketStatus/${ticketDetail[2]}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            response: response.data,
            status: response.data.status,
            page: response.data.page,
            title: response.data.title,
            category: response.data.category,
            ticketStatus: response.data.ticketStatus,
            newDealerCode: response.data.dealerCode,
          });
        });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/ticketMessage/getAllMessages/${ticketDetail[2]}`,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((response) => {
          this.setState({
            messages: response.data,
          });
          response.data.map(
            (x) =>
              (x.userMessage !== null &&
                this.setState({
                  userMessage: [...this.state.userMessage, x.userMessage],
                })) ||
              (x.adminMessage !== null &&
                this.setState({
                  adminMessage: [...this.state.adminMessage, x.adminMessage],
                }))
          );
        });
    } catch (error) { }
  }
  changeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };
  changeCategory = (event) => {
    this.setState({
      category: event.target.value,
    });
  };
  changePage = (event) => {
    this.setState({
      page: event.target.value,
    });
  };
  changeTitle = (event) => {
    this.setState({
      title: event.target.value,
    });
  };
  sendMessage() {
    const adminMessage = {
      adminMessage: this.state.message,
    };
    this.state.message !== "" &&
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/ticketMessage/saveAdminMessage/${ticketDetail[2]}`,
          adminMessage,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then(
          (response) => response.status === 200 && window.location.reload()
        );
  }

  render() {
    const messagess = this.state.messages.map(
      (x) =>
        (x.userMessage !== null && (
          <label>
            {ticketDetail[3]}: {x.userMessage}
          </label>
        )) ||
        (x.adminMessage !== null && <label>Iointel: {x.adminMessage}</label>)
    );
    const { category, page } = this.state;
    return (
      <>
        <div className="row">
          <div className="col menuCol">
            <SideMenu />
          </div>
          <div className="col">
            <div className="maincontainer">
              <br />
              <br />
              <div className="row">
                <div className="col">
                  <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SUPPORT</label>
                </div>
                <div className="col-and">
                  <GetProfileImage />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <span id="lblactivation">Category</span>
                  <span className="textnumber2">*</span>
                  <select
                    className="mt-2"
                    id="customeria"
                    name="category"
                    placeholder="please select category of your problem "
                    value={this.state.category}
                    onChange={this.changeCategory}
                    required
                  >
                    <option selected>please select category of your problem</option>
                    <option value="Report a Problem">Report a Problem</option>
                    <option value="Feedback">Feedback</option>
                  </select>
                </div>
                <div className="col-5">
                  <span id="lblactivation">Page</span>
                  <select
                    id="customeria"
                    name="page"
                    className="mt-2"
                    placeholder="please select category of your problem "
                    onChange={this.changePage}
                    value={this.state.page}
                  >
                    <option selected>
                      please select category of your problem{" "}
                    </option>
                    <option value="Dashboard">Dashboard</option>
                    <option value="SIMs Management">SIMs Management</option>
                    <option value="SIM Details">SIM Details</option>
                    <option value="Customer Details">Customer Details</option>
                    <option value="Plan Selection">Plan Selection</option>
                    <option value="Summary">Summary</option>
                    <option value="Confirmation">Confirmation</option>
                    <option value="Contract Details">Contract Details</option>
                    <option value="Live Usage">Live Usage</option>
                    <option value="Add More Data">Add More Data</option>
                    <option value="SIM-CARD Order">SIM-CARD Order</option>
                    <option value="User Management">User Management</option>
                    <option value="Billing">Billing</option>
                    <option value="Pricing">Pricing</option>
                    <option value="Settings">Settings</option>
                    <option value="Support">Support</option>
                  </select>
                </div>
              </div>
              <div className="mt-2" style={{ color: "#fefefe" }}>
                <img src={requestOK} />
                <span className="ticketStatusTitle pl-1 pt-5">
                  This request submitted
                  <span className="pl-1" style={{ color: "#fefefe" }}>
                    1 day ago.
                  </span>
                  <span className="pl-1" style={{ color: "#cdccc7" }}>
                    And this request marked as
                    <select
                      className="ticketStatusDropdown ml-2 mr-2 "
                      name="status"
                      value={this.state.status}
                      onChange={this.onChangedStatus}
                    >
                      <option name="OPEN">OPEN</option>
                      <option name="SOLVED">SOLVED</option>
                    </select>
                    by iointel admins.
                  </span>
                </span>
              </div>
              <div className="pl-3">
                <div className="row pt-2">
                  <span className="ticketTitle">Title</span><span className="textnumber2">*</span>
                </div>
                <div className="row">
                  <input
                    id="titleInput"
                    className=" form-control"
                    type="text"
                    placeholder="Type your title"
                    style={{ color: "#E3E2DD", cursor: "default" }}
                    value={this.state.title}
                    onChange={this.changeTitle}
                    autoComplete="off"
                  />
                </div>
                <div className="row">
                  <label className="ticketDetails">
                    Please supply details about your request/problem
                  </label>
                </div>
                <div className="row">
                  <span
                    className="scroll"
                    id="detailsInput"
                    style={{
                      color: "#E3E2DD",
                      cursor: "default",
                      paddingLeft: "11px",
                    }}
                  >
                    {messagess}
                  </span>

                  <div className="input-group">
                    <input
                      id="titleInput"
                      className=" form-control"
                      type="text"
                      autoComplete="off"
                      value={this.state.message}
                      onChange={this.changeMessage}
                      placeholder="Type a message"
                      onKeyDown={(e) => e.keyCode === 13 && sendButton(e)}
                      style={{
                        color: "#E3E2DD",
                        borderTop: "1px solid #D3D2CD",
                        minHeight: "33px",
                      }}
                    />
                    <button
                      onClick={this.sendMessage}
                      style={{
                        backgroundColor: "rgba(227, 226, 221, 0.5)",
                        borderTop: "1px solid #D3D2CD",
                      }}
                    >
                      <img src={sendButton} />
                    </button>
                  </div>
                </div>
                <Button onClick={this.saveChanges} className="submitBtn">
                  Save Changes
                </Button>
                <Button href="/SupportIointel" className="backBtn">
                  Back
                </Button>
              </div>
              <br />
              <GetImage />
            </div>
          </div>
        </div>
      </>
    );
  }
}
