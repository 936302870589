import {
    UPDATE_USER
   } from "../actions/types";
   const initialState = JSON.parse(localStorage.getItem("updateUser"));
   export default function (state = initialState, action) {
     const { type, payload } = action;
     
   switch (type) {
     case UPDATE_USER:
       return {
         ...state,
         updateUser: payload};
         default:
         return state;
     }
 }