import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "./user.management.page.component.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSortDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Posts from "./Posts";
import Pagination from "./Pagination";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";
var userInformation = JSON.parse(localStorage.getItem("user"));

class BoardModerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      boolean: false,
      filter: "",
      posts: [],
      active: false,
      active2: false,
      active3: false,
      status: "",
      loading: false,
      currentPage: 1,
      postsPerPage: 12,
      state: "",
      customer_id: "",
      contacts: [],
    };
    this.showActives = this.showActives.bind(this);
    this.showSuspends = this.showSuspends.bind(this);
    this.showDeactivateds = this.showDeactivateds.bind(this);
  }

  componentDidMount = (e) => {
    this.showUnused();
  };

  showUnused() {
    this.setState({
      isLoading: true,
      posts: [],
    });

    fetch(
      `${process.env.REACT_APP_API_URL}/api/iccidCatalog/getUnusedIccids/dealerCode/` +
      userInformation.dealerCode,
      {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      }
    )
      .then((response) => response.json())

      .then((parsedJSON) =>
        parsedJSON.map((user) => ({
          // id: `${user.id}`,
          iccid: `${user.iccid}`,
          status: `${user.status}`,
          startDate: `${user.date}`,
        }))
      )
      .then((posts) => {
        this.setState({
          posts,
          isLoading: false,
        });
      })
      .catch((error) => console.log("parsing failed", error));
  }

  showActives() {
    this.setState({
      status: "a",
      state: "Active",
      active: true,
      active2: false,
      active3: false,
    });
    this.fetchData("a");
  }
  showSuspends() {
    this.setState({
      status: "s",
      state: "Suspend",
      active: false,
      active2: true,
      active3: false,
    });
    this.fetchData("s");
  }

  showDeactivateds() {
    this.setState({
      status: "d",
      state: "Deactive",
      active: false,
      active2: false,
      active3: true,
    });
    this.fetchData("d");
  }

  fetchData(x) {
    const payerId = {
      payerid: userInformation.dealerCode,
    };

    this.setState({
      isLoading: true,
      posts: [],
      loading: false,
    });
    fetch(`${process.env.REACT_APP_API_URL}/api/contract/simList/status/${x}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userInformation.access_token}`,
        User: `${userInformation.username}`,
      },
      body: JSON.stringify(payerId),
    })
      .then((response) => response.json())

      .then((parsedJSON) =>
        parsedJSON.map((prods) => ({
          iccid: `${prods.last_ICCID}`,
          status: `${prods.last_state}`,
          nameSurname: `${prods.responsible_person}`,
          startDate: `${prods.start_date}`.split(" ", 1),
          endDate: `${prods.end_date}`,
          customer_id: `${prods.customer_id}`,
          id: `${prods.id}`,
        }))
      )
      .then((posts) => {
        this.setState({
          posts,
          isLoading: false,
        });
      })
      .catch((error) => console.log("parsing failed", error));
  }

  searchTxt(e) {
    this.setState({ filter: e.target.value });
  }

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.posts];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ posts: arrayCopy });
  }
  remove() {
    localStorage.removeItem("iccid");
  }
  render() {
    let {
      status,
      posts,
      active,
      Datasearch,
      isLoading,
      filter,
      postsPerPage,
      currentPage,
      loading,
      boolean,
    } = this.state;

    const data = posts.filter((item) => item.nameSurname == active);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    Datasearch = currentPosts.filter((item) => {
      return Object.keys(item).some(
        (key) => item[key].includes(filter)
        // item[key].toLowerCase().includes(filter.toLocaleLowerCase())
      );
    });

    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          {userInformation.dealerType == "CleanIot" ? (<>
            <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
            <img src={GULSAH} className="cleanBg" alt="login bg frame" />
          </>) : (null)}
          <div className="maincontainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            <br />
            <br />
            <div className="row">
              <div className="col">
                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SIMs MANAGEMENT</label>
              </div>
              <div className="col-and">
                <GetProfileImage />
              </div>
            </div>
            <div className="row ">
              <div className="col statusSim">
                <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"}>Select Status</label>
                <ButtonGroup aria-label="Basic example">
                  <button
                    className={
                      userInformation.dealerType == "CleanIot" ? (this.state.active ? "activatedbtnClean" : "deactivatedbtnClean") : (this.state.active ? "activatedbtn" : "deactivatedbtn")
                    }
                    value={status}
                    onClick={() => {
                      this.showActives();
                      // this.fetchData()
                    }}
                  >
                    Active
                  </button>
                  <button
                    value={status}
                    className={
                      userInformation.dealerType == "CleanIot" ? (this.state.active2 ? "activatedbtnClean" : "deactivatedbtnClean") : (this.state.active2 ? "activatedbtn" : "deactivatedbtn")
                    }
                    onClick={() => {
                      this.showSuspends();
                    }}
                  >
                    Suspend
                  </button>
                  <button
                    value={status}
                    className={
                      userInformation.dealerType == "CleanIot" ? (this.state.active3 ? "activatedbtnClean" : "deactivatedbtnClean") : (this.state.active3 ? "activatedbtn" : "deactivatedbtn")
                    }
                    onClick={() => {
                      this.showDeactivateds();
                    }}
                  >
                    Deactive
                  </button>
                </ButtonGroup>
              </div>
              <div className="col search">
                <label className={userInformation.dealerType == "CleanIot" ? "labeltxtClean" : "labeltxt"}>Search User</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={userInformation.dealerType == "CleanIot" ? "form-control searchstyleClean" : "form-control searchstyle"}
                    placeholder="Assigned User, SIMs Number #"
                    value={filter}
                    onChange={this.searchTxt.bind(this)}
                  />
                  {userInformation.dealerType == "CleanIot" ? (<>
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        id="searchicon"
                        style={{
                          background: "#000000",
                          color: "#B1CB32",
                          borderEndEndRadius: "4px",
                          borderStartEndRadius: "4px",
                        }}
                      ><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                  </>) : (<>
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        id="searchicon"
                        style={{
                          background: "#0A1330",
                          color: "#D04102",
                          borderEndEndRadius: "4px",
                          borderStartEndRadius: "4px",
                        }}
                      ><FontAwesomeIcon icon={faSearch} /></div>
                    </div>
                  </>)}
                </div>
              </div>
              <div className="col align-items-end">
                <Button
                  href="/simActivation"
                  className={userInformation.dealerType == "CleanIot" ? "defineSimButtonClean" : "defineSimButton"}
                  onClick={() => this.remove()}
                >
                  + DEFINE SIM
                </Button>
              </div>
            </div>
            <br />
            <Posts
              sortBy={this.sortBy}
              posts={Datasearch}
              loading={loading}
              status={status}
            />
            <br />
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={posts.length}
              paginate={(number) => this.setState({ currentPage: number })}
              next={() => this.setState({ currentPage: currentPage + 1 })}
              previous={() => this.setState({ currentPage: currentPage - 1 })}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default BoardModerator;
