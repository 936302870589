// import React, { Component } from 'react';

// class ImageUpload extends Component {
//     render() {
//         return (
//             <div>
//                   <input
//             style={{ marginLeft: "2%", marginTop: "1%" }}
//             id="inputactivaton"
//             type="text"
//             name="dealerCode"
//             className="form-control"
//             placeholder="Dealer Code"
//             value={this.state.dealerCode}
//             required
//             onChange={(event) =>
//               this.handleUpdateState("dealerCode", event.target.value)
//             }
//           />
//             </div>
//         );
//     }
// }

// export default ImageUpload;
import React, { Component, useState } from "react";
import "../bulkUpload/bulkUploadStyle.css";
import FolderIcon from "../../images/add.png";
import CloseIcon from "../../images/edit.png";
import okey from "../../images/okey.png";
import axios from "axios";
import {
  Layout,
  Container,
  BoxUpload,
  ImagePreview,
} from "../imageUpload/imageIndexStyle";
import { height } from "@mui/system";
import { ProgressBar } from "react-bootstrap";

var userInformation = JSON.parse(localStorage.getItem("user"));

class ImageUpload extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: "",
      isUploaded: false,
      image: "",
      uploadPercentage: 0,
      message: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.setImage = this.setImage.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  setImage(e) {
    this.setState({
      image: e.target.result,
    });
  }

  submit() {
    const data = new FormData();
    data.append("image", this.state.selectedFile);
    console.warn(this.state.selectedFile);
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total); 

        if (percent < 100) {
          this.setState({
            uploadPercentage: percent,
          });
        }
      },
    };
    let url = `${process.env.REACT_APP_API_URL}/api/image/upload/image/dealerCode/${userInformation.dealerCode}`;

    axios
      .post(url, data, {
        options,
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then((res) => { 
        this.setState({ uploadPercentage: 100, message: res.data }, () => {
          setTimeout(() => {
            this.setState({ uploadPercentage: 0 });
          }, 1000);
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  handleUpdateState = (field = "", value = "") => {
    this.setState({ [field]: value });
  };
  render() {
    let { isUploaded, image, uploadPercentage, message } = this.state;
    return (
      <div className="content" style={{ width: "95%" }}>
        <div className="row">
          <label
            for="file-upload"
            className="custom-file-upload btn ml-3 pr-4 "
          >
            <div className="image-upload">
              <label htmlFor="upload-input">
                <img
                  src={FolderIcon}
                  draggable={"false"}
                  alt="placeholder"
                  style={{ cursor: "pointer" }}
                />
              </label>

              <input
                id="upload-input"
                type="file"
                accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                onChange={this.handleInputChange}
              />
            </div>
          </label>
        </div>
        {uploadPercentage > 0 && (
          <ProgressBar
            now={uploadPercentage}
            active
            label={`${uploadPercentage}%`}
          />
        )}
        <div className="row ml-2">
          {this.state.selectedFile.name && (
            <p style={{ color: "#fefefe" }}>
              Your selected file: {this.state.selectedFile.name}
            </p>
          )}
        </div>
        {message && <p>{message}</p>}
        <div className="row pt-0 mt-0 ml-2">
          <button onClick={() => this.submit()} className=" uploadimage">
            Upload <img className="ml-1" src={okey} alt="okey Logo" />
          </button>
        </div>
      </div>
    );
  }
}
export default ImageUpload;
