import React, { Component } from 'react'
import bg from "../../images/bg_sfw.jpg";
// import pricingtable from "../../images/piricing_table.svg";
import pricingScreen from "../../images/pricing_screen.png";
import SideMenu from "../../SideMenu";
import GetProfileImage from '../getProfileImage/GetProfileImage';

var userInformation = JSON.parse(localStorage.getItem("user"));

class PricingPage extends Component {
    render() {
        return (
            <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
            <div className="maincontainer">
                <img src={bg} id="bg" alt="Background Image" />
                <br />
                <br />
                <div className="row">
                    <div className="col">
                        <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>PRICING</label>
                    </div>
                    <div className="col-and">
                        <GetProfileImage />
                    </div>
                </div>
                <div className="row">
                    {/* <img src={pricingtable} alt="Pricing Table Image" /> */}
                    <img
                        style={{ width: "100%", marginTop:"2%" }}
                        src={pricingScreen}
                        alt="Pricing Table Image"
                    />
                </div>
            </div>
            </div>
            </div>
        )
    }
}
export default PricingPage;