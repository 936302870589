import React, { Component } from "react";
import "./simActivationStyle.css";

class ProductsBigCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateA: false,
      stateB: false,
      stateC: false,
      stateD: false,
      stateE: false,
      stateF: false,
      tariffId: "",
      status: "",
    };
  }
  setStateA() {
    this.setState({
      stateA: true,
      stateB: false,
      stateC: false,
      stateD: false,
      stateE: false,
      stateF: false,
    });
  }
  setStateB() {
    this.setState({
      stateA: false,
      stateB: true,
      stateC: false,
      stateD: false,
      stateE: false,
      stateF: false,
    });
  }
  setStateC() {
    this.setState({
      stateA: false,
      stateB: false,
      stateC: true,
      stateD: false,
      stateE: false,
      stateF: false,
    });
  }
  setStateD() {
    this.setState({
      stateA: false,
      stateB: false,
      stateC: false,
      stateD: true,
      stateE: false,
      stateF: false,
    });
  }
  setStateF() {
    this.setState({
      stateA: false,
      stateB: false,
      stateC: false,
      stateD: false,
      stateE: false,
      stateF: true,
    });
  }
  changeSelcet() {
    this.setState({
      stateA: false,
      stateB: false,
      stateC: false,
      stateD: false,
      stateE: true,
      stateF: false,
    });
  }
  render() {
    return (
      <div>
        <div>

          {this.props.simType === "CCIOT" ? (
            <>
              <div className="row" style={{ marginLeft: "0%" }}>
                <label id="lblactivation">Available Data Plan</label>
                <div style={{ marginLeft: "-8%" }}>
                  <div key={this.props.item[0].id}>
                    <div
                      className="col"
                      style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "32%", marginLeft: "0%" }}
                    >
                      <button
                        className="dataNBClkBtn"
                        value={this.props.item[0].repeatingProductId}
                        onClick={(e) => {
                          this.props.setRepeatingProductId(
                            e.target.value
                          );
                          this.props.setTariffId(
                            this.props.item[0].tariffId
                          );
                          this.props.setRepeatingProductDescription(
                            this.props.item[0].description
                          );
                          this.setStateA();
                        }}
                      >
                        {this.props.item[0].description}
                        <span
                          style={{
                            fontStyle: "italic",
                            fontWeight: "400",
                            fontSize: "13.223px",
                            lineHeight: "15px",
                            textAlign: "right",
                            paddingTop: "10%",
                          }}
                        >${this.props.item[0].charge}/mo</span>
                      </button>
                      {/* <div className="card">
                      <div
                        className={this.state.stateA ? "cordoClk" : "cardo"}
                        style={{ background: "#0A1330" }}
                      >
                        <div>
                          <div className="row justify-content-start">
                            <div className="col">
                              <label
                                id="numbernet"
                                style={{ paddingLeft: "15px" }}
                              >
                                {this.props.item[0].description}
                              </label>
                            </div>
                            <label
                              id="money"
                              style={{
                                paddingLeft: "15px",
                              }}
                            >${this.props.item[0].charge}/mo</label>
                          </div>
                          <div className="row justify-content-center">
                            <button
                              className={
                                this.state.stateA ? "selectClkBtn" : "selectBtn"
                              }
                              value={this.props.item[0].repeatingProductId}
                              onClick={(e) => {
                                this.props.setRepeatingProductId(
                                  e.target.value
                                );
                                this.props.setTariffId(
                                  this.props.item[0].tariffId
                                );
                                this.props.setRepeatingProductDescription(
                                  this.props.item[0].description
                                );
                                this.setStateA();
                              }}
                            >
                              {this.state.stateA ? "Selected" : "Select"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <label id="lblactivation">Top Picks By Dealers</label>
              <div
                style={{ paddingRight: "8%" }}
                className="row justify-content-center">
                <div className="row" style={{marginTop:"-38px"}}>
                  <div>
                    <div key={this.props.item[0].id}>
                      <div
                        className="col"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="card">
                          <div
                            className={this.state.stateA ? "cordoClk" : "cardo"}
                            style={{ background: "#0A1330" }}
                          >
                            <div>
                              <div className="row justify-content-start">
                                <div className="col">
                                  <label
                                    id="numbernet"
                                  >
                                    {this.props.item[0].description}
                                  </label>
                                  <label
                                    id="money"
                                  >${this.props.item[0].charge}/mo</label>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <button
                                  className={
                                    this.state.stateA
                                      ? "selectClkBtn"
                                      : "selectBtn"
                                  }
                                  value={this.props.item[0].repeatingProductId}
                                  onClick={(e) => {
                                    this.props.setRepeatingProductId(
                                      e.target.value
                                    );
                                    this.props.setTariffId(
                                      this.props.item[0].tariffId
                                    );
                                    this.props.setRepeatingProductDescription(
                                      this.props.item[0].description
                                    );
                                    this.setStateA();
                                  }}
                                >
                                  {this.state.stateA ? "Selected" : "Select"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div key={this.props.item[1].id}>
                      <div
                        className="col"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="card">
                          <div
                            className={this.state.stateB ? "cordoClk" : "cardt"}
                            style={{ background: "#181727" }}
                          >
                            <div>
                              <div className="row justify-content-start">
                                <div className="col">
                                  <label
                                    id="numbernet"
                                  >
                                    {this.props.item[1].description}
                                  </label>
                                  <label
                                    id="money"
                                  >${this.props.item[1].charge}/mo</label>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <button
                                  // id="selectbtn"
                                  // type="button"
                                  // className="btn m-4"
                                  className={
                                    this.state.stateB
                                      ? "selectClkBtn"
                                      : "selectBtn"
                                  }
                                  value={this.props.item[1].repeatingProductId}
                                  onClick={(e) => {
                                    this.props.setRepeatingProductId(
                                      e.target.value
                                    );
                                    this.props.setTariffId(
                                      this.props.item[1].tariffId
                                    );

                                    this.props.setRepeatingProductDescription(
                                      this.props.item[1].description
                                    );
                                    this.setStateB();
                                  }}
                                >
                                  {this.state.stateB ? "Selected" : "Select"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div key={this.props.item[2].id}>
                      <div
                        className="col"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="card">
                          <div
                            className={this.state.stateC ? "cordoClk" : "cardtr"}
                            style={{ background: "#DB4401" }}
                          >
                            <div>
                              <div className="row justify-content-start">
                                <div className="col">
                                  <label
                                    id="numbernet"
                                  >
                                    {this.props.item[2].description}
                                  </label>
                                  <label
                                    id="money"
                                  >${this.props.item[2].charge}/mo</label>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <button
                                  className={
                                    this.state.stateC
                                      ? "selectClkBtn"
                                      : "selectedBtn"
                                  }
                                  value={this.props.item[2].repeatingProductId}
                                  onClick={(e) => {
                                    this.props.setRepeatingProductId(
                                      e.target.value
                                    );
                                    this.props.setTariffId(
                                      this.props.item[2].tariffId
                                    );

                                    this.props.setRepeatingProductDescription(
                                      this.props.item[2].description
                                    );
                                    this.setStateC();
                                  }}
                                >
                                  {this.state.stateC ? "Selected" : "Select"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div key={this.props.item[3].id}>
                      <div
                        className="col"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="card">
                          <div
                            className={this.state.stateD ? "cordoClk" : "cardf"}
                            style={{ background: "#D3D2CD" }}
                          >
                            <div>
                              <div className="row justify-content-start">
                                <div className="col">
                                  <label
                                    id="numbernet"
                                    style={{
                                      color: "#0A1330",
                                    }}
                                  >
                                    {this.props.item[3].description}
                                  </label>
                                  <label
                                    id="money"
                                    style={{
                                      color: "#0A1330",
                                    }}
                                  >${this.props.item[3].charge}/mo</label>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <button
                                  className={
                                    this.state.stateD
                                      ? "selectClkBtn"
                                      : "selectBtn"
                                  }
                                  value={this.props.item[3].repeatingProductId}
                                  onClick={(e) => {
                                    this.props.setRepeatingProductId(
                                      e.target.value
                                    );
                                    this.props.setTariffId(
                                      this.props.item[3].tariffId
                                    );

                                    this.props.setRepeatingProductDescription(
                                      this.props.item[3].description
                                    );
                                    this.setStateD();
                                  }}
                                >
                                  {this.state.stateD ? "Selected" : "Select"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div key={this.props.item[4].id}>
                      <div
                        className="col"
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <div className="card">
                          <div
                            className={this.state.stateF ? "cordoClk" : "cardg"}
                            style={{ background: "#53788D" }}
                          >
                            <div>
                              <div className="row justify-content-start">
                                <div className="col">
                                  <label
                                    id="numbernet"
                                    style={{
                                      color: "#D3D2CD",
                                    }}
                                  >
                                    {this.props.item[4].description}
                                  </label>
                                  <label
                                    id="money"
                                    style={{
                                      color: "#D3D2CD",
                                    }}
                                  >${this.props.item[4].charge}/mo</label>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <button
                                  className={
                                    this.state.stateF
                                      ? "selectClkBtn"
                                      : "selected2Btn"
                                  }
                                  value={this.props.item[4].repeatingProductId}
                                  onClick={(e) => {
                                    this.props.setRepeatingProductId(
                                      e.target.value
                                    );
                                    this.props.setTariffId(
                                      this.props.item[4].tariffId
                                    );

                                    this.props.setRepeatingProductDescription(
                                      this.props.item[4].description
                                    );
                                    this.setStateF();
                                  }}
                                >
                                  {this.state.stateF ? "Selected" : "Select"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="lblDataPlan">All Data Plans</label>
                <br />
                {/* <div className="container justify-content-center"> */}
                  <div
                    className="row d-flex row-cols-8 justify-content-center"
                  style={{ padding: "0% 28.8% 2% 21%" }}
                  // style={{ paddingLeft:"20%",alignItems:"center", maxWidth:"800px"}}
                  >
                    {/* <div className="col-md-4 col-lg-3"> */}
                    {this.props.item.map((r) =>
                      r.repeatingProductId > 15 ? (
                        <div
                          // className="row-cols-6"
                          style={{
                            margin: "5px",
                            marginBottom: "15px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                          }}
                        >
                          <div>
                            <div key={r.accumulatorId}>
                              <button
                                className={
                                  this.state.stateE ? "dataBtn" : "dataBtn"
                                }
                                value={r.repeatingProductId}
                                onClick={(e) => {
                                  this.props.setRepeatingProductId(
                                    r.repeatingProductId
                                  );
                                  this.props.setTariffId(r.tariffId);

                                  this.props.setRepeatingProductDescription(
                                    r.description
                                  );
                                  this.changeSelcet();
                                }}
                              >
                                <div className="col-start">{r.description}</div>
                                <div className="col-and">
                                <span
                                  className="rightMoney"
                                  style={{
                                    fontStyle: "italic",
                                    fontWeight: "400",
                                    fontSize: "14.423px",
                                    lineHeight: "15px",
                                    textAlign: "right",
                                    paddingTop: "10%",
                                  }}
                                >${r.charge}/mo</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                    {/* </div> */}
                  </div>
                {/* </div> */}

              </div>

            </>
          )}

        </div>
      </div>
    );
  }
}
export default ProductsBigCard;
