import React, { Component } from 'react';
import GetProfileImage from '../getProfileImage/GetProfileImage';
import IointelTotalDoughtnutChart from './IointelTotalDoughtnutChart';
import SideMenu from "../../SideMenu";

var userInformation = JSON.parse(localStorage.getItem("user"));

class IointelDashboard extends Component {
    render() {
        return (
            <div className="row">
                <div className="col menuCol">
                    <SideMenu />
                </div>
                <div className="col">
                    <div className="content" style={{ width: "95%" }}>
                        <br />
                        <br />
                        <div className="row">
                            <div className="col">
                                <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>IOINTEL DASHBOARD</label>
                            </div>
                            <div className="col-and">
                                <GetProfileImage />
                            </div>
                        </div>
                        <div className="row">
                            <IointelTotalDoughtnutChart />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default IointelDashboard;