import jdLogo from "../images/jdLogo.png";
import React, { Component } from "react";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
// import AlertDialogSlide from "./imageUpload/ImageAdd.js";
import ImageIndex from "./imageUpload/ImageIndex";
import { Link } from "react-router-dom";
import axios from "axios";
import Lottie from "react-lottie";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as location from "../1055-world-locations.json";
import * as success from "../1127-success.json";
import ImageUpload from "./imageUploadd/ImageUpload";
import GetProfileImage from "./getProfileImage/GetProfileImage";
import bg from "../images/bgNew.png";
import cleanLoginBg from "../images/beyazZemin.png";
import GULSAH from "../images/GULSAH.png";
import SideMenu from "../SideMenu";

// import Dialog from "./Dialog";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vcity = (value) => {
  // var illegalChars = /\W/; // allow letters, numbers, and underscores
  if (!/^[a-zA-Z\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid city.
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vphone = (value) => {
  var illegalChars = /(\d)\1{2}\1{3}\1{4}/;
  if (value.length < 9 || value.length > 12) {
    return (
      <div className="alert alert-danger" role="alert">
        The phone must be between 9 and 12 characters.
      </div>
    );
  } else if (illegalChars.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid phone.
      </div>
    );
  }
};

const vcompany = (value) => {
  if (!/^[0-9a-zA-Z\s.-]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid company name.
      </div>
    );
  }
};

const vzipCode = (value) => {
  if (value.length !== 5) {
    return (
      <div className="alert alert-danger" role="alert">
        The zipcode must be 5 characters.
      </div>
    );
  } else if (!/^\d{5}(?:[-\s]\d{4})?$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid zipcode.
      </div>
    );
  }
};

const vaddressLineOne = (value) => {
  if (!/^[a-zA-Z0-9-_.\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid address line 1.
      </div>
    );
  }
};
const vaddressLineTwo = (value) => {
  if (!/^[a-zA-Z0-9-_.\s]*$/i.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter a valid address line 2.
      </div>
    );
  }
};
var userInformation = JSON.parse(localStorage.getItem("user"));
var zip = "";
let a;

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      obj: {
        username: "",
        phone: "",
        country: "USA",
        state: "",
        city: "",
        zipcode: "",
        addressLine1: "",
        addressLine2: "",
        name: "",
        department: "",
        enabled: null,
        dealerCode: "",
        role: "",
      },
      isVisible: false,
      loading: false,
      errorMessage: false,
      zip: "",
      z: "",
      fullName: "",
      email: "",
      phone: "",
      open: false,
      boolean: false,
    };
    // this.changeValue = this.changeValue.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onInputchange(event) {
    if (userInformation.role === "ROLE_ADMIN") {
      this.setState((x) => ({
        ...x,
        obj: {
          ...x.obj,
          [event.target.name]: event.target.value,
        },
      }));
    } else {
      this.setState(() => ({
        [event.target.name]: event.target.value,
      }));
    }
  }

  handleUpdateState = (field = "", value = "") => {
    this.setState({ [field]: value });
  };

  // ${process.env.REACT_APP_API_URL}/api/user/getUser/username/${userInformation.username}
  componentDidMount() {
    if (userInformation.role === "ROLE_ADMIN") {
      this.fetchAdminData();
    } else {
      this.fetchModAndUserData();
    }
  }

  fetchModAndUserData() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/user/getUser/username/${userInformation.username}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((res) => {

        this.setState({
          fullName: res.data.name,
          email: res.data.username,
          phone: res.data.phone,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchAdminData() {
    var data = {
      custid: userInformation.dealerCode,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/customer/getCustomerDetailIointel`,
      headers: {
        Authorization: `Bearer ${userInformation.access_token}`,
        User: `${userInformation.username}`,
      },

      data: data,
    };
    axios(config)
      .then(
        function (response) {
          this.setState({
            obj: response.data[0],
            z: response.data[0].invoice_zipcode,
          });
          zip = response.data[0].invoice_zipcode;
        }.bind(this)
      )

      .catch(function (error) {
        console.log(error);
      });
  }

  saveChanges(e) {
    if (this.state.z !== this.state.obj.invoice_zipcode) {
      a = this.state.obj.invoice_zipcode;
      this.handleClickOpen();
    } else {
      a = this.state.z;
      this.submitChanges();
    }
  }

  submitChanges() {
    const data = {
      invoicename: this.state.obj.invoice_name,
      email: this.state.obj.email,
      contactphone: this.state.obj.contact_phone,
      invoicestateofaddress: this.state.obj.invoice_state_of_address,
      invoicecity: this.state.obj.invoice_city,
      invoicezipcode: a,
      invoiceaddressline1: this.state.obj.invoice_address_line_1,
      invoiceaddressline2: this.state.obj.invoice_address_line_2,
      custid: userInformation.dealerCode,
      invoicecountry: "USA",
    };
    // console.log(data);
    this.setState({
      isVisible: true,
      errorMessage: false,
    });
    this.handleClose();

    setTimeout(() => {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/customer/addCustomerDetailIointel`,
          data,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            isVisible: true,
            errorMessage: false,
            loading: true,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 4000);
        })

        .catch((err) => {
          console.error(err);
          this.setState({
            isVisible: false,
            errorMessage: err.response.data.message,
          });
          alert(err);
        });
    }, 2000);
  }
  saveModAndUserChanges() {
    this.setState({
      isVisible: true,
      errorMessage: false,
    });

    const data = {
      username: this.state.email,
      name: this.state.fullName,
      department: userInformation.department,
      role: userInformation.role,
      phone: this.state.phone,
      enabled: userInformation.isActive ? true : false,
    };
    setTimeout(() => {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/user/updateUser/username/${userInformation.username}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${userInformation.access_token}`,
              User: `${userInformation.username}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            isVisible: true,
            errorMessage: false,
            loading: true,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 4000);
        })

        .catch((err) => {
          console.error(err);
          this.setState({
            isVisible: false,
            errorMessage: err.response.data.message,
          });
          alert(err);
        });
    }, 2000);
  }

  onSubmit(e) {
    e.preventDefault();
  }
  render() {
    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: location.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: success.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    let { boolean, } = this.state;
    return (
      <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
          <div className="maincontainer">
            {userInformation.dealerType == "CleanIot" ? (
              <>
                <img src={cleanLoginBg} className="cleanBg" alt="login bg" />
                <img src={GULSAH} className="cleanBg" alt="login bg frame" />
              </>
            ) : (
              <>
                <img src={bg} id="bg" alt="Background Image" />
              </>
            )}
            {userInformation.role === "ROLE_ADMIN" ? (
              <>
                <br />
                <Dialog
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title"></DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      If you change the zip code, your billing tax will be
                      calculated based on that zip code.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose}>NO</Button>
                    <Button onClick={() => this.submitChanges()}>YES</Button>
                  </DialogActions>
                </Dialog>
                <br />
                <div className="row">
                  <div className="col">
                    <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>EDIT PROFILE</label>
                    {this.state.errorMessage && (
                      <h1> {this.state.errorMessage} </h1>
                    )}
                  </div>
                  <div className="col-and">
                    <GetProfileImage />
                  </div>
                </div>
                <div id="lblactivation">Company Logo</div>
                <div className="row ml-0">
                  {/* <AlertDialogSlide onSelected={(img) => console.log("image", img)} /> */}
                  {/* <ImageIndex /> */}
                  <ImageUpload />
                </div>
                <Form
                  onSubmit={this.onSubmit}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  <div className="ml-0" style={{ paddingLeft: "0%" }}>
                    <br />
                    <div className="form-group">
                      <div className="container ml-0" style={{ paddingLeft: "0%" }}>
                        <div className="row">
                          <div className="col-6">
                            <label id="lblactivation" value={this.state.obj}>
                              Company Name{" "}
                            </label>
                            <Input
                              id="customera"
                              type="text"
                              name="invoice_name"
                              className="form-control"
                              placeholder="Company name"
                              value={this.state.obj.invoice_name}
                              onChange={this.onInputchange}
                              validations={[vcompany]}
                            ></Input>
                          </div>
                          <div className="col-3">
                            <label id="lblactivation">
                              Zipcode <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customeria"
                              type="text"
                              name="invoice_zipcode"
                              className="form-control"
                              placeholder="please type"
                              value={this.state.obj.invoice_zipcode}
                              onChange={this.onInputchange}
                              validations={[required, vzipCode]}
                            />
                          </div>
                          <div className="col-3">
                            <button className="changePassword">
                              <Link to={"/ChangePassword"}>Change Password</Link>
                            </button>
                          </div>
                          <div className="w-100"></div>
                          <br />
                          <br />
                          <br />
                          <div className="col-6">
                            <label id="lblactivation">
                              E-Mail <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customera"
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="E-mail address"
                              value={this.state.obj.email}
                              onChange={this.onInputchange}
                              // value={this.state.email}
                              // onChange={(event) =>
                              //   this.setState({
                              //     email: event.target.value,
                              //   })
                              // }
                              validations={[required, email]}
                            />
                          </div>
                          <div className="col-6">
                            <label id="lblactivation">
                              Phone <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customera"
                              type="text"
                              name="contact_phone"
                              className="form-control"
                              placeholder="Mobile or static phone number"
                              value={this.state.obj.contact_phone}
                              onChange={this.onInputchange}
                              validations={[required, vphone]}
                            />
                          </div>
                          <div className="w-100"></div>
                          <br />
                          {/* <div className="container">
                        <div className="row"> */}
                          <div className="col-3">
                            <label id="lblactivation">Country</label>
                            <Input
                              id="customeria"
                              style={{ marginTop: "6%" }}
                              type="text"
                              name="invoice_country"
                              className="form-control"
                              // placeholder="United States of America"
                              value={this.state.obj.invoice_country}
                              disabled
                            />
                          </div>
                          <div className="col-3">
                            <label id="lblactivation">
                              State <span className="textnumber2">*</span>
                            </label>
                            <select
                              id="customeria"
                              name="invoice_state_of_address"
                              // value={this.state.role}
                              // onChange={this.onChangedRole}
                              // validations={[required, vrole]}
                              placeholder="please select"
                              value={this.state.obj.invoice_state_of_address}
                              onChange={this.onInputchange}
                            >
                              <option selected>Select State</option>
                              <option>ALABAMA </option>
                              <option>ALASKA</option>
                              <option>ALBERTA</option>
                              <option>AMERICAN SAMOA</option>
                              <option>ARIZONA</option>
                              <option>ARKANSAS</option>
                              <option>BRITISH COLUMB </option>
                              <option>CALIFORNIA</option>
                              <option>COLORADO</option>
                              <option>CONNECTICUT</option>
                              <option>DELAWARE</option>
                              <option>FLORIDA</option>
                              <option>FOREIGN</option>
                              <option>GEORGIA</option>
                              <option>GUAM</option>
                              <option>HAWAII</option>
                              <option>IDAHO</option>
                              <option>ILLINOIS</option>
                              <option>INDIANA</option>
                              <option>IOWA</option>
                              <option>KANSAS</option>
                              <option>KENTUCKY</option>
                              <option>LOUISIANA</option>
                              <option>MAINE</option>
                              <option>MANITOBA</option>
                              <option>MARYLAND</option>
                              <option>MASSACHUSETTS</option>
                              <option>MICHIGAN</option>
                              <option>MINNESOTA</option>
                              <option>MISSISSIPPI</option>
                              <option>MISSOURI</option>
                              <option>MONTANA</option>
                              <option>N. MARIANA ISL</option>
                              <option>NEBRASKA</option>
                              <option>NEVADA</option>
                              <option>NEW BRUNSWICK</option>
                              <option>NEW HAMPSHIRE</option>
                              <option>NEW JERSEY</option>
                              <option>NEW MEXICO</option>
                              <option>NEW YORK</option>
                              <option>NEWFOUNDLAND</option>
                              <option>NORTH CAROLINA</option>
                              <option>NORTH DAKOTA</option>
                              <option>NORTHWEST TERR</option>
                              <option>NOVA SCOTIA</option>
                              <option>NUNAVUT</option>
                              <option>OHIO</option>
                              <option>OKLAHOMA</option>
                              <option>ONTARIO</option>
                              <option>OREGON</option>
                              <option>PENNSYLVANIA</option>
                              <option>PRINCE EDWARD</option>
                              <option>PUERTO RICO</option>
                              <option>QUEBEC</option>
                              <option>RHODE ISLAND</option>
                              <option>SASKATCHEWAN</option>
                              <option>SOUTH CAROLINA</option>
                              <option>SOUTH DAKOTA</option>
                              <option>TENNESSEE</option>
                              <option>TEXAS</option>
                              <option>UTAH</option>
                              <option>VERMONT</option>
                              <option>VIRGIN INLANDS</option>
                              <option>VIRGINIA</option>
                              <option>WASHINGTON</option>
                              <option>WASHINGTON D.C.</option>
                              <option>WEST VIRGINIA</option>
                              <option>WISCONSIN</option>
                              <option>YUKON TERR</option>
                            </select>
                          </div>
                          <div className="col-6">
                            <label id="lblactivation">
                              City <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customera"
                              style={{ marginTop: "2.1%" }}
                              type="text"
                              name="invoice_city"
                              className="form-control"
                              placeholder="please type"
                              value={this.state.obj.invoice_city}
                              onChange={this.onInputchange}
                              validations={[required, vcity]}
                            />
                          </div>
                          <div className="w-100"></div>
                          <br />
                          <div className="col-6">
                            <label id="lblactivation">
                              Address Line 1 <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customera"
                              type="text"
                              name="invoice_address_line_1"
                              className="form-control"
                              placeholder="Business or residental address"
                              value={this.state.obj.invoice_address_line_1}
                              onChange={this.onInputchange}
                              validations={[required, vaddressLineOne]}
                            />
                          </div>
                          <div className="col-6">
                            <label id="lblactivation">Address Line 2</label>
                            <Input
                              id="customera"
                              type="text"
                              name="invoice_address_line_2"
                              className="form-control"
                              placeholder="Business or residental address"
                              value={this.state.obj.invoice_address_line_2}
                              onChange={this.onInputchange}
                              validations={[vaddressLineTwo]}
                            />
                          </div>
                        </div>
                        {/* </div>
                    </div> */}
                      </div>
                      <br />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      userInformation.role === "ROLE_ADMIN"
                        ? this.saveChanges()
                        : this.saveModAndUserChanges();
                    }}
                    id="saveChangesBtn"
                    className="btn mt-5"
                  >
                    Save Changes
                  </button>
                </Form>
              </>
            ) : (
              <>
                <br />
                <br />
                <div className="row">
                  <div className="col">
                    <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>EDIT PROFILE</label>
                    {this.state.errorMessage && (
                      <h1> {this.state.errorMessage} </h1>
                    )}
                  </div>
                  <div className="col-and">
                    <GetProfileImage />
                  </div>
                </div>

                <div className="row ml-0"></div>
                <Form
                  onSubmit={this.onSubmit}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  <div className="ml-0" style={{ paddingLeft: "0%" }}>
                    <br />
                    <div className="form-group">
                      <div className="container ml-0" style={{ paddingLeft: "0%" }}>
                        <div className="row">
                          <div className="col">
                            <label id="lblactivation" value={this.state}>
                              User Name
                            </label>
                            <Input
                              id="customera"
                              type="text"
                              name="invoice_name"
                              className="form-control"
                              placeholder="Name Surname"
                              value={this.state.fullName}
                              onChange={(event) =>
                                this.handleUpdateState(
                                  "fullName",
                                  event.target.value
                                )
                              }
                              validations={[vcompany]}
                            ></Input>
                          </div>
                          <div className="col">
                            <button className="changePassword2">
                              <Link to={"/ChangePassword"}>Change Password</Link>
                            </button>
                          </div>
                          <div className="w-100"></div>
                          <br />
                          <br />
                          <div className="col">
                            <label id="lblactivation">
                              E-Mail <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customera"
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="E-mail address"
                              value={this.state.email}
                              onChange={(event) =>
                                this.handleUpdateState("email", event.target.value)
                              }
                              validations={[required, email]}
                            />
                          </div>
                          <div className="col">
                            <label id="lblactivation">
                              Phone <span className="textnumber2">*</span>
                            </label>
                            <Input
                              id="customera"
                              type="text"
                              name="contact_phone"
                              className="form-control"
                              placeholder="Mobile or static phone number"
                              value={this.state.phone}
                              onChange={(event) =>
                                this.handleUpdateState("phone", event.target.value)
                              }
                              validations={[required, vphone]}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                  {/* {console.log(this.state)} */}
                  <button
                    onClick={() => {
                      userInformation.role === "ROLE_ADMIN"
                        ? this.saveChanges()
                        : this.saveModAndUserChanges();
                    }}
                    id="saveChangesBtn"
                    className="btn mt-5"
                  >
                    Save Changes
                  </button>
                </Form>
              </>
            )}
            {!this.state.isVisible ? (
              <></>
            ) : (
              <>
                {true ? (
                  <>
                    {!this.state.loading ? (
                      <Lottie options={defaultOptions1} height={200} width={200} />
                    ) : (
                      <Lottie options={defaultOptions2} height={100} width={100} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Settings;
