import React, { useState } from "react";

import "./user.management.page.component.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import action from "../images/action.png";
import actionClean from "../images/actionClean.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Lottie from "react-lottie";
import * as location from "../1055-world-locations.json";
import * as success from "../1127-success.json";
import Button from "@mui/material/Button";
import dateFormat, { masks } from "dateformat";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
var userInformation = JSON.parse(localStorage.getItem("user"));

const Posts = ({ posts, loading, status, sortBy }) => {
  const [contractDetails, setContractDetails] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [completed, setcompleted] = useState(false);
  const [loadings, setLoadings] = useState();
  const [open, setOpen] = useState(false);
  const [agree, setAgree] = useState(false);
  const [boolean, setboolean] = useState(false);
  const [x, setX] = useState();
  const [id, setId] = useState();
  const [data, setData] = useState(posts);
  const [order, setOrder] = useState("ASC");
  const [number, setNumber] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  };
  const sortingNumber = (col) => {
    setNumber(
      posts.sort(function (a, b) {
        return a - b;
      })
    );
  };

  const submit = () => {
    const newState = {
      contractid: id,
      newstate: x,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/contract/changeState`, newState, {
        headers: {
          Authorization: `Bearer ${userInformation.access_token}`,
          User: `${userInformation.username}`,
        },
      })
      .then(handleClose());
  };
  const changeStatus = (x, id) => {
    setX(x);
    setId(id);
    setOpen(true);
  };
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const a = (post) => {
    setContractDetails(post);
    localStorage.setItem("testObject", JSON.stringify(post));

    // localStorage.setItem('refresh', "1");
  };
  if (loading) {
    return <h2>Loading...</h2>;
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <table className={userInformation.dealerType == "CleanIot" ? "aClean" : "a"}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {x === "s"
                ? "Do you want to suspend this Simcard?"
                : x === "d"
                  ? "Do you want to deactivate this Simcard?"
                  : "Do you want to reactivate this Simcard?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NO</Button>
            <Button
              onClick={() => {
                submit();
              }}
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
        <thead className={userInformation.dealerType == "CleanIot" ? "theadClean" : "thead"}>
          <tr>
            <th
              scope="col"
              style={{ cursor: "pointer" }}
              onClick={() => sorting("iccid")}
            >
              SIM Number
              {/* <button className="btn" style={{ color: "#A4A6A7" }}>
                <FontAwesomeIcon icon={faSortDown} />
              </button> */}
            </th>
            <th scope="col">Status</th>
            <th
              scope="col"
              style={{ cursor: "pointer" }}
              onClick={() => sorting("nameSurname")}
            >
              Assigned User
              <button className="btn" style={{ color: "#A4A6A7" }}>
                <FontAwesomeIcon icon={faSortDown} />
              </button>
            </th>
            <th
              scope="col"
              style={{ cursor: "pointer" }}
              onClick={() => sorting("startDate")}
            >
              Date Assigned
              {/* <button className="btn" style={{ color: "#A4A6A7" }}>
                <FontAwesomeIcon icon={faSortDown} />
              </button> */}
            </th>
            <th
              scope="col"
              style={{ cursor: "pointer" }}
              onClick={() => sorting("endDate")}
            >
              Activation End
              {/* <button className="btn" style={{ color: "#A4A6A7" }}>
                <FontAwesomeIcon icon={faSortDown} />
              </button> */}
            </th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody id="tablebody">
          {posts.map((post) => (
            <tr key={post.id} className="border_bottom">
              <td>{post.iccid === "null" ? "-" : post.iccid}</td>
              <td>{post.status === "null" ? "-" : post.status}</td>
              <td>{post.nameSurname === undefined ? "-" : post.nameSurname}</td>
              <td>
                {post.status === "Unused"
                  ? "-"
                  : post.startDate === "null"
                    ? "-"
                    : dateFormat(post.startDate, "mm-dd-yyyy")}
              </td>
              <td>
                {post.status === "Deactivate"
                  ? dateFormat(post.endDate, "mm-dd-yyyy")
                  : "-"}
              </td>
              <td>
                <div className="btn-group dropup">
                  <button
                    id="dropupbtn"
                    type="button"
                    className="btn"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userInformation.dealerType == "CleanIot" ? (<img src={actionClean} alt="Action Logo" />) : (<img src={action} alt="Action Logo" />)}
                  </button>
                  <ul className="dropdown-menu">
                    {post.status === "Unused" && (
                      <a href="/simActivation">
                        <li
                          onClick={() => {
                            localStorage.setItem(
                              "iccid",
                              JSON.stringify(post.iccid)
                            );
                          }}
                          style={{ borderBottom: "1px solid #D3D2CD" }}
                          className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                        >
                          Activate
                        </li>
                      </a>
                    )}
                    {status === "a" && (
                      <>
                        <a href="/ContractDetails">
                          <li
                            onClick={() => a(post)}
                            style={{ borderBottom: "1px solid #D3D2CD" }}
                            className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                          >
                            Contract Details
                          </li>
                        </a>
                        <li
                          className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                          onClick={() => changeStatus("s", post.id)}
                        >
                          Suspend
                        </li>

                        <li
                          className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                          onClick={() => changeStatus("d", post.id)}
                        >
                          Deactive
                        </li>
                      </>
                    )}
                    {status === "s" && (
                      <>
                        <a href="/ContractDetails">
                          <li
                            onClick={() => a(post)}
                            style={{ borderBottom: "1px solid #D3D2CD" }}
                            className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                          >
                            Contract Details
                          </li>
                        </a>
                        <li
                          className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                          onClick={() => changeStatus("a", post.id)}
                        >
                          Active
                        </li>
                        <li
                          className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                          onClick={() => changeStatus("d", post.id)}
                        >
                          Deactive
                        </li>
                      </>
                    )}
                    {status === "d" && (
                      <a href="/ContractDetails">
                        <li
                          onClick={() => a(post)}
                          style={{ borderBottom: "1px solid #D3D2CD" }}
                          className={userInformation.dealerType == "CleanIot" ? "updateButtonClean" : "updateButton"}
                        >
                          Contract Details
                        </li>
                      </a>
                    )}
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!isVisible ? (
        <></>
      ) : (
        <>
          {true ? (
            <>
              {!loading ? (
                <Lottie options={defaultOptions1} height={200} width={200} />
              ) : (
                <Lottie options={defaultOptions2} height={100} width={100} />
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default Posts;
