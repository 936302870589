import React from 'react';
import GetProfileImage from '../../../components/getProfileImage/GetProfileImage';
// import { TicketsCounterSuperAdmin } from './TicketsCounterSuperAdmin';
import TicketTableSuperAdmin from './TicketTableSuperAdmin';
import SideMenu from "../../../SideMenu";
var userInformation = JSON.parse(localStorage.getItem("user"));

export const UserTicketsPage = () => {
    return (
        <div className="row">
        <div className="col menuCol">
          <SideMenu />
        </div>
        <div className="col">
        <div className="content" style={{ width: "95%" }}>
            <br />
            <br />
            <div className="row">
                <div className="col">
                    <label className={userInformation.dealerType == "CleanIot" ? "titleMainClean" : "titleMain"}>SUPPORT</label>
                </div>
                <div className="col-and">
                    <GetProfileImage />
                </div>
            </div>
            <div className="pl-0">
                {/* <TicketsCounterSuperAdmin /> */}
                <br />
                <TicketTableSuperAdmin />
            </div>
        </div>
        </div>
        </div>
    )
}
