import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import MapChart from "./MapChart";

var userInformation = JSON.parse(localStorage.getItem("user"));

function MapIndex() {
  const [content, setContent] = useState("");
  const [state, setState] = useState("");
  const [count, setCount] = useState("");
  const [data, setData] = useState()
  const testing = (context) => {
    return content;
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/dashboard/usaMap/dealerCode/${userInformation.dealerCode}`,
        {
          headers: {
            Authorization: `Bearer ${userInformation.access_token}`,
            User: `${userInformation.username}`,
          },
        }
      )
      .then((res) => {
        setData(res.data)
        res.data.map((a) => (
          setState(a.state),
          setCount(a.count)
        ))
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleClick = (reviveState) => {
    setContent(reviveState);
  };

  const handleOverridePosition = (args) => {
    const { top, left } = args;
    return { top, left, right: 0, bottom: 0 };
  };

  return (

    <div> 
      <MapChart
        setTooltip={setContent}
        testClick={handleClick}
        countt={count}
      />
      <ReactTooltip
        // className="citiesTooltip"
        // getContent={testing}
        event="click"
        globalEventOff="mouseEnter"
        isCapture={true}
        overridePosition={handleOverridePosition}
        backgroundColor="#DB4401"
        textColor="#FFFFFF"
        style={{ borderRadius: "20%" }}
      >
        <section className="tooltip-content">

          <h5 style={{paddingTop:"8px"}}>{content?.name}</h5>
          {content?.name === state ? (
              <h5 style={{textAlign:"center"}}>{count}</h5>
            ):(
              <h5 style={{textAlign:"center"}}>0</h5>
            )}
          {/* {data.map((a) => (
            content?.name === a.state && (
              <h4>{a.state}: {a.count}</h4>
            )
          ))} */}
        </section>
      </ReactTooltip>
      {/* {data.map((a)=>(
        <h1>{a.state}: {a.count}</h1>
      ))} */}
    </div>
  );
}
export default MapIndex;

// import axios from "axios";
// import React, { useState } from "react";
// import ReactTooltip from "react-tooltip";
// import MapChart from "./MapChart";

// var userInformation = JSON.parse(localStorage.getItem("user"));

// function MapIndex() {
//   const [content, setContent] = useState("");
//   const [count, setCount] = useState("");

//   axios
//     .get(
//       `${process.env.REACT_APP_API_URL}/api/dashboard/usaMap/dealerCode/${userInformation.dealerCode}/state/${content.name}`
//     )
//     .then((res) => {
//       // console.log(res);
//       setCount(res.data.count);
//       localStorage.setItem("count", JSON.stringify(res.data.count));

//     })
//     .catch((err) => {
//       console.error(err);
//     });

//   return (
//     <div>
//       <MapChart setTooltipContent={setContent}  countt={count}/>
//       <ReactTooltip>{content}: {count}</ReactTooltip>
//     </div>
//   );
// }
// export default MapIndex;
